import { useEffect, useState, useRef } from 'react';
import { NftSearchType, TokenType } from './types';
import { useMoralisWeb3Api } from 'react-moralis';
import { filterWhiteList, searchNft, filterSearch, filterDuplicates } from './funcs';
import { tokens as whitelistedAddresses } from '../helper/connector';

export const useSearchNft = (addressArg: string) => {
  const [searchNFTs, setSearchNFTs] = useState<TokenType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [isError, setIsError] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [cursor, setCursor] = useState<string | null>(null);
  const [address, setAddress] = useState(addressArg);
  const addressRef = useRef(addressArg);
  const [search, setSearch] = useState<NftSearchType>({
    query: '',
    filter: 'name',
  });
  const Web3Api = useMoralisWeb3Api();

  const updateAddress = (addressUpdate: string) => {
    addressRef.current = addressUpdate;
    setAddress(addressUpdate);
  };

  const loadSearchNft = (searchArg?: NftSearchType) => {
    const searchData = searchArg ?? search;
    const addressData = !address ? addressRef.current : addressRef.current;

    if (!searchData.query) {
      return;
    }

    if (isLoading || !addressData || isLoadingRef.current) {
      return;
    }

    isLoadingRef.current = true;
    setIsLoading(true);
    setIsError(false);

    searchNft({
      apiProvider: Web3Api,
      address: addressData,
      searchQuery: searchData,
      cursor: null,
    })
      .then((data) => {
        if (data) {
          setCursor(data.cursor);
          const receivedTokens = filterWhiteList(data.tokens, whitelistedAddresses);
          setSearchNFTs(receivedTokens);
        }
        isLoadingRef.current = false;
        setIsLoading(false);
      })
      .catch((err) => {
        isLoadingRef.current = false;
        setIsError(true);
        setIsLoading(false);
      });
    setIsRefresh(false);
  };

  const loadSearchNftNext = (searchArg?: NftSearchType) => {
    const searchData = searchArg ?? search;

    if (!searchData.query) {
      return;
    }

    if (isNext || isEnd || !address) {
      return;
    }

    setIsNext(true);
    setIsError(false);

    searchNft({
      apiProvider: Web3Api,
      address: address,
      searchQuery: search,
      cursor: cursor,
    })
      .then((data) => {
        if (data) {
          setCursor(data.cursor);
          const receivedTokens = filterWhiteList(data.tokens, whitelistedAddresses);
          const filteredTokens = filterDuplicates(receivedTokens, searchNFTs);
          setSearchNFTs([...searchNFTs, ...filteredTokens]);
          setIsEnd(data.cursor === null);
        }
      })
      .catch((err) => {
        setIsError(true);
      });
    setIsNext(false);
  };

  useEffect(() => {
    loadSearchNft(search);
  }, [search, address, isRefresh]);

  useEffect(() => {
    loadSearchNftNext(search);
  }, [isNext]);

  return {
    searchIsEnd: isEnd,
    searchNFTs,
    search,
    setSearch,
    isNext,
    setIsNext,
    searchIsRefresh: isRefresh,
    setSearchIsrefresh: setIsRefresh,
    searchIsLoading: isLoading,
    setSearchIsLoading: setIsLoading,
    searchIsError: isError,
    searchSetAddress: setAddress,
    searchAddress: address,
    loadSearchNft,
    loadSearchNftNext,
    updateAddress,
  };
};
