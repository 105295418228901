import { JsonRpcSigner } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { SwapObject } from '../features/swapSlice';
import { VaultSwap } from '../typechain';

import { approveERC20, approveERC721, approveERC1155 } from './approveTokens';

type ApproveObjects = {
  name: string;
  type: 'ERC-721' | 'ERC-20' | 'ERC-1155';
  isApproved: string;
};

export const formateDataToSwap = async (
  tokens: SwapObject[],
  address: string,
  approvedState: ApproveObjects[],
  setApprovedState: Function,
  handleError: Function,
  signer: JsonRpcSigner,
  isApprove?: boolean,
) => {
  const tokensSwap = [];

  for (let i = 0; i < tokens.length; i++) {
    switch (tokens[i].tokenStandard) {
      case 'ERC-20':
        if (tokens[i].title === 'Ether') continue;
        if (isApprove) {
          const amountInWei = ethers.utils.parseUnits(tokens[i].amount!, tokens[i].decimals);

          await approveERC20(
            tokens[i].address,
            amountInWei,
            address,
            approvedState[i],
            setApprovedState,
            handleError,
            signer,
          );
        }

        tokensSwap.push({
          dapp: tokens[i].address,
          typeStd: 0,
          tokenId: [],
          blc: [ethers.utils.parseUnits(tokens[i].amount!, tokens[i].decimals)],
          data: [],
        } as VaultSwap.SwapStructStruct);
        break;

      case 'ERC-721':
        if (isApprove) {
          await approveERC721(tokens[i].address, address, approvedState[i], setApprovedState, handleError, signer);
        }

        tokensSwap.push({
          dapp: tokens[i].address,
          typeStd: 1,
          tokenId: [tokens[i].id],
          blc: [],
          data: [],
        } as VaultSwap.SwapStructStruct);
        break;

      case 'ERC-1155':
        if (isApprove) {
          await approveERC1155(tokens[i].address, address, approvedState[i], setApprovedState, handleError, signer);
        }

        tokensSwap.push({
          dapp: tokens[i].address,
          typeStd: 2,
          tokenId: [tokens[i].id],
          blc: [tokens[i].amount],
          data: [128],
        } as VaultSwap.SwapStructStruct);
        break;

      default:
        return [];
    }
  }

  return tokensSwap;
};
