import { useState, useEffect } from 'react';

import { ethers } from 'ethers';

export const useGetGasPrice = () => {
  const [gasPrice, setGasPrice] = useState('');
  const [isGettingGasPrice, setIsGettingGasPrice] = useState(true);

  const provider = new ethers.providers.JsonRpcProvider(
    `https://${process.env.REACT_APP_NETWORK_NAME}.infura.io/v3/${process.env.REACT_APP_INFURA_API}`,
  );

  const getGasPrice = async () => {
    const gasPrice = ethers.utils.formatEther(await provider.getGasPrice());
    setGasPrice(gasPrice.substring(0, 15));
    setIsGettingGasPrice(false);
  };

  useEffect(() => {
    getGasPrice();
  }, []);

  return { gasPrice, isGettingGasPrice };
};
