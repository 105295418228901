import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import makeBlockie from 'ethereum-blockies-base64';

import axios from 'axios';

// @ts-ignore
import ReactCursorPosition from 'react-cursor-position';

import { ethers } from 'ethers';
import { useGetFee } from '../../hooks/useGetFee';
import { useGetGasPrice } from '../../hooks/useGetGasPrice';
import { useGetTokensFromId } from '../../hooks/useGetTokensFromId';

import {
  GetTokensFromId,
  GetTokensFromId_swaps_nftsOne,
  GetTokensFromId_swaps_nftsOne_dapp,
} from '../../services/myTradesService/__generated__/GetTokensFromId';
import { assetType } from '../../../__generated__/globalTypes';

import ETHImage from '../../icons/ethereum-icon.svg';
import verifyCheck from '../../icons/verifiy-check.svg';
import notAvailable from '../../images/not-available.svg';
import { Loading, TransactionModal, CancelTradeModal } from '../../components';
import { ToggleSteps } from '../Swap/components';
import { SwapLoading } from '../Swap/Swap';

import ERC721Token from './page-components/ERC721Token/ERC721Token';
import ERC1155Token from './page-components/ERC1155Token/ERC1155Token';

import { tokens as whitelistedAddresses } from '../../helper/connector';

import { Container } from '../../globalStyles';
import {
  ReviewTradeContent,
  ReviewTradeText,
  ReviewTradeMakeSureText,
  ReviewTradeBlockWrapper,
  ReviewTradeSubtitle,
  ReviewTradeTokensWrapper,
  TokenWrapper,
  TokenContent,
  TokenTitle,
  TokenImageCurrency,
  TokenCurrencyWrapper,
  TokenAmountCurrency,
  WalletIcon,
  AccountText,
  BackButton,
  BackButtonWrapper,
  TransactionHash,
  VerifyIcon,
} from './ReviewTrade.styles';

interface NFTType {
  __typename: 'SwapAsset';
  id: string;
  assetType: assetType;
  dapp: GetTokensFromId_swaps_nftsOne_dapp;
  tokenId: any[];
  balance: any[];
  metaURI: string[];
  isWhitelisted: boolean;
}

function ReviewTrade({ details }: { details?: boolean }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const [showTransaction, setShowTransaction] = useState(false);
  const [isRejectTrade, setIsRejectTrade] = useState(false);
  const { gasPrice, isGettingGasPrice } = useGetGasPrice();
  const { fee, isGettingFee } = useGetFee();

  const { data, isLoading } = useGetTokensFromId(id!);
  const [dataForModal, setDataForModal] = useState<any[]>();
  const [tokensUserOne, setTokensUserOne] = useState<any[]>();
  const [tokensUserTwo, setTokensUserTwo] = useState<any[]>();
  const [transactionHashOne, setTransactionHashOne] = useState('');
  const [transactionHashTwo, setTransactionHashTwo] = useState('');
  const [ethUserOne, setEthUserOne] = useState('');
  const [ethUserTwo, setEthUserTwo] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [swapId, setSwapId] = useState('');

  const openTransaction = (e: React.SyntheticEvent, hash: string) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/tx/${hash}`, '_blank');
  };

  const openAccount = (e: React.SyntheticEvent, hash: string) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/address/${hash}`, '_blank');
  };

  const getDataForModal = async (data: GetTokensFromId) => {
    const dataForModal = await Promise.all(
      data.swaps[0].nftsTwo.map(async (token: GetTokensFromId_swaps_nftsOne) => {
        if (token.assetType === 'ERC1155') {
          const name = await axios
            .get(
              `https://api.covalenthq.com/v1/${process.env.REACT_APP_NETWORK_CHAINID}/tokens/${token.dapp.id}/nft_metadata/${token.tokenId}/?quote-currency=USD&format=JSON&key=ckey_${process.env.REACT_APP_COVALENT}`,
            )
            .then((res: any) => res.data.data.items[0].contract_name);

          return {
            name,
            type: 'ERC-1155',
            isApproved: 'false',
          };
        }
        return {
          name: token.dapp.name,
          type: `ERC-${token.assetType.substring(3)}`,
          isApproved: 'false',
        };
      }),
    );

    return dataForModal;
  };

  useEffect(() => {
    if (!data || !account) return;
    if (!data?.swaps[0]?.nftsOne && !data?.swaps[0]?.nftsTwo) navigate('/my-trades');
    if (data.swaps[0].status === 'Canceled') navigate('/my-trades');

    setAddressOne(data.swaps[0].addressOne);
    setAddressTwo(data.swaps[0].addressTwo);

    const tokensUserOneWhitelisted = data.swaps[0].nftsOne.map((token) =>
      whitelistedAddresses.map((address) => address.toLowerCase()).includes(token.dapp.id.toLowerCase())
        ? {
            ...token,
            isWhitelisted: true,
          }
        : {
            ...token,
            isWhitelisted: false,
          },
    );

    const tokensUserTwoWhitelisted = data.swaps[0].nftsTwo.map((token) =>
      whitelistedAddresses.map((address) => address.toLowerCase()).includes(token.dapp.id.toLowerCase())
        ? {
            ...token,
            isWhitelisted: true,
          }
        : {
            ...token,
            isWhitelisted: false,
          },
    );

    if (account.toLowerCase() === data.swaps[0].addressTwo.toLowerCase()) {
      setEthUserOne(data.swaps[0].valueOne);
      setEthUserTwo(data.swaps[0].valueTwo);

      setTokensUserOne(tokensUserOneWhitelisted);
      setTokensUserTwo(tokensUserTwoWhitelisted);

      setTransactionHashOne(data.swaps[0].closeAction);
      setTransactionHashTwo(data.swaps[0].createAction);
    } else {
      setEthUserOne(data.swaps[0].valueTwo);
      setEthUserTwo(data.swaps[0].valueOne);

      setTokensUserOne(tokensUserTwoWhitelisted);
      setTokensUserTwo(tokensUserOneWhitelisted);

      setTransactionHashOne(data.swaps[0].createAction);
      setTransactionHashTwo(data.swaps[0].closeAction);
    }

    setSwapId(parseInt(data.swaps[0].id.toString(), 16).toString());
    getDataForModal(data).then((res) => setDataForModal(res));
  }, [data, account]);

  useEffect(() => {
    if (!isGettingFee) return;
  }, [isGettingFee]);

  useEffect(() => {
    if (!isGettingGasPrice) return;
  }, [isGettingGasPrice]);

  if (!data?.swaps[0]?.nftsOne && !data?.swaps[0]?.nftsTwo) {
    navigate('/my-trades');
  }

  return (
    <>
      {showTransaction && account ? (
        <TransactionModal
          setShowTransaction={setShowTransaction}
          address={account}
          gasPrice={gasPrice}
          fee={fee}
          approveTrade
          addressOne={addressOne}
          addressTwo={addressTwo}
          swapId={swapId}
          userTwoAssets={dataForModal}
        />
      ) : (
        <></>
      )}
      {isRejectTrade ? (
        <CancelTradeModal
          closeModal={setIsRejectTrade}
          tradeId={swapId}
          fee={fee}
          isRejectTrade
          swapCreator={addressOne}
        />
      ) : (
        <></>
      )}
      <Container>
        {isLoading ? (
          <SwapLoading>
            <Loading isLoading />
          </SwapLoading>
        ) : (
          <ReviewTradeContent>
            {!details ? <ReviewTradeText>Review trade</ReviewTradeText> : <></>}
            {!details ? (
              <ReviewTradeMakeSureText>Make Sure Your Trade</ReviewTradeMakeSureText>
            ) : (
              <>
                <BackButtonWrapper>
                  <BackButton onClick={() => navigate('/my-trades')}>Back</BackButton>
                </BackButtonWrapper>
                <ReviewTradeMakeSureText>
                  Trade Details - #{id} <WalletIcon src={makeBlockie(addressOne)} alt="" />
                  <AccountText>{addressOne.slice(0, 5).concat('...') + addressOne.slice(39, 42).concat()}</AccountText>
                </ReviewTradeMakeSureText>
              </>
            )}
            <ReviewTradeBlockWrapper>
              {details ? (
                <ReviewTradeSubtitle>
                  You Sent These{' '}
                  <TransactionHash onClick={(e) => openTransaction(e, transactionHashOne)}>
                    {transactionHashOne.slice(0, 12).concat('...') +
                      transactionHashOne.slice(transactionHashOne.length - 12, transactionHashOne.length).concat()}
                  </TransactionHash>
                </ReviewTradeSubtitle>
              ) : (
                <ReviewTradeSubtitle>
                  Your asset{' '}
                  <TransactionHash onClick={(e) => openAccount(e, addressTwo)}>
                    {addressTwo.slice(0, 12).concat('...') +
                      addressTwo.slice(addressTwo.length - 12, addressTwo.length).concat()}
                  </TransactionHash>
                </ReviewTradeSubtitle>
              )}
            </ReviewTradeBlockWrapper>
            <ReviewTradeTokensWrapper>
              {tokensUserTwo!.map((token: NFTType, key: number) => {
                switch (token.assetType) {
                  case 'ERC721':
                    return (
                      <ReactCursorPosition>
                        <ERC721Token
                          key={key}
                          id={token.id}
                          tokenId={token.tokenId}
                          name={token.dapp.name!}
                          address={token.dapp.id}
                          isWhitelisted={token.isWhitelisted}
                        />
                      </ReactCursorPosition>
                    );

                  case 'ERC1155':
                    return (
                      <ReactCursorPosition>
                        <ERC1155Token
                          key={key}
                          id={token.id}
                          tokenId={token.tokenId}
                          balance={token.balance}
                          address={token.dapp.id}
                          isWhitelisted={token.isWhitelisted}
                        />
                      </ReactCursorPosition>
                    );

                  case 'ERC20':
                    return (
                      <TokenWrapper key={token.id}>
                        <TokenImageCurrency
                          src={`${process.env.PUBLIC_URL}/assets/token-icons/${token.dapp.symbol?.toLowerCase()}.svg`}
                          onError={(e) => {
                            e.currentTarget.src = notAvailable;
                          }}
                        />
                        <TokenContent>
                          <TokenCurrencyWrapper>
                            <TokenTitle>
                              {token.dapp.name} {token.isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
                            </TokenTitle>
                            <TokenAmountCurrency>
                              {ethers.utils.formatUnits(token.balance[0], token.dapp.decimals!)} {token.dapp.symbol}
                            </TokenAmountCurrency>
                          </TokenCurrencyWrapper>
                        </TokenContent>
                      </TokenWrapper>
                    );
                  default:
                    return null;
                }
              })}
              {ethUserTwo !== '0' ? (
                <TokenWrapper key="ethUserTwo">
                  <TokenImageCurrency src={ETHImage} />
                  <TokenContent>
                    <TokenCurrencyWrapper>
                      <TokenTitle>
                        Etherum <VerifyIcon src={verifyCheck} alt="" />
                      </TokenTitle>
                      <TokenAmountCurrency>{ethers.utils.formatEther(ethUserTwo)} ETH</TokenAmountCurrency>
                    </TokenCurrencyWrapper>
                  </TokenContent>
                </TokenWrapper>
              ) : (
                <></>
              )}
            </ReviewTradeTokensWrapper>
            <ReviewTradeBlockWrapper>
              <ReviewTradeBlockWrapper>
                {details ? (
                  <ReviewTradeSubtitle>
                    You Received These{' '}
                    <TransactionHash onClick={(e) => openTransaction(e, transactionHashTwo)}>
                      {transactionHashTwo.slice(0, 12).concat('...') +
                        transactionHashTwo.slice(transactionHashTwo.length - 12, transactionHashTwo.length).concat()}
                    </TransactionHash>
                  </ReviewTradeSubtitle>
                ) : (
                  <ReviewTradeSubtitle>
                    Counterparty asset{' '}
                    <TransactionHash onClick={(e) => openAccount(e, addressOne)}>
                      {addressOne.slice(0, 12).concat('...') +
                        addressOne.slice(addressOne.length - 12, addressOne.length).concat()}
                    </TransactionHash>
                  </ReviewTradeSubtitle>
                )}
              </ReviewTradeBlockWrapper>
            </ReviewTradeBlockWrapper>
            <ReviewTradeTokensWrapper>
              {tokensUserOne!.map((token: NFTType, key: number) => {
                switch (token.assetType) {
                  case 'ERC721':
                    return (
                      <ReactCursorPosition>
                        <ERC721Token
                          key={key}
                          id={token.id}
                          tokenId={token.tokenId}
                          name={token.dapp.name!}
                          address={token.dapp.id}
                          isWhitelisted={token.isWhitelisted}
                        />
                      </ReactCursorPosition>
                    );

                  case 'ERC1155':
                    return (
                      <ReactCursorPosition>
                        <ERC1155Token
                          key={key}
                          id={token.id}
                          tokenId={token.tokenId}
                          balance={token.balance}
                          address={token.dapp.id}
                          isWhitelisted={token.isWhitelisted}
                        />
                      </ReactCursorPosition>
                    );

                  case 'ERC20':
                    return (
                      <TokenWrapper key={token.id}>
                        <TokenImageCurrency
                          src={`${process.env.PUBLIC_URL}/assets/token-icons/${token.dapp.symbol?.toLowerCase()}.svg`}
                          onError={(e) => {
                            e.currentTarget.src = notAvailable;
                          }}
                        />
                        <TokenContent>
                          <TokenCurrencyWrapper>
                            <TokenTitle>
                              {token.dapp.name} {token.isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
                            </TokenTitle>
                            <TokenAmountCurrency>
                              {ethers.utils.formatUnits(token.balance[0], token.dapp.decimals!)} {token.dapp.symbol}
                            </TokenAmountCurrency>
                          </TokenCurrencyWrapper>
                        </TokenContent>
                      </TokenWrapper>
                    );

                  default:
                    return null;
                }
              })}
              {ethUserOne !== '0' ? (
                <TokenWrapper key="ethUserOne">
                  <TokenImageCurrency src={ETHImage} />
                  <TokenContent>
                    <TokenCurrencyWrapper>
                      <TokenTitle>
                        Etherum <VerifyIcon src={verifyCheck} alt="" />
                      </TokenTitle>
                      <TokenAmountCurrency>{ethers.utils.formatEther(ethUserOne)} ETH</TokenAmountCurrency>
                    </TokenCurrencyWrapper>
                  </TokenContent>
                </TokenWrapper>
              ) : (
                <></>
              )}
            </ReviewTradeTokensWrapper>
          </ReviewTradeContent>
        )}
      </Container>
      {!details ? (
        <ToggleSteps
          buttonName="Approve Trade Request"
          nextStepLink={`/review-trade/${id}`}
          backLinkName="Reject"
          setIsRejectTrade={setIsRejectTrade}
          setShowTransaction={setShowTransaction}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default ReviewTrade;
