import { useNavigate } from 'react-router-dom';

import closeIcon from '../../icons/close-icon.svg';
import errorImg from '../../images/error.svg';

import {
  ErrorContainer,
  ErrorWrapper,
  ModalContent,
  ErrorTitle,
  ErrorText,
  ErrorTradeImg,
  ErrorButton,
  ErrorLink,
  ModalCloseIcon,
} from './TransactionErrorModal.styles';

function TransactionErrorModal({
  errorMessage,
  tryAgain,
  closeModal,
  stopLoading,
  setIsDisabled,
}: {
  errorMessage: any;
  tryAgain: Function;
  closeModal: Function;
  stopLoading?: Function;
  setIsDisabled?: Function;
}) {
  const navigate = useNavigate();

  return (
    <ErrorContainer>
      <ErrorWrapper>
        <ModalCloseIcon
          src={closeIcon}
          onClick={() => {
            tryAgain(false);
            closeModal(false);
            document.body.classList.remove('no-scroll');
          }}
        />
        <ModalContent>
          <ErrorTradeImg src={errorImg} />
          <ErrorTitle>Something went wrong!</ErrorTitle>
          <ErrorText>{errorMessage.message ? errorMessage.message : errorMessage}</ErrorText>
          {errorMessage === 'The asset you are trying to trade is no longer in your wallet' ? (
            <ErrorButton
              onClick={() => {
                navigate('/my-trades');
                document.body.classList.remove('no-scroll');
                tryAgain(false);
                if (setIsDisabled && stopLoading) {
                  setIsDisabled(false);
                  stopLoading(false);
                }
              }}
            >
              Return to My Trades
            </ErrorButton>
          ) : (
            <ErrorButton
              onClick={() => {
                tryAgain(false);
                if (setIsDisabled && stopLoading) {
                  setIsDisabled(false);
                  stopLoading(false);
                }
              }}
            >
              Try again
            </ErrorButton>
          )}
          <ErrorLink target="_blank" href={process.env.REACT_APP_SUPPORT_LINK}>
            Contact support
          </ErrorLink>
        </ModalContent>
      </ErrorWrapper>
    </ErrorContainer>
  );
}

export default TransactionErrorModal;
