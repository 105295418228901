import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
`;

export const ErrorWrapper = styled.div`
  width: 500px;
  height: 480px;

  background: #ffffff;
  border: 1px solid #e3e7eb;
  box-sizing: border-box;
  border-radius: 16px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ErrorTradeImg = styled.img`
  margin: 0 auto;
  min-height: 195px;
`;

export const ErrorTitle = styled.h3`
  color: #183b56;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const ErrorText = styled.p`
  color: #74899a;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  max-width: 26rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 26px;
  transition: opacity 0.2s ease-in-out;
`;

export const ModalCloseIcon = styled.img`
  position: absolute;
  top: -6px;
  right: -6px;
  width: 24px;
  cursor: pointer;
`;

export const ErrorButton = styled.button`
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  border-radius: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const ErrorLink = styled.a`
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 35%;
  left: 35%;
`;
