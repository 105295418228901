import styled from 'styled-components';

export const ERC1155Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  height: 382px;
  width: 100%;
  position: relative;
`;

export const ERC1155Image = styled.img`
  width: 100%;
  height: 302px;
  object-fit: contain;
  border-radius: 16px 16px 0 0;

  &.no-image {
    width: 70%;
    height: 100%;
  }
`;

export const ERC1155ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ERC1155Content = styled.div`
  width: 100%;
  height: 20%;
  padding: 8px 16px;
`;

export const ERC1155Amount = styled.span`
  color: #183b56;
  font-weight: 600;
  font-size: 1rem;
  margin: 8px 0;
`;

export const ERC1155Title = styled.h5`
  color: #183b56;
  font-weight: 600;
  margin: 8px 0;
  font-size: 1rem;
  text-align: center;
`;

export const ERC1155Numbering = styled.span`
  font-size: 0.875rem;
  color: #183b56;
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;
