import styled from 'styled-components';

type LeaveProps = {
  white: boolean;
};

export const LeaveContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
`;

export const LeaveWrapper = styled.div`
  width: 500px;
  height: 180px;

  background: #ffffff;
  border: 1px solid #e3e7eb;
  box-sizing: border-box;
  border-radius: 16px;

  padding: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LeaveText = styled.p`
  color: #183b56;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  padding-top: 14px;
`;

export const LeaveButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const LeaveButton = styled.button<LeaveProps>`
  background: ${(props) => (props.white ? '#fff' : 'linear-gradient(180deg, #566ffe 0%, #18a0fb 100%)')};
  color: ${(props) => (props.white ? '#183B56' : '#fff')};
  border: ${(props) => (props.white ? ' 1px solid #E3E7EB' : 'none')};
  border-radius: 40px;
  padding: 5px 19px;
  width: 140px;
  height: 40px;
  font-size: 1rem;
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    opacity: 0.8;
  }
`;
