import { apolloClient } from '../../app/graphql';
import { GET_MY_TRADES, GET_TOKENS_FROM_ID } from './queries';
import { GetMyTrades } from './__generated__/GetMyTrades';
import { GetTokensFromId } from './__generated__/GetTokensFromId';

class MyTrades {
  async getMyTrades(first = 1000, address: String): Promise<GetMyTrades> {
    try {
      const response = await apolloClient.query({
        query: GET_MY_TRADES,
        variables: { first, address },
        fetchPolicy: 'network-only',
      });

      if (!response || !response.data) {
        throw new Error('Cannot get my trades!');
      }

      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async getTokensFromId(id: String): Promise<GetTokensFromId> {
    try {
      const response = await apolloClient.query({
        query: GET_TOKENS_FROM_ID,
        variables: { id },
      });

      if (!response || !response.data) {
        throw new Error('Cannot get tokens from trade id!');
      }

      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new MyTrades();
