import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import { ethers } from 'ethers';
import { VaultSwap__factory } from '../../typechain';

import closeIcon from '../../icons/close-icon.svg';
import cancelImg from '../../images/cancel-trade.svg';

import { Loading } from '..';

import {
  CancelTradeContainer,
  CancelTradeWrapper,
  CancelTradeImg,
  CancelTitle,
  InfoContainer,
  InfoRow,
  RowText,
  RowAmount,
  ModalContent,
  ModalCloseIcon,
  CancelButton,
  CancelLink,
  FontWeight,
  LoadingContainer,
} from './CancelTradeModal.styles';
import TransactionErrorModal from '../TransactionErrorModal/TransactionErrorModal';
import CancelDoneModal from '../CancelDoneModal/CancelDoneModal';

type CancelModalProps = {
  closeModal: Function;
  tradeId: string;
  fee: string;
  isRejectTrade?: boolean;
  swapCreator?: string;
};

function CancelTradeModal({ closeModal, tradeId, fee, isRejectTrade, swapCreator }: CancelModalProps) {
  const { account, connector } = useWeb3React();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isError, setIsError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isTransactionDone, setIsTransactionDone] = useState(false);

  const cancelTrade = async () => {
    if (!connector) return;

    setIsWaiting(true);
    setIsDisabled(true);

    if (!account) return;
    if (isRejectTrade && !swapCreator) return;

    const provider = new ethers.providers.Web3Provider(await connector.getProvider());
    const signer = provider.getSigner(account);

    const batchSwapContract = VaultSwap__factory.connect(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, signer);

    try {
      let tx;
      if (isRejectTrade) {
        tx = await batchSwapContract.cancelSwapIntent(swapCreator!, tradeId);
      } else {
        tx = await batchSwapContract.cancelSwapIntent(account, tradeId);
      }

      await tx.wait().then(() => {
        console.debug('trade canceled!');
        document.body.classList.remove('no-scroll');
        setIsTransactionDone(true);
      });
    } catch (error: any) {
      setIsDisabled(false);
      setIsWaiting(false);
      setIsError(true);
      setErrorMessage(error.message ? error.message : error);
    }
  };

  useEffect(
    () => () => {
      document.body.classList.remove('no-scroll');
      closeModal(false);
    },
    [],
  );

  useEffect(() => {
    if (!connector) return;
  }, [connector]);

  return isTransactionDone ? (
    <CancelDoneModal closeModal={closeModal} isRejectTrade={isRejectTrade} />
  ) : isError ? (
    <TransactionErrorModal
      errorMessage={errorMessage}
      tryAgain={setIsError}
      closeModal={closeModal}
      stopLoading={setIsWaiting}
      setIsDisabled={setIsDisabled}
    />
  ) : (
    <CancelTradeContainer>
      <CancelTradeWrapper disabled={isDisabled}>
        {!isDisabled && !isWaiting ? (
          <ModalCloseIcon
            src={closeIcon}
            alt=""
            onClick={() => {
              closeModal(false);
              document.body.classList.remove('no-scroll');
            }}
          />
        ) : (
          <></>
        )}
        {isWaiting ? (
          <LoadingContainer>
            <Loading isLoading={isWaiting} />
          </LoadingContainer>
        ) : (
          <></>
        )}
        <ModalContent disabled={isWaiting}>
          <CancelTradeImg src={cancelImg} alt="" />
          {isRejectTrade ? (
            <CancelTitle>Reject this trade?</CancelTitle>
          ) : (
            <CancelTitle>Cancel this trade?</CancelTitle>
          )}
          {isRejectTrade ? (
            <></>
          ) : (
            <InfoContainer>
              <InfoRow marginTop>
                <RowText>Fee refund</RowText>
                <RowAmount blue>
                  {fee} <FontWeight>ETH</FontWeight>
                </RowAmount>
              </InfoRow>
            </InfoContainer>
          )}
          {isRejectTrade ? (
            <CancelButton disabled={isDisabled} onClick={() => cancelTrade()}>
              Yes, I want to reject
            </CancelButton>
          ) : (
            <CancelButton disabled={isDisabled} onClick={() => cancelTrade()}>
              Yes, I want to cancel
            </CancelButton>
          )}
          <CancelLink
            onClick={() => {
              closeModal(false);
              document.body.classList.remove('no-scroll');
            }}
          >
            Let me think for a bit
          </CancelLink>
        </ModalContent>
      </CancelTradeWrapper>
    </CancelTradeContainer>
  );
}

export default CancelTradeModal;
