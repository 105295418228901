import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { useSelector } from 'react-redux';
import makeBlockie from 'ethereum-blockies-base64';
import { useGetETHBalance } from '../../hooks/useGetETHBalance';
import { useGetAccount } from '../../hooks/useGetAccount';

import { Chains } from '../../helper/connector';

import { selectTokensUserOne } from '../../app/store';

import { Container } from '../../globalStyles';

import {
  NavbarBackground,
  NavbarContainer,
  NavbarLeft,
  NavbarRight,
  NavbarLogo,
  NavbarExternalLink,
  NavbarSearchWrapper,
  NavbarSearchIcon,
  NavbarSearch,
  NavbarLinks,
  NavbarLink,
  ConnectWalletButton,
  ConnectErrorButton,
  NavbarIcon,
} from './Navbar.styles';

import { AccountDropdown, LeaveWarning } from '../index';

import Logo from '../../images/logo.svg';
import SearchIcon from '../../icons/search-icon.svg';
import WalletIcon from '../../icons/wallet-icon.svg';
import WarningIcon from '../../icons/warning-icon.png';

function Navbar(props: any) {
  const { account, isGettingAccount } = useGetAccount();
  const { chainId, deactivate, error } = useWeb3React();
  const { setAddress, balanceETH, isLoading } = useGetETHBalance();
  const imageRef = useRef<any>(null);
  const ref = useRef<any>(null);
  const { onClickOutside, show } = props;
  const [showWarning, setShowWarning] = useState(false);
  const [warningLink, setWarningLink] = useState('');
  const [showState, setShowState] = useState(show);
  const isWrongNetwork = error && error instanceof UnsupportedChainIdError;
  const swapTokens = useSelector(selectTokensUserOne);
  useEffect(() => {
    if (!chainId) return;
  }, [chainId]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (!ref.current?.contains(event.target) && !imageRef.current?.contains(event.target)) {
        onClickOutside && onClickOutside();
        setShowState(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  const navigate = useNavigate();

  const goToConnectPage = useCallback(() => navigate('/connect', { replace: true }), [navigate]);

  useEffect(() => {
    if (!account) {
      return;
    }

    setAddress(account);
    // eslint-disable-next-line
  }, [account]);

  return (
    <NavbarBackground>
      <Container>
        {isLoading && isGettingAccount ? (
          <></>
        ) : (
          <NavbarContainer>
            <>{showWarning ? <LeaveWarning link={warningLink} setShowWarning={setShowWarning} /> : <></>}</>
            <NavbarLeft>
              <NavbarLogo onClick={() => navigate('/swap')} src={Logo} />
            </NavbarLeft>
            <NavbarRight>
              <NavbarLinks>
                <NavbarLink
                  style={({ isActive }) => ({
                    color: isActive ? ' active' : '',
                  })}
                  to="/swap"
                >
                  Trade
                </NavbarLink>

                <>
                  {swapTokens.length ? (
                    <NavbarLink
                      style={({ isActive }) => ({
                        color: isActive ? ' active' : '',
                      })}
                      to="/my-trades"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowWarning(true);
                        setWarningLink('/my-trades');
                      }}
                    >
                      My Trades
                    </NavbarLink>
                  ) : (
                    <NavbarLink
                      style={({ isActive }) => ({
                        color: isActive ? ' active' : '',
                      })}
                      to="/my-trades"
                    >
                      My Trades
                    </NavbarLink>
                  )}
                </>

                <NavbarExternalLink href={process.env.REACT_APP_SUPPORT_LINK} target="_blank">
                  Support
                </NavbarExternalLink>
                <NavbarLink
                  style={({ isActive }) => ({
                    color: isActive ? ' active' : '',
                  })}
                  to="/faq"
                  onClick={(e) => e.preventDefault()}
                  className="disabled"
                >
                  <div className="comingSoon">
                    <span>Coming Soon</span>
                  </div>
                  Trading Post
                </NavbarLink>
                <NavbarLink
                  style={({ isActive }) => ({
                    color: isActive ? ' active' : '',
                  })}
                  to="/faq"
                  onClick={(e) => e.preventDefault()}
                  className="disabled"
                >
                  <div className="comingSoon left">
                    <span>Coming Soon</span>
                  </div>
                  Profile
                </NavbarLink>
              </NavbarLinks>
              {account ? (
                <NavbarIcon
                  src={makeBlockie(account)}
                  ref={imageRef}
                  onClick={() => {
                    setShowState(!showState);
                  }}
                />
              ) : (
                <>
                  {isWrongNetwork ? (
                    <ConnectErrorButton>
                      <img src={WarningIcon} alt="" />
                      <span>Network</span>
                    </ConnectErrorButton>
                  ) : (
                    <ConnectWalletButton onClick={goToConnectPage}>
                      <img src={WalletIcon} alt="" />
                      Connect Wallet
                    </ConnectWalletButton>
                  )}
                </>
              )}
            </NavbarRight>
            {account && chainId && balanceETH && showState ? (
              <AccountDropdown
                ref={ref}
                account={account}
                chain={Chains[chainId]}
                balanceETH={balanceETH}
                setShowState={setShowState}
                deactivate={() => {
                  deactivate();
                }}
              />
            ) : (
              <></>
            )}
          </NavbarContainer>
        )}
      </Container>
    </NavbarBackground>
  );
}

export default Navbar;
