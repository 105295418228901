import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { tokens as whitelistedAddresses } from '../helper/connector';
import { TokenType } from './types';
import { useGetErc20FromAddress } from './useGetErc20FromAddress';

export const useGetFilteredErc20FromAddress = () => {
  const {
    ERC20Tokens,
    ETHBalance,
    addressTokens,
    setAddressTokens,
    setIsRefreshTokens,
    setIsLoadingTokens,
    isRefreshTokens,
    isLoadingTokens,
    isErrorTokens,
  } = useGetErc20FromAddress();

  const [filteredData, setFilteredData] = useState<Object[]>();

  useEffect(() => {
    if (!ERC20Tokens || !ETHBalance) {
      return;
    }

    const sortedERC20 = ERC20Tokens.reduce<TokenType[]>((accumulator, item) => {
      accumulator.push({
        title: item.name,
        balance: ethers.utils.formatUnits(item.balance, item.decimals),
        decimals: item.decimals,
        isSelected: false,
        symbol: item.symbol,
        amount: '',
        address: item.token_address,
        tokenStandard: 'ERC-20',
        category: 'Tokens',
        uniqueKey: uuidv4(),
        image: item.logo,
      });

      return accumulator;
    }, []);

    const UsersETH = {
      title: 'Ether',
      balance: ethers.utils.formatEther(ETHBalance.balance),
      isSelected: false,
      symbol: 'ETH',
      amount: '',
      address: '0x0000000000000000000000000000000000000000',
      tokenStandard: 'ERC-20',
      category: 'Tokens',
      uniqueKey: uuidv4(),
    };

    const internallFilterData = [UsersETH, ...sortedERC20];

    internallFilterData!.sort((a: any, b: any) => {
      if (a.title !== 'Ether' && b.title !== 'Ether' && a.title && b.title) {
        return a.title.localeCompare(b.title);
      }
      return null;
    });

    const whitelistedTokens = internallFilterData!.map((token) =>
      whitelistedAddresses.map((address) => address.toLowerCase()).includes(token.address.toLowerCase())
        ? {
            ...token,
            isWhitelisted: true,
          }
        : {
            ...token,
            isWhitelisted: false,
          },
    );

    setIsLoadingTokens(false);
    setFilteredData(whitelistedTokens);
  }, [ERC20Tokens, ETHBalance]);

  return {
    erc20Filtered: filteredData,
    setAddressTokens,
    addressTokens,
    isRefreshTokens,
    setIsRefreshTokens,
    setIsLoadingTokens,
    isLoadingTokens,
    isErrorTokens,
  };
};
