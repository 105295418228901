import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { addToSwapCurrencyUserOne, addToSwapCurrencyUserTwo } from '../../features/swapSlice';

import { checkBalance } from '../../utils/checkBalance';

import image from '../../icons/ether-icon.svg';

import {
  NativeTokenWrapper,
  NativeTokenText,
  NativeTokenInput,
  NativeTokenButton,
  NotEnoughBalance,
} from './NativeToken.styles';

type NativeTokenProps = {
  balance: string;
  id: string;
  isSelected: boolean;
  amount: string;
  step?: number;
  address: string;
};

function NativeToken({ balance, id, isSelected, amount, step, address }: NativeTokenProps) {
  const [input, setInput] = useState('');
  const [isBalanceEnough, setIsBalanceEnough] = useState(true);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const addToSwap = () => {
    const result = checkBalance(balance, input);
    if (result === 0) {
      setIsBalanceEnough(false);
    } else {
      setIsBalanceEnough(true);

      // if (step !== 2) {
      //   dispatch(
      //     addToSwapCurrencyUserOne({
      //       image,
      //       title: `${result} ETH`,
      //       amount: result,
      //       id,
      //       tokenStandard: 'Native Token',
      //       address,
      //     })
      //   );
      // } else {
      //   dispatch(
      //     addToSwapCurrencyUserTwo({
      //       image,
      //       title: `${result} ETH`,
      //       amount: result,
      //       id,
      //       tokenStandard: 'Native Token',
      //       address,
      //     })
      //   );
      // }
    }
  };

  return (
    <>
      {isSelected ? (
        <NativeTokenWrapper>
          <NativeTokenText>Amount</NativeTokenText>
          <NativeTokenInput disabled onChange={handleChange} placeholder={amount} />
          <NativeTokenButton
            disabled
            onClick={() => {
              addToSwap();
            }}
          >
            Set Amount
          </NativeTokenButton>
        </NativeTokenWrapper>
      ) : (
        <NativeTokenWrapper>
          <NativeTokenText>Amount</NativeTokenText>
          {isBalanceEnough ? (
            <NativeTokenInput onChange={handleChange} placeholder="Input amount here" />
          ) : (
            <>
              <NotEnoughBalance>The balance is not enough</NotEnoughBalance>
              <NativeTokenInput className="red" onChange={handleChange} placeholder="Input amount here" />
            </>
          )}
          <NativeTokenButton
            onClick={() => {
              addToSwap();
            }}
          >
            Set Amount
          </NativeTokenButton>
        </NativeTokenWrapper>
      )}
    </>
  );
}

export default NativeToken;
