import metamask from '../icons/wallet-icons/metamask-icon.svg';
import walletConnect from '../icons/wallet-icons/walletconnect-icon.svg';

const WalletList = [
  {
    id: 1,
    image: metamask,
    name: 'Metamask',
    background: 'rgba(246, 133, 27, 0.1)',
    text: '',
  },
  {
    id: 2,
    image: walletConnect,
    name: 'WalletConnect',
    background: 'rgba(60, 153, 252, 0.1)',
    text: '',
  },
];

export default WalletList;
