import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addToSwapCurrencyUserOne, addToSwapCurrencyUserTwo } from '../../features/swapSlice';

import notAvailable from '../../images/not-available.svg';
import { TokenType } from '../../types/tokensType';
import verifyCheck from '../../icons/verifiy-check.svg';

import { checkBalance } from '../../utils/checkBalance';

import Loading from '../Loading/Loading';

import {
  ERC20ItemWrapper,
  ERC20Circle,
  ERC20Image,
  ERC20Content,
  ERC20Title,
  ERC20Balance,
  ERC20Input,
  ERC20Button,
  ERC20Loading,
  NotEnoughBalance,
  VerifyIcon,
} from './ERC20Item.styles';

function ERC20Item({
  title,
  balance,
  amount,
  id,
  symbol,
  decimals,
  isSelected,
  step,
  address,
  noSelect,
  isWhitelisted,
  image,
}: TokenType) {
  const [formatedETH, setFormatedETH] = useState(balance);

  const getLocalImageUrl = (url: string | undefined) => {
    if (!url) {
      return undefined;
    }

    try {
      return `${url}/assets/token-icons/${symbol?.toLowerCase()}.svg`;
    } catch (e) {
      return undefined;
    }
  };

  const [publicImage, setPublicImage] = useState(getLocalImageUrl(process.env.REACT_APP_PUBLIC_URL));
  const [input, setInput] = useState(amount || '');
  const [isBalanceEnough, setIsBalanceEnough] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const onError = () => setPublicImage(notAvailable);
  const openTokenEtherscan = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/address/${address}`, '_blank');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const addToSwap = () => {
    const result = checkBalance(balance, input);
    if (result === 0) {
      setIsBalanceEnough(false);
    } else {
      setIsBalanceEnough(true);

      if (step === 2) {
        dispatch(
          addToSwapCurrencyUserTwo({
            image: image ?? publicImage,
            title,
            amount: result,
            decimals,
            id,
            symbol,
            tokenStandard: 'ERC-20',
            address,
          }),
        );
      } else {
        dispatch(
          addToSwapCurrencyUserOne({
            image: image ?? publicImage,
            title,
            amount: result,
            decimals,
            id,
            symbol,
            tokenStandard: 'ERC-20',
            address,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (!image && !publicImage) return;
    setFormatedETH(
      Number(balance)
        .toFixed(8)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .replace(/(\.00+|00+)$/, ''),
    );
    setIsLoading(false);
  }, [publicImage, balance]);

  return (
    <ERC20ItemWrapper disabled={isSelected}>
      <ERC20Circle>
        {isLoading ? (
          <ERC20Loading>
            <Loading isLoading />
          </ERC20Loading>
        ) : (
          <ERC20Image src={image ?? publicImage} onError={onError} />
        )}
      </ERC20Circle>
      <ERC20Content>
        <ERC20Title onClick={openTokenEtherscan}>
          {title} {isWhitelisted ? <VerifyIcon src={verifyCheck} /> : <></>}
        </ERC20Title>
        <ERC20Balance>
          Balance:
          {formatedETH}
        </ERC20Balance>
        {isWhitelisted && !noSelect ? (
          <>
            {isBalanceEnough ? (
              <ERC20Input placeholder="Input amount" onChange={handleChange} value={input} />
            ) : (
              <>
                <NotEnoughBalance>The balance is not enough</NotEnoughBalance>
                <ERC20Input className="red" placeholder="Input amount" onChange={handleChange} />
              </>
            )}
            {isSelected && input && amount !== input ? (
              <ERC20Button
                onClick={() => {
                  addToSwap();
                }}
              >
                Update
              </ERC20Button>
            ) : isSelected ? (
              <ERC20Button disabled>Update</ERC20Button>
            ) : (
              <ERC20Button
                onClick={() => {
                  addToSwap();
                }}
              >
                Add Token
              </ERC20Button>
            )}
          </>
        ) : (
          <></>
        )}
      </ERC20Content>
    </ERC20ItemWrapper>
  );
}

export default ERC20Item;
