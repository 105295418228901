import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGetAccount } from '../../hooks/useGetAccount';

import { selectTokensUserOne, selectTokensUserTwo } from '../../app/store';
import { reset } from '../../features/swapSlice';

import { WalletNotConnected, Loading } from '../../components';
import { Notification, SwapStepOne, SwapStepTwo, ToggleSteps } from './components/index';

import { Container } from '../../globalStyles';
import { useGetFilteredNftFromAddress } from '../../hooks/useGetFilteredNftFromAddress';
import { useGetFilteredErc20FromAddress } from '../../hooks/useFilteredErc20FromAddress';
import { useNft } from '../../hooks/useNft';
import { useParams } from 'react-router-dom';

type SwapProps = {
  secondStep?: boolean;
  editAssets?: string;
  clear?: boolean;
  isView?: boolean;
};

type ButtonsType = {
  name: string;
  isSelected: boolean;
};

const allCategories = ['NFT', 'Tokens'];

const filteredButtons = allCategories.reduce(
  (accumulator: Array<ButtonsType>, category: string) =>
    (accumulator = [{ name: category, isSelected: false }, ...accumulator]),
  [],
);

export const SwapLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 10%;
`;

export const SwapError = styled.div`
  height: 50vh;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Swap({ secondStep, editAssets, clear, isView }: SwapProps) {
  const { account, isGettingAccount } = useGetAccount();
  const { id } = useParams();

  const [allTokens, setAllTokens] = useState<any>([]);
  const [buttons, setButtons] = useState(filteredButtons);

  const swapTokens = useSelector(selectTokensUserOne);
  const swapTokensSecondUser = useSelector(selectTokensUserTwo);

  const [filter, setFilter] = useState<string>('NFT');

  const setActiveButton = (buttons: Array<ButtonsType>, selectedButton: string) => {
    buttons.map((curButton) => {
      if (curButton.name !== selectedButton) {
        return (curButton.isSelected = false);
      }

      return (curButton.isSelected = true);
    });

    setButtons(buttons);
  };

  const {
    collection,
    dispatchAction,
    isNftLoading,
    isNftError,
    isNftEnd,
    next: nextNft,
    refresh: refreshNft,
    updateAddress: nftUpdateAddress,
    nftAddress,
  } = useNft(id || account ? id ?? account! : '');
  const {
    erc20Filtered,
    setAddressTokens,
    isRefreshTokens,
    setIsRefreshTokens,
    addressTokens,
    isLoadingTokens,
    isErrorTokens,
  } = useGetFilteredErc20FromAddress();

  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState<string | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedTerm === null) {
        return;
      }

      setSearchTerm(debouncedTerm);
      if (!debouncedTerm) {
        dispatchAction({ action: 'Plain' });
        return;
      }

      dispatchAction({
        action: 'Search',
        options: {
          query: debouncedTerm,
          filter: 'name',
        },
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  const filterItems = (button: string) => {
    setFilter(button);
    if (button === 'NFT') {
      if (!collection) return;

      setActiveButton(filteredButtons, button);
      setAllTokens(collection);
    } else if (button === 'Tokens') {
      if (!erc20Filtered) return;

      setActiveButton(filteredButtons, button);
      setAllTokens(erc20Filtered);
    }
  };

  const refresh = () => {
    if (filter === 'NFT') {
      refreshNft();
    } else if (filter === 'Tokens') {
      setIsRefreshTokens(!isRefreshTokens);
    }
  };

  const next = () => {
    if (filter === 'Tokens') return;
    nextNft();
  };

  useEffect(() => {
    setAllTokens([]);

    if (!secondStep) {
      if (account) {
        setAddressTokens(account);
        nftUpdateAddress(account);
      }
    } else {
      const secondAddress = sessionStorage.getItem('secondAddress');
      if (secondAddress) {
        nftUpdateAddress(secondAddress);
        setAddressTokens(secondAddress);
      }
    }
    // eslint-disable-next-line
  }, [account, id]);

  useEffect(() => {
    filterItems(filter);
    // eslint-disable-next-line
  }, [collection, erc20Filtered]);

  useEffect(() => {
    if (clear) dispatch(reset());

    // eslint-disable-next-line
  }, [clear]);

  return (
    <div>
      {account ? (
        <>
          {(isNftLoading || isLoadingTokens) && isGettingAccount ? (
            <SwapLoading>
              <Loading isLoading />
            </SwapLoading>
          ) : (
            <>
              {isNftError || isErrorTokens ? (
                <SwapError>Some error has occurred</SwapError>
              ) : (
                <>
                  <Notification />
                  <Container>
                    {secondStep ? (
                      <>
                        {allTokens && (!isNftLoading || !isLoadingTokens) ? (
                          <SwapStepTwo
                            buttons={buttons}
                            allTokens={allTokens}
                            filterItems={filterItems}
                            editAssets={editAssets}
                            isView={isView}
                            address={nftAddress}
                            addressTokens={addressTokens}
                            setAddressTokens={setAddressTokens}
                            setAddress={nftUpdateAddress}
                            refresh={refresh}
                            isEnding={filter === 'NFT' ? isNftEnd : true}
                            next={next}
                            searchTerm={debouncedTerm ?? searchTerm}
                            setSearchTerm={(search) => setDebouncedTerm(search)}
                          />
                        ) : (
                          <SwapLoading>
                            <Loading isLoading />
                          </SwapLoading>
                        )}
                      </>
                    ) : (
                      <>
                        {allTokens && (!isNftLoading || !isLoadingTokens) ? (
                          <SwapStepOne
                            buttons={buttons}
                            allTokens={allTokens}
                            filterItems={filterItems}
                            editAssets={editAssets}
                            refresh={refresh}
                            isEnding={filter === 'NFT' ? isNftEnd : true}
                            next={next}
                            searchTerm={debouncedTerm ?? searchTerm}
                            setSearchTerm={(search) => setDebouncedTerm(search)}
                          />
                        ) : (
                          <SwapLoading>
                            <Loading isLoading />
                          </SwapLoading>
                        )}
                      </>
                    )}
                  </Container>
                  {secondStep && swapTokens.length ? (
                    <ToggleSteps
                      secondStep
                      buttonName={editAssets ? 'Back to Swap' : 'Review Trade'}
                      nextStepLink={
                        editAssets && swapTokensSecondUser.length
                          ? '/swap/step-three'
                          : swapTokensSecondUser.length
                          ? '/swap/step-three'
                          : ''
                      }
                      backLink={editAssets ? '' : '/swap/step-two/address'}
                    />
                  ) : secondStep && isView ? (
                    <></>
                  ) : (
                    <ToggleSteps
                      secondStep={false}
                      buttonName={editAssets!}
                      nextStepLink={
                        editAssets && swapTokens.length
                          ? '/swap/step-three'
                          : swapTokens.length
                          ? '/swap/step-two/address'
                          : ''
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <WalletNotConnected />
      )}
    </div>
  );
}

export default Swap;
