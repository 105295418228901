import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore
import imageExists from 'image-exists';

import Loading from '../Loading/Loading';

import { calculateModalPosition } from '../../utils/calculateModalPosition';
import { checkIsValidUrl } from '../../hooks/funcs';

import {
  addToSwapNFTUserOne,
  reduceTheAmountSwapUserOne,
  removeFromSwapUserOne,
  addToSwapNFTUserTwo,
  reduceTheAmountSwapUserTwo,
  removeFromSwapUserTwo,
} from '../../features/swapSlice';

import { TokenType } from '../../types/tokensType';
import { NFTInfoModal } from '../index';

import noImage from '../../images/no-image.svg';
import verifyCheck from '../../icons/verifiy-check.svg';

import plusIcon from '../../icons/plus-icon.svg';
import minusIcon from '../../icons/minus-icon.svg';

import thumbnail from '../../icons/video-thumbnail.png';

import {
  ERC1155ItemWrapper,
  ERC1155Image,
  ERC1155Content,
  ERC1155UpperContent,
  ERC1155BottomContent,
  ERC1155Title,
  ERC1155Amount,
  ERC1155Balance,
  ERC1155TokenName,
  ERC1155Counter,
  ERC1155CounterIcon,
  LoadingContainer,
  VerifyIcon,
  VideoThumbnail,
} from './ERC1155Item.styles';

function ERC1155Item({
  image,
  imageFallback,
  title,
  tokenName,
  balance,
  id,
  isSelected,
  amount,
  step,
  address,
  isActive,
  description,
  noSelect,
  isWhitelisted,
}: TokenType) {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [tokenImg, setTokenImg] = useState('');
  const [isNoImage, setIsNoImage] = useState(false);
  const [tokenCounter, setTokenCounter] = useState(0);
  const [isShownModal, setIsShownModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalPosition, setModalPosition] = useState('');
  const [isTitleHovered, setIsTitleHovered] = useState(false);
  const [imagePathname, setImagePathname] = useState('');

  const addToSwap = () => {
    if (step !== 2) {
      dispatch(
        addToSwapNFTUserOne({
          image: tokenImg,
          title,
          amount: tokenCounter.toString(),
          id,
          tokenStandard: 'ERC-1155',
          tokenName,
          address,
          description,
        }),
      );
    } else {
      dispatch(
        addToSwapNFTUserTwo({
          image: tokenImg,
          title,
          amount: tokenCounter.toString(),
          id,
          tokenStandard: 'ERC-1155',
          tokenName,
          address,
          description,
        }),
      );
    }
  };

  const reduceTheAmount = () => {
    if (step !== 2) {
      if (tokenCounter === 1) {
        dispatch(
          removeFromSwapUserOne({
            image: tokenImg,
            title,
            amount,
            id,
            tokenStandard: 'ERC-1155',
            tokenName,
            address,
            description,
          }),
        );
      } else {
        dispatch(
          reduceTheAmountSwapUserOne({
            image: tokenImg,
            title,
            amount,
            id,
            tokenStandard: 'ERC-1155',
            tokenName,
            address,
            description,
          }),
        );
      }
    } else if (tokenCounter === 1) {
      dispatch(
        removeFromSwapUserTwo({
          image: tokenImg,
          title,
          amount,
          id,
          tokenStandard: 'ERC-1155',
          tokenName,
          address,
          description,
        }),
      );
    } else {
      dispatch(
        reduceTheAmountSwapUserTwo({
          image: tokenImg,
          title,
          amount,
          id,
          tokenStandard: 'ERC-1155',
          tokenName,
          address,
          description,
        }),
      );
    }
  };

  const increaseTokenAmount = () => {
    setTokenCounter(tokenCounter + 1);
    addToSwap();
  };

  const decreaseTokenAmount = () => {
    setTokenCounter(tokenCounter - 1);
    reduceTheAmount();
  };

  const getModalWidth = () => {
    if (!ref.current) return;

    const newWidth = ref.current.clientWidth;
    setModalWidth(newWidth);
  };

  useEffect(() => {
    if (!isSelected) setTokenCounter(0);
  }, [isSelected]);

  useEffect(() => {
    if (!amount) return;

    setTokenCounter(+amount);
  }, [amount]);

  useEffect(() => {
    if (image) {
      const isValidUrl = checkIsValidUrl(image);
      if (isValidUrl) {
        const urlObj = new URL(image);
        setImagePathname(urlObj.pathname);
      }
    }

    imageExists(image, (exists: boolean) => {
      if (exists) {
        setTokenImg(image);
      } else {
        if (!imageFallback) {
          setIsNoImage(true);
          setTokenImg(noImage);
          return;
        }
        imageExists(imageFallback, (exists: boolean) => {
          if (exists) {
            setTokenImg(imageFallback);
          } else {
            setIsNoImage(true);
            setTokenImg(noImage);
          }
        });
      }
    });

    const timing = setTimeout(() => {
      setIsLoading(false);
    }, 1400);

    return () => clearTimeout(timing);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isActive && isTitleHovered) {
      const timing = setTimeout(() => {
        setIsShownModal(true);
      }, 400);
      return () => clearTimeout(timing);
    }
    setIsShownModal(false);
    setIsTitleHovered(false);
    setModalPosition('');
  }, [isActive, isTitleHovered]);

  useEffect(() => {
    getModalWidth();
    window.addEventListener('resize', getModalWidth);
  }, []);

  return (
    <ERC1155ItemWrapper selected={isSelected} ref={ref}>
      <NFTInfoModal
        isShown={isShownModal}
        position={modalPosition}
        title={title}
        description={description || ''}
        address={address}
        tokenId={id}
        tokenStandard="ERC-1155"
        isWhitelisted={isWhitelisted}
      />
      {isLoading ? (
        <LoadingContainer>
          <Loading isLoading />
        </LoadingContainer>
      ) : (
        <ERC1155Image
          className={isNoImage || tokenImg === '' ? 'no-image' : ''}
          src={tokenImg || noImage}
          selected={!!isSelected}
        />
      )}
      {!isLoading && imagePathname.includes('.mp4') ? <VideoThumbnail src={thumbnail}></VideoThumbnail> : <></>}
      <ERC1155Content>
        <ERC1155UpperContent>
          <ERC1155Title
            onMouseOver={(e) => {
              setTimeout(() => {
                const modalPosition = calculateModalPosition(e, modalWidth);
                setModalPosition(modalPosition);
                setIsTitleHovered(true);
              }, 100);
            }}
          >
            {title ? (title.length > 16 ? title?.slice(0, 16).concat('...') : title) : ''}{' '}
            {isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
          </ERC1155Title>
          {balance.length > 1 ? (
            <ERC1155Amount>
              X <ERC1155Balance isOneNum={false}>{balance}</ERC1155Balance>
            </ERC1155Amount>
          ) : (
            <ERC1155Amount>
              X <ERC1155Balance isOneNum>{balance}</ERC1155Balance>
            </ERC1155Amount>
          )}
        </ERC1155UpperContent>
        <ERC1155BottomContent>
          <ERC1155TokenName
            onMouseOver={(e) => {
              setTimeout(() => {
                const modalPosition = calculateModalPosition(e, modalWidth);
                setModalPosition(modalPosition);
                setIsTitleHovered(true);
              }, 100);
            }}
          >
            {tokenName ? (tokenName.length > 16 ? tokenName?.slice(0, 16).concat('...') : tokenName) : ''}
          </ERC1155TokenName>
          {isWhitelisted && !noSelect ? (
            <ERC1155Counter>
              {tokenCounter === 0 ? (
                <ERC1155CounterIcon disabled src={minusIcon} alt="" />
              ) : (
                <ERC1155CounterIcon onClick={decreaseTokenAmount} src={minusIcon} alt="" />
              )}
              {amount || 0}
              {tokenCounter === +balance ? (
                <ERC1155CounterIcon disabled src={plusIcon} alt="" />
              ) : (
                <ERC1155CounterIcon onClick={increaseTokenAmount} src={plusIcon} alt="" />
              )}
            </ERC1155Counter>
          ) : (
            <></>
          )}
        </ERC1155BottomContent>
      </ERC1155Content>
    </ERC1155ItemWrapper>
  );
}

export default ERC1155Item;
