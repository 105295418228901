import styled from 'styled-components';

interface ERC1155ItemProps {
  selected?: boolean;
  disabled?: boolean;
  isOneNum?: boolean;
}

export const ERC1155ItemWrapper = styled.div<ERC1155ItemProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border-radius: 16px;
  transition: box-shadow 0.2s ease-in-out;
  height: 382px;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  ${(props) =>
    props.selected
      ? `
          border: 4px solid #18A0FB
          `
      : ''}
`;

export const ERC1155Image = styled.img<ERC1155ItemProps>`
  width: 100%;
  height: 302px;
  object-fit: contain;
  border-radius: 16px 16px 0 0;

  &.no-image {
    width: 70%;
    height: 100%;
    max-height: 302px;
  }

  ${(props) =>
    props.selected
      ? `
          border-radius: 12px 12px 0 0;
          height: 294px;
          `
      : ''}
`;

export const ERC1155Content = styled.div`
  width: 100%;
  height: 20%;
  padding: 8px 16px;
`;

export const ERC1155UpperContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ERC1155BottomContent = styled(ERC1155UpperContent)``;

export const ERC1155Amount = styled.span`
  color: #183b56;
  font-weight: 600;
  font-size: 1rem;
`;

export const ERC1155Balance = styled.span<ERC1155ItemProps>`
  color: #ffffff;
  font-weight: 400;
  background: linear-gradient(180deg, #566ffe 0%, #3b84fd 52.64%, #18a0fb 100%);
  background-size: cover;
  padding: 4px 11px;

  ${(props) =>
    props.isOneNum
      ? `
          border-radius: 50%;
          `
      : 'border-radius: 30% / 45%;'}
`;

export const ERC1155Title = styled.h5`
  color: #183b56;
  font-weight: 600;
  font-size: 1rem;
  margin: 8px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80%;
  height: 20px;
  width: inherit;
`;

export const ERC1155TokenName = styled.span`
  font-size: 0.875rem;
  color: #183b56;
  min-height: 18px;
`;

export const ERC1155Counter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 72px;
  height: 24px;
  background: #f3f5f7;
  border-radius: 40px;
  padding: 8px 4px;
  font-size: 0.875rem;
  color: #183b56;
  cursor: auto;
  transition: all 0.2s ease-in-out;

  img {
    cursor: pointer;
  }
`;

export const ERC1155CounterIcon = styled.img<ERC1155ItemProps>`
  ${(props) =>
    props.disabled
      ? `
          opacity: 0.2;
          `
      : ''}
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const VideoThumbnail = styled.img`
  width: 50%;
  top: 20%;
  position: absolute;
`;
