export const checkBalance = (prevBalance: string, inputBalance: string) => {
  if (inputBalance === '' || inputBalance === '0') return 0;

  inputBalance = inputBalance.replace(',', '.').replace(/[^0-9$.,]/g, '');
  if (!inputBalance) return 0;

  const parts = inputBalance.split('.');
  let amount = '';

  for (let i = 0; i < parts.length; i++) {
    if (i === 1) {
      amount += `.${parts[i]}`;
    } else if (parts[i]) {
      amount += parts[i];
    } else {
      continue;
    }
  }

  if (+amount > +prevBalance) return 0;

  return amount;
};
