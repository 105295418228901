import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavbarBackground = styled.div`
  background: #ffffff;
`;

export const NavbarContainer = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 1.5rem 0;
`;

export const NavbarLeft = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavbarRight = styled.div`
  display: flex;
  align-items: center;
`;

export const NavbarLogo = styled.img`
  margin-right: 1.5rem;
  max-width: 196px;
  cursor: pointer;
`;

export const NavbarSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 312px;
  height: 40px;
  padding: 8px 16px;
  background: #f3f5f7;
  border-radius: 8px;
`;

export const NavbarSearchIcon = styled.img`
  margin-right: 12px;
`;

export const NavbarSearch = styled.input`
  border: none;
  outline: none;
  background: #f3f5f7;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
`;

export const NavbarLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-right: 40px;
`;

export const NavbarLink = styled(NavLink)`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 700;
  color: #183b56;
  position: relative;

  &.disabled {
    color: #74899a;
  }

  &.active {
    position: relative;
    background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:after {
      content: '';
      display: block;
      height: 2px;
      left: 0px;
      position: absolute;
      width: 40px;
      background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
      border-radius: 4px;
      top: 24px;
    }
  }

  .comingSoon {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: #000000;
    color: #fff;
    font-weight: 400;
    height: 30px;
    width: 120px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    top: -38px;
    left: -14px;

    &.left {
      left: -36px;
    }
  }

  .comingSoon::after {
    content: '';
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -20%;
  }

  &:hover {
    .comingSoon {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const NavbarExternalLink = styled.a`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 700;
  color: #183b56;
  position: relative;
`;

export const ConnectWalletButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-beetwen;
  gap: 10px;
  width: 190px;
  height: 40px;
  padding: 8px 16px;
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  border: none;
  border-radius: 40px;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.85;
  }
`;

export const ConnectErrorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 190px;
  height: 40px;
  padding: 8px 16px;
  background: #d2122e;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;

  img {
    max-width: 18px;
  }
`;

export const NavbarIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`;
