import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { ethers } from 'ethers';

import { selectTokensUserOne } from '../../../../app/store';
import { resetSwapUserTwo } from '../../../../features/swapSlice';

import { Container } from '../../../../globalStyles';
import ToggleSteps from '../ToggleSteps/ToggleSteps';

import { SwapContent, SwapStepText, SwapSelectText } from '../SwapStepOne/SwapStepOne.styles';
import { SwapInputWrapper, SwapText, SwapInput, SwapButton, SwapNoTokens } from './SwapInputAddress.styles';

import { findAddress } from '../../../../funcs/findAddress';

function SwapInputAddress({ isView }: { isView: boolean }) {
  const [input, setInput] = useState('');
  const [isError, setIsError] = useState(false);
  const { account, connector } = useWeb3React();
  const navigate = useNavigate();
  const swapTokensUserOne = useSelector(selectTokensUserOne);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value.replace(/\s/g, ''));
  };

  const checkIfValidAddress = () => {
    if (!isView && input === account) {
      setIsError(true);
      return;
    }

    findAddress(connector!, input).then((address) => {
      const isValid = ethers.utils.isAddress(address);
      if (isValid) {
        if (isView) {
          setIsError(false);
          navigate(`/view/${address}`);
        } else {
          setIsError(false);
          sessionStorage.setItem('secondAddress', address);
          navigate(`/swap/step-two/tokens/${address}`);
        }
      } else {
        setIsError(true);
      }
    });
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      checkIfValidAddress();
    }
  };

  useEffect(() => {
    if (!isView && !swapTokensUserOne.length) navigate('/swap');
    // eslint-disable-next-line
  }, [swapTokensUserOne]);

  useEffect(() => {
    dispatch(resetSwapUserTwo());
    // eslint-disable-next-line

    return setIsError(false);
  }, [dispatch]);

  return (
    <>
      <Container>
        <SwapContent>
          {isView ? (
            <></>
          ) : (
            <>
              <SwapStepText>Step 2 of 3</SwapStepText>
              <SwapSelectText>What Assets You Would Like From Counterparty?</SwapSelectText>
            </>
          )}
          <SwapInputWrapper>
            <SwapText>Counterparty Wallet Address</SwapText>
            {isError ? (
              <>
                <SwapNoTokens>This address either does not exist or there is no token on it</SwapNoTokens>
                <SwapInput onKeyPress={onKeyPress} onChange={handleChange} />
              </>
            ) : (
              <SwapInput onKeyPress={onKeyPress} onChange={handleChange} placeholder="Wallet Address - 0x0000 or ENS" />
            )}
            <SwapButton onClick={() => checkIfValidAddress()}>Load Assets</SwapButton>
          </SwapInputWrapper>
        </SwapContent>
      </Container>
      {isView ? <></> : <ToggleSteps secondStep backLink="/swap" />}
    </>
  );
}

export default SwapInputAddress;
