import React, { useState } from 'react';

import {
  AccountDropdownWrapper,
  AccountDropdownAddressBlock,
  AccountDropdownAddress,
  AccountDropdownCopyButtonWrapper,
  AccountDropdownCopyButton,
  AccountDropdownBalanceBlock,
  AccountDropdownBalanceImg,
  AccountDropdownBalanceETH,
  AccountDropdownChain,
  AccountDropdownDisconnectBlock,
  AccountDropdownDisconnectIcon,
} from './AccountDropdown.styles';

type AccountDropdownType = {
  account: string;
  chain: string;
  balanceETH: string;
  setShowState: Function;
  deactivate: Function;
};

const AccountDropdown = React.forwardRef<HTMLDivElement, AccountDropdownType>(
  ({ account, chain, balanceETH, deactivate, setShowState }: AccountDropdownType, ref) => {
    const [isCopied, setIsCopied] = useState('Copy');

    if (!account) {
      return <></>;
    }

    const address = `${account.substring(0, 7)}...${account.substring(35, 42)}`;

    return (
      <AccountDropdownWrapper className="dropdown" ref={ref}>
        <AccountDropdownAddressBlock>
          <AccountDropdownAddress>{address}</AccountDropdownAddress>
          <AccountDropdownCopyButtonWrapper
            onClick={() => {
              navigator.clipboard.writeText(account);
              setIsCopied('Copied');
              setTimeout(() => {
                setIsCopied('Copy');
              }, 1400);
            }}
          >
            <div className="copyWindow">
              <span>{isCopied}</span>
            </div>
            <AccountDropdownCopyButton />
          </AccountDropdownCopyButtonWrapper>
        </AccountDropdownAddressBlock>
        <AccountDropdownBalanceBlock>
          <AccountDropdownBalanceImg />
          <div className="block">
            <span className="text">Balance</span>
            <div>
              <AccountDropdownBalanceETH>{balanceETH}</AccountDropdownBalanceETH>
              <span className="ETH"> ETH</span>
            </div>
          </div>
        </AccountDropdownBalanceBlock>
        <AccountDropdownChain>{chain}</AccountDropdownChain>
        <hr style={{ width: '80%', opacity: '0.2', marginTop: '24px' }} />
        <AccountDropdownDisconnectBlock
          onClick={() => {
            setShowState(false);
            deactivate();
            localStorage.removeItem('walletconnect');
            localStorage.setItem('disconnect', 'true');
          }}
        >
          <AccountDropdownDisconnectIcon />
          <span>Disconnect Wallet</span>
        </AccountDropdownDisconnectBlock>
      </AccountDropdownWrapper>
    );
  },
);

export default AccountDropdown;
