import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import App from './App';
import GlobalStyle from './globalStyles';

import { store } from './app/store';

import { ScrollToTop } from './components';

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ScrollToTop />
        <GlobalStyle />
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
