import { BigNumber } from 'ethers';

import { JsonRpcSigner } from '@ethersproject/providers';
import { IERC20__factory } from '../types/factories/IERC20__factory';
import { IERC721__factory } from '../types';
import { IERC1155__factory } from '../types/factories/IERC1155__factory';

type ApproveObjects = {
  name: string;
  type: 'ERC-721' | 'ERC-20' | 'ERC-1155';
  isApproved: string;
};

async function makeTokenPending(approvedObject: ApproveObjects, setApprovedState: Function) {
  approvedObject.isApproved = 'pending';
  setApprovedState((prevState: any) => [...prevState]);
}

export const approveERC20 = async (
  address: string,
  amount: BigNumber,
  tokenOwner: string,
  approvedObject: ApproveObjects,
  setApprovedState: Function,
  handleError: Function,
  signer: JsonRpcSigner,
) => {
  const ERC20Contract = IERC20__factory.connect(address, signer);

  await makeTokenPending(approvedObject, setApprovedState);

  const isAllowance = await ERC20Contract.allowance(tokenOwner, `${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`);

  if (isAllowance.eq(amount)) {
    approvedObject.isApproved = 'true';
    setApprovedState((prevState: any) => [...prevState]);
    return;
  }

  try {
    await (await ERC20Contract.approve(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, amount)).wait().then(() => {
      approvedObject.isApproved = 'true';
      setApprovedState((prevState: any) => [...prevState]);
    });
  } catch (error: any) {
    handleError(error.message ? error.message : error);
    return false;
  }
};

export const approveERC721 = async (
  address: string,
  tokenOwner: string,
  approvedObject: ApproveObjects,
  setApprovedState: Function,
  handleError: Function,
  signer: JsonRpcSigner,
) => {
  const ERC721Contract = IERC721__factory.connect(address, signer);

  await makeTokenPending(approvedObject, setApprovedState);

  const isApprovedForAll = await ERC721Contract.isApprovedForAll(
    tokenOwner,
    `${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`,
  );

  if (isApprovedForAll) {
    approvedObject.isApproved = 'true';
    setApprovedState((prevState: any) => [...prevState]);
    return;
  }

  try {
    await (await ERC721Contract.setApprovalForAll(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, true))
      .wait()
      .then(() => {
        approvedObject.isApproved = 'true';
        setApprovedState((prevState: any) => [...prevState]);
      });
  } catch (error: any) {
    handleError(error.message ? error.message : error);
    return false;
  }
};

export const approveERC1155 = async (
  address: string,
  tokenOwner: string,
  approvedObject: ApproveObjects,
  setApprovedState: Function,
  handleError: Function,
  signer: JsonRpcSigner,
) => {
  const ERC1155Contract = IERC1155__factory.connect(address, signer);

  await makeTokenPending(approvedObject, setApprovedState);

  const isApprovedForAll = await ERC1155Contract.isApprovedForAll(
    tokenOwner,
    `${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`,
  );

  if (isApprovedForAll) {
    approvedObject.isApproved = 'true';
    setApprovedState((prevState: any) => [...prevState]);
    return;
  }

  try {
    await (await ERC1155Contract.setApprovalForAll(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, true))
      .wait()
      .then(() => {
        approvedObject.isApproved = 'true';
        setApprovedState((prevState: any) => [...prevState]);
      });
  } catch (error: any) {
    handleError(error.message ? error.message : error);
    return false;
  }
};
