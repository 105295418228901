import styled from 'styled-components';

export const SwapBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &.second {
    margin-top: 32px;
  }
`;

export const SwapSubtitle = styled.h5`
  color: #74899a;
  font-size: 2rem;
  font-weight: 600;
`;

export const SwapTokensWrapper = styled.div`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
`;

export const TokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  height: 382px;
  width: 100%;
  position: relative;
`;

export const TokenImageCurrency = styled.img`
  max-height: 160px;
  max-width: 160px;
  height: 50%;
  margin: 0 auto;
  margin-top: 24px;
`;

export const TokenContent = styled.div`
  width: 100%;
  height: 20%;
  padding: 8px 16px;
`;

export const TokenCurrencyWrapper = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TokenTitle = styled.h5`
  color: #183b56;
  font-weight: 600;
  margin: 8px 0;
  font-size: 1rem;
  text-align: center;
`;

export const TokenAmountCurrency = styled.span`
  color: #183b56;
  font-weight: 600;
  font-size: 2rem;
  margin: 8px 0;
  text-align: center;
  max-width: 300px;
  word-wrap: break-word;
`;

export const EditButton = styled.button`
  background: #ffffff;
  border-radius: 40px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const SwapAddress = styled.span`
  margin-left: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  border-bottom: 1px solid #18a0fb;
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  text-fill-color: transparent;
  margin-left: 25px;
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;
