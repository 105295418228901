import styled from 'styled-components';

export const WalletNotConnectedText = styled.div`
  height: 50vh;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function WalletNotConnected() {
  return <WalletNotConnectedText>You need to connect wallet first</WalletNotConnectedText>;
}

export default WalletNotConnected;
