import styled from 'styled-components';

interface ERC721ItemProps {
  selected?: boolean;
}

export const ERC721ItemWrapper = styled.div<ERC721ItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border-radius: 16px;
  transition: box-shadow 0.2s ease-in-out;
  height: 382px;
  position: relative;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  ${(props) =>
    props.selected
      ? `
          border: 4px solid #18A0FB
          `
      : ''}
`;

export const ERC721Image = styled.img<ERC721ItemProps>`
  width: 100%;
  height: 302px;
  object-fit: contain;
  border-radius: 16px 16px 0 0;

  &.no-image {
    width: 70%;
    height: 100%;
    max-height: 302px;
  }

  ${(props) =>
    props.selected
      ? `
          border-radius: 12px 12px 0 0;
          height: 294px;
          `
      : ''}
`;

export const ERC721Content = styled.div`
  width: 100%;
  height: 20%;
  padding: 8px 16px;
  position: relative;

  .helpModal {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: #000000;
    color: #fff;
    height: 105px;
    width: 232px;
    bottom: 76px;
    padding-left: 8px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    z-index: 99;
    cursor: auto;

    &__link {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      //background-clip: text;
      text-fill-color: transparent;
      text-decoration: none;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  .helpModal::after {
    content: '';
    position: relative;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -56px;
    right: 200px;
  }
`;

export const ERC721Title = styled.h5`
  color: #183b56;
  font-weight: 600;
  margin: 8px 0 10px;
  font-size: 1rem;
`;

export const ERC721Numbering = styled.span`
  font-size: 0.875rem;
  color: #183b56;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;
