import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { TokenStatus } from '../../__generated__/globalTypes';

import myTradesService from '../services/myTradesService';
import {
  GetMyTrades_incoming_nftsOne,
  GetMyTrades_incoming_nftsTwo,
} from '../services/myTradesService/__generated__/GetMyTrades';

export type AllTrades = {
  incoming?: boolean;
  __typename: 'Swap';
  id: string;
  createAction: string;
  closeAction: string;
  cancelAction: string;
  valueOne: string;
  valueTwo: string;
  addressOne: any;
  addressTwo: any;
  status: TokenStatus;
  swapStart: any;
  swapEnd: any;
  nftsOne: GetMyTrades_incoming_nftsOne[];
  nftsTwo: GetMyTrades_incoming_nftsTwo[];
}[];

export const useGetUserTrades = () => {
  const { account } = useWeb3React();
  const [trades, setTrades] = useState<AllTrades>();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const fetchTrades = async () => {
    if (!account) return;

    const myTrades = await myTradesService.getMyTrades(1000, account).catch((err) => {
      console.error('Error ', err);
    });

    if (!myTrades) return;
    const incomingTrades = myTrades.incoming.map((trade) => ({
      ...trade,
      incoming: true,
    }));

    const allTrades = [...myTrades.outcoming, ...incomingTrades];
    const sortedTrades = allTrades.sort((a, b) => b.swapStart - a.swapStart);
    if (!sortedTrades) return;

    setTrades(sortedTrades);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!account) return;

    setIsLoading(true);

    fetchTrades();
    // eslint-disable-next-line
  }, [account, isRefresh]);

  return {
    data: trades,
    isLoading,
    isRefresh,
    setIsRefresh,
  };
};
