import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SwapObject = {
  image: string;
  title: string;
  amount?: string;
  id: string;
  decimals?: string;
  description?: string;
  symbol?: string;
  tokenStandard: 'ERC-20' | 'ERC-721' | 'ERC-1155';
  tokenName?: string;
  address: string;
};

type SwapState = {
  swapTokensUserOne: SwapObject[];
  swapTokensUserTwo: SwapObject[];
};

const initialState: SwapState = {
  swapTokensUserOne: [],
  swapTokensUserTwo: [],
};

const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    addToSwapCurrencyUserOne(state, action: PayloadAction<SwapObject>) {
      const item = state.swapTokensUserOne.find((item) => item.address === action.payload.address);

      state.swapTokensUserOne.map((token) => {
        if (token === item) {
          return (token.amount = action.payload.amount);
        }
        return token;
      });

      if (item) return;

      state.swapTokensUserOne.push(action.payload);
    },
    addToSwapNFTUserOne(state, action: PayloadAction<SwapObject>) {
      const item = state.swapTokensUserOne.find(
        (item) => item.id === action.payload.id && item.address === action.payload.address,
      );

      state.swapTokensUserOne.map((token) => {
        if (token === item && token.amount) {
          let amountInNum = Number(token.amount);
          return (token.amount = (amountInNum += 1).toString());
        }
        return token;
      });

      if (item) return;

      state.swapTokensUserOne.push({ ...action.payload, amount: '1' });
    },
    reduceTheAmountSwapUserOne(state, action: PayloadAction<SwapObject>) {
      state.swapTokensUserOne.map((token) => {
        if (token.address === action.payload.address && token.id === action.payload.id && token.amount) {
          let amountInNum = Number(token.amount);
          return (token.amount = (amountInNum -= 1).toString());
        }
        return token;
      });
    },
    removeFromSwapUserOne(state, action: PayloadAction<SwapObject>) {
      const nextSwapTokens = state.swapTokensUserOne.filter(
        (token) => token.address !== action.payload.address || token?.id !== action.payload?.id,
      );

      state.swapTokensUserOne = nextSwapTokens;
    },
    addToSwapCurrencyUserTwo(state, action: PayloadAction<SwapObject>) {
      const item = state.swapTokensUserTwo.find((item) => item.address === action.payload.address);

      state.swapTokensUserTwo.map((token) => {
        if (token === item) {
          return (token.amount = action.payload.amount);
        }
        return token;
      });

      if (item) return;

      state.swapTokensUserTwo.push(action.payload);
    },
    addToSwapNFTUserTwo(state, action: PayloadAction<SwapObject>) {
      const item = state.swapTokensUserTwo.find(
        (item) => item.id === action.payload.id && item.address === action.payload.address,
      );

      state.swapTokensUserTwo.map((token) => {
        if (token === item && token.amount) {
          let amountInNum = Number(token.amount);
          return (token.amount = (amountInNum += 1).toString());
        }
        return token;
      });

      if (item) return;

      state.swapTokensUserTwo.push({ ...action.payload, amount: '1' });
    },
    reduceTheAmountSwapUserTwo(state, action: PayloadAction<SwapObject>) {
      state.swapTokensUserTwo.map((token) => {
        if (token.address === action.payload.address && token.id === action.payload.id && token.amount) {
          let amountInNum = Number(token.amount);
          return (token.amount = (amountInNum -= 1).toString());
        }
        return token;
      });
    },
    removeFromSwapUserTwo(state, action: PayloadAction<SwapObject>) {
      const nextSwapTokens = state.swapTokensUserTwo.filter(
        (token) => token.address !== action.payload.address || token?.id !== action.payload?.id,
      );

      state.swapTokensUserTwo = nextSwapTokens;
    },
    resetSwapUserTwo(state) {
      state.swapTokensUserTwo = initialState.swapTokensUserTwo;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  addToSwapCurrencyUserOne,
  addToSwapCurrencyUserTwo,
  addToSwapNFTUserOne,
  addToSwapNFTUserTwo,
  reduceTheAmountSwapUserOne,
  reduceTheAmountSwapUserTwo,
  removeFromSwapUserOne,
  removeFromSwapUserTwo,
  resetSwapUserTwo,
  reset,
} = swapSlice.actions;

export default swapSlice.reducer;
