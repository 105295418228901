import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset } from '../../features/swapSlice';

import { LeaveContainer, LeaveWrapper, LeaveText, LeaveButtonWrapper, LeaveButton } from './LeaveWarning.styles';

function LeaveWarning({ link, setShowWarning }: { link: string; setShowWarning: Function }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <LeaveContainer>
      <LeaveWrapper>
        <LeaveText>Are you sure you want to leave the trade? </LeaveText>
        <LeaveButtonWrapper>
          <LeaveButton
            onClick={() => {
              setShowWarning(false);
              dispatch(reset());
              navigate(`${link}`);
            }}
            white
          >
            Yes
          </LeaveButton>
          <LeaveButton onClick={() => setShowWarning(false)} white={false}>
            No
          </LeaveButton>
        </LeaveButtonWrapper>
      </LeaveWrapper>
    </LeaveContainer>
  );
}

export default LeaveWarning;
