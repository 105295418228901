import { useEffect, useState } from 'react';
import { NftActionType, TokenType } from './types';
import { usePlainNft } from './usePlainNft';
import { useSearchNft } from './useSearchNft';
import { filterSearch } from './funcs';

interface NftActionEvent {
  action: NftActionType;
  options?: any;
}

export const useNft = (nftAddress: string) => {
  const [event, setEvent] = useState<NftActionEvent>({
    action: 'Plain',
  });
  const [collection, setCollection] = useState<TokenType[]>([]);
  const [isNftLoading, setIsNftLoading] = useState(false);
  const [isNftError, setIsNftError] = useState(false);
  const [isNftEnd, setIsNftEnd] = useState(true);
  const [address, setAddress] = useState<string>(nftAddress);

  const {
    setIsNext,
    setSearch,
    searchIsLoading,
    searchNFTs,
    setSearchIsrefresh,
    searchIsEnd,
    searchSetAddress,
    searchAddress,
    loadSearchNft,
    loadSearchNftNext,
    updateAddress,
  } = useSearchNft(String(nftAddress));

  const {
    plainIsEnd,
    nfts,
    setPlainIsNext,
    setPlainIsrefresh,
    plainIsLoading,
    plainSetAddress,
    plainAddress,
    loadPlainNft,
    loadNextPlainNft,
  } = usePlainNft(String(nftAddress));

  const state = {
    Plain: {
      refresh: (options?: any) => {
        loadPlainNft();
      },
      updateCollection: () => {
        return setCollection(nfts)
      },
      next: () => {},
      end: () => {},
      loading: () => setIsNftLoading(plainIsLoading),
      updateAddress: () => plainSetAddress(address),
      getAddress: () => plainAddress,
    },
    Search: {
      refresh: (options?: any) => setCollection(filterSearch(collection, options?.query ?? '')),
      updateCollection: () => {},
      next: () => {},
      end: () => setIsNftEnd(searchIsEnd),
      loading: () => setIsNftLoading(searchIsLoading),
      updateAddress: () => updateAddress(address),
      getAddress: () => searchAddress,
    },
  };

  useEffect(() => {
    state[event.action].updateCollection();
  }, [searchNFTs, nfts]);

  useEffect(() => {
    state[event.action].end();
  }, [plainIsEnd, searchIsEnd]);

  useEffect(() => {
    state[event.action].loading();
  }, [plainIsLoading, searchIsLoading]);

  useEffect(() => {
    state[event.action].updateAddress();
  }, [address]);

  return {
    collection,
    dispatchAction: setEvent,
    isNftEnd,
    isNftLoading,
    isNftError,
    next: () => state[event.action].next(),
    refresh: () => state[event.action].refresh(event.options),
    updateAddress: (addressArg: string) => setAddress(addressArg),
    nftAddress: address,
  };
};
