import { useEffect, useRef, useState } from 'react';
import { ERC20TokensResponse, ETHTokenResponse } from './types';
import { useErc20FetchFromAddress } from './useErc20FetchFromAddress';

export const useGetErc20FromAddress = () => {
  const [address, setAddress] = useState('');
  const { fetchTokens } = useErc20FetchFromAddress();
  const [ERC20Tokens, setERC20Tokens] = useState<ERC20TokensResponse[]>();
  const [ETHBalance, setETHBalance] = useState<ETHTokenResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const isLoadingRef = useRef<boolean>(false);

  useEffect(() => {
    if (!address || isLoadingRef.current || isLoading) {
      return;
    }
    
    isLoadingRef.current = true;
    setIsLoading(true);
    setIsError(false);
    fetchTokens(address)
      .then((data: any) => {
        setERC20Tokens(data.tokens);
        setETHBalance(data.balance);
        setIsLoading(false);
        isLoadingRef.current = false;
      })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
        isLoadingRef.current = false;
      });
  }, [address, isRefresh]);

  return {
    ERC20Tokens,
    ETHBalance,
    isLoadingTokens: isLoading,
    isErrorTokens: isError,
    isRefreshTokens: isRefresh,
    addressTokens: address,
    setIsLoadingTokens: setIsLoading,
    setIsRefreshTokens: setIsRefresh,
    setAddressTokens: setAddress,
  };
};
