import styled from 'styled-components';

export const ConnectWalletWrapper = styled.div``;

export const ConnectWalletContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
`;

export const ConnectWalletTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  color: #183b56;
`;

export const ConnectWalletText = styled.p`
  margin-top: 8px;
  color: #74899a;
`;

export const ConnectWalletWindows = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
`;
