import { ethers } from 'ethers';
import { AbstractConnector } from '@web3-react/abstract-connector';

export const findAddress = async (connector: AbstractConnector | undefined, address: string): Promise<string> => {
  if (!connector) return address;
  const provider = new ethers.providers.Web3Provider(await connector.getProvider());
  if (!address.includes('.')) {
    return address;
  }
  const ethAddress = await provider.resolveName(address);
  if (!ethAddress) return address;
  return ethAddress;
};
