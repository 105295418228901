import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore
import imageExists from 'image-exists';

import {
  addToSwapNFTUserOne,
  addToSwapNFTUserTwo,
  removeFromSwapUserOne,
  removeFromSwapUserTwo,
} from '../../features/swapSlice';

import Loading from '../Loading/Loading';
import { NFTInfoModal } from '../index';

import { calculateModalPosition } from '../../utils/calculateModalPosition';

import noImage from '../../images/no-image.svg';
import verifyCheck from '../../icons/verifiy-check.svg';

import { TokenType } from '../../types/tokensType';

import {
  ERC721ItemWrapper,
  ERC721Image,
  ERC721Content,
  ERC721Title,
  ERC721Numbering,
  LoadingContainer,
  VerifyIcon,
} from './ERC721Item.styles';

function ERC721Item({
  image,
  imageFallback,
  title,
  tokenName,
  amount,
  id,
  isSelected,
  step,
  address,
  isActive,
  description,
  noSelect,
  isWhitelisted,
}: TokenType) {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [tokenImg, setTokenImg] = useState(image);
  const [isNoImage, setIsNoImage] = useState(false);
  const [isShownModal, setIsShownModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalPosition, setModalPosition] = useState('');
  const [isTitleHovered, setIsTitleHovered] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const openLink = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`${process.env.REACT_APP_SUPPORT_LINK}`, '_blank');
  };

  const addToSwap = () => {
    if (noSelect) return;

    if (!isWhitelisted) {
      setShowHelpModal(!showHelpModal);
      return;
    }

    if (step !== 2) {
      dispatch(
        addToSwapNFTUserOne({
          image: tokenImg,
          title,
          amount,
          id,
          tokenStandard: 'ERC-721',
          tokenName,
          address,
          description,
        }),
      );
    } else {
      dispatch(
        addToSwapNFTUserTwo({
          image: tokenImg,
          title,
          amount,
          id,
          tokenStandard: 'ERC-721',
          tokenName,
          address,
          description,
        }),
      );
    }
  };

  const removeFromSwap = () => {
    if (step !== 2) {
      dispatch(
        removeFromSwapUserOne({
          image: tokenImg,
          title,
          amount,
          id,
          tokenStandard: 'ERC-721',
          tokenName,
          address,
          description,
        }),
      );
    } else {
      dispatch(
        removeFromSwapUserTwo({
          image: tokenImg,
          title,
          amount,
          id,
          tokenStandard: 'ERC-721',
          tokenName,
          address,
          description,
        }),
      );
    }
  };

  const getModalWidth = () => {
    if (!ref.current) return;

    const newWidth = ref.current.clientWidth;
    setModalWidth(newWidth);
  };

  useEffect(() => {
    imageExists(image, (exists: boolean) => {
      if (exists) {
        setTokenImg(image);
      } else {
        if (!imageFallback) {
          setIsNoImage(true);
          setTokenImg(noImage);
          return;
        }
        imageExists(imageFallback, (exists: boolean) => {
          if (exists) {
            setTokenImg(imageFallback);
          } else {
            setIsNoImage(true);
            setTokenImg(noImage);
          }
        });
      }
    });

    const timing = setTimeout(() => {
      setIsLoading(false);
    }, 1400);
    return () => clearTimeout(timing);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isActive && isTitleHovered) {
      const timing = setTimeout(() => {
        setIsShownModal(true);
      }, 400);
      return () => clearTimeout(timing);
    }
    setIsShownModal(false);
    setShowHelpModal(false);
    setIsTitleHovered(false);
    setModalPosition('');
  }, [isActive, isTitleHovered]);

  useEffect(() => {
    getModalWidth();
    window.addEventListener('resize', getModalWidth);
  }, []);
  return (
    <>
      {isSelected ? (
        <ERC721ItemWrapper selected ref={ref} onClick={removeFromSwap}>
          <NFTInfoModal
            isShown={isShownModal}
            position={modalPosition}
            title={title}
            description={description || ''}
            address={address}
            tokenId={id}
            tokenStandard="ERC-721"
            isWhitelisted={isWhitelisted}
          />
          {isLoading ? (
            <LoadingContainer>
              <Loading isLoading />
            </LoadingContainer>
          ) : (
            <ERC721Image
              className={isNoImage || tokenImg === '' ? 'no-image' : ''}
              src={tokenImg || noImage}
              selected
            />
          )}
          <ERC721Content>
            {showHelpModal ? (
              <div className="helpModal show">
                <span>
                  This asset is not currently supported for trade. Review our{' '}
                  <span onClick={openLink} className="helpModal__link">
                    FAQ’s
                  </span>{' '}
                  to learn more about VaultSwap Verified collections.
                </span>
              </div>
            ) : (
              <div className="helpModal">
                <span>
                  This asset is not currently supported for trade. Review our{' '}
                  <span className="helpModal__link">FAQ’s</span> to learn more about VaultSwap Verified collections.
                </span>
              </div>
            )}
            <ERC721Title
              onMouseOver={(e) => {
                setTimeout(() => {
                  const modalPosition = calculateModalPosition(e, modalWidth);
                  setModalPosition(modalPosition);
                  setIsTitleHovered(true);
                }, 100);
              }}
            >
              {title ? (title.length > 28 ? title?.slice(0, 28).concat('...') : title) : ''}{' '}
              {isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
            </ERC721Title>
            <ERC721Numbering
              onMouseOver={(e) => {
                setTimeout(() => {
                  const modalPosition = calculateModalPosition(e, modalWidth);
                  setModalPosition(modalPosition);
                  setIsTitleHovered(true);
                }, 100);
              }}
            >
              {tokenName ? (tokenName.length > 28 ? tokenName?.slice(0, 28).concat('...') : tokenName) : ''}
            </ERC721Numbering>
          </ERC721Content>
        </ERC721ItemWrapper>
      ) : (
        <ERC721ItemWrapper onClick={addToSwap} ref={ref}>
          <NFTInfoModal
            isShown={isShownModal}
            position={modalPosition}
            title={title}
            description={description || ''}
            address={address}
            tokenId={id}
            tokenStandard="ERC-721"
            isWhitelisted={isWhitelisted}
          />
          {isLoading ? (
            <LoadingContainer>
              <Loading isLoading />
            </LoadingContainer>
          ) : (
            <ERC721Image
              className={isNoImage || tokenImg.includes('no-image') ? 'no-image' : ''}
              src={tokenImg || noImage}
            />
          )}
          <ERC721Content>
            {showHelpModal ? (
              <div className="helpModal show">
                <span>
                  This asset is not currently supported for trade. Review our{' '}
                  <span onClick={openLink} className="helpModal__link">
                    FAQ’s
                  </span>{' '}
                  to learn more about VaultSwap Verified collections.
                </span>
              </div>
            ) : (
              <div className="helpModal">
                <span>
                  This asset is not currently supported for trade. Review our{' '}
                  <span className="helpModal__link">FAQ’s</span> to learn more about VaultSwap Verified collections.
                </span>
              </div>
            )}
            <ERC721Title
              onMouseOver={(e) => {
                setTimeout(() => {
                  const modalPosition = calculateModalPosition(e, modalWidth);
                  setModalPosition(modalPosition);
                  setIsTitleHovered(true);
                }, 100);
              }}
            >
              {title ? (title.length > 28 ? title?.slice(0, 28).concat('...') : title) : ''}{' '}
              {isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
            </ERC721Title>
            <ERC721Numbering
              onMouseOver={(e) => {
                setTimeout(() => {
                  const modalPosition = calculateModalPosition(e, modalWidth);
                  setModalPosition(modalPosition);
                  setIsTitleHovered(true);
                }, 100);
              }}
            >
              {tokenName ? (tokenName.length > 28 ? tokenName?.slice(0, 28).concat('...') : tokenName) : ''}
            </ERC721Numbering>
          </ERC721Content>
        </ERC721ItemWrapper>
      )}
    </>
  );
}

export default ERC721Item;
