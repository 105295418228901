import { useSelector } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import { useWeb3React } from '@web3-react/core';
import ReactCursorPosition from 'react-cursor-position';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { selectTokensUserOne, selectTokensUserTwo } from '../../../../app/store';

// @ts-ignore
import verifyCheck from '../../../../icons/verifiy-check.svg';
import infoIcon from '../../../../icons/info-icon.svg';
import RefreshIcon from '../../../../icons/refresh-icon.svg';

import { TokenType } from '../../../../types/tokensType';

import TokenButton from '../TokenButton/TokenButton';

import { ERC721Item, ERC1155Item, ERC20Item } from '../../../../components/index';

import {
  SwapContent,
  SwapStepText,
  SwapSelectText,
  SwapButtonsWrapper,
  SwapItemsWrapper,
  SwapNoTokens,
  SearchWrapper,
  SearchImg,
  SwapInput,
  NothingsFound,
  InputAndSwitchWrapper,
  CheckBoxWrapper,
  CheckBoxLabel,
  CheckBox,
  VerifyIcon,
  InfoIconWrapper,
  InfoIcon,
  ToggleWrapper,
} from '../SwapStepOne/SwapStepOne.styles';

import SearchIcon from '../../../../icons/search-icon.svg';
import { RefreshButton } from '../../../ViewTokens/ViewTokens';

type SwapStepTwoProps = {
  buttons: any;
  allTokens: TokenType[];
  filterItems: Function;
  editAssets?: string;
  isView: boolean | undefined;
  setAddress: (address: string) => void;
  setAddressTokens: (address: string) => void;
  address: string | undefined;
  addressTokens: string;
  refresh: Function;
  next: () => void;
  isEnding: boolean;
  searchTerm: string;
  setSearchTerm: (search: string) => void;
};

const SwapStepTwo = ({
  buttons,
  filterItems,
  allTokens,
  editAssets,
  isView,
  setAddressTokens,
  address,
  addressTokens,
  refresh,
  isEnding,
  next,
  searchTerm,
  setSearchTerm,
}: SwapStepTwoProps) => {
  const { chainId } = useWeb3React();
  const { id } = useParams();
  const [filteredTokens, setFilteredTokens] = useState(allTokens);
  const [onlyVerified, setOnlyVerified] = useState(false);
  const [onlyNotSpam, setOnlyNotSpam] = useState(true);
  const swapTokensUserTwo = useSelector(selectTokensUserTwo);
  const swapTokens = useSelector(selectTokensUserOne);
  const navigate = useNavigate();

  useEffect(() => {
    const arrWithSelectedTokens = allTokens.map((token: TokenType) => {
      for (let i = 0; i < swapTokensUserTwo.length; i++) {
        if (swapTokensUserTwo[i].tokenStandard === 'ERC-20') {
          if (swapTokensUserTwo[i].title === token.title) {
            return {
              ...token,
              amount: swapTokensUserTwo[i].amount!,
              isSelected: true,
            };
          }
        } else if (swapTokensUserTwo[i].tokenStandard === 'ERC-20' && swapTokensUserTwo[i].title === 'Ether') {
          if (swapTokensUserTwo[i].tokenStandard === token.category) {
            return {
              ...token,
              amount: swapTokensUserTwo[i].amount!,
              isSelected: true,
            };
          }
        } else if (swapTokensUserTwo[i].address === token.address && swapTokensUserTwo[i].id === token.id) {
          return {
            ...token,
            amount: swapTokensUserTwo[i].amount!,
            isSelected: true,
          };
        }
      }

      return token;
    });

    setFilteredTokens(arrWithSelectedTokens);
  }, [allTokens, swapTokensUserTwo]);

  useEffect(() => {
    if (!isView && !swapTokens.length) {
      navigate('/swap');
    }
    // eslint-disable-next-line
  }, [swapTokens]);

  useEffect(() => {
    if (isView && !address && !addressTokens && id) {
      setAddressTokens(id);
    }

    if (!isView) {
      window.onbeforeunload = function () {
        const account = localStorage.getItem('account');
        sessionStorage.setItem('secondAddress', account!);
        navigate('/swap');
        return true;
      };

      return () => {
        window.onbeforeunload = null;
      };
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <SwapContent>
      {editAssets && swapTokens.length ? (
        <SwapSelectText>Edit Assets That You Would Like From Counterparty</SwapSelectText>
      ) : swapTokens.length ? (
        <>
          <SwapStepText>Step 2 of 3</SwapStepText>
          <SwapSelectText>Select the NFTs and Tokens You Want To Receive</SwapSelectText>
        </>
      ) : (
        <></>
      )}
      <SwapButtonsWrapper>
        {buttons
          .slice(0)
          .reverse()
          .map((button: any) => (
            <TokenButton
              key={button.name}
              token={button.name}
              isSelected={button.isSelected}
              filter={filterItems}
              clearSearch={setSearchTerm}
            />
          ))}
        <RefreshButton src={RefreshIcon} onClick={() => refresh()} alt="refresh button" />
      </SwapButtonsWrapper>
      <InputAndSwitchWrapper>
        <SearchWrapper>
          <SearchImg src={SearchIcon} />
          <SwapInput
            type="text"
            placeholder="Filter assets"
            value={searchTerm}
            onChange={(event: any) => {
              setSearchTerm(event.target.value);
            }}
          />
        </SearchWrapper>

        <ToggleWrapper>
          <CheckBoxWrapper>
            <CheckBox id="checkbox-verified" type="checkbox" onClick={() => setOnlyVerified(!onlyVerified)} />
            <CheckBoxLabel htmlFor="checkbox-verified" />
          </CheckBoxWrapper>
          <VerifyIcon src={verifyCheck} />
          <SwapStepText>Verified Only</SwapStepText>
          <InfoIconWrapper>
            <InfoIcon src={infoIcon} />
            <div className="helpModal">
              <span>When active, only verified (tradeable) assets are visible</span>
            </div>
          </InfoIconWrapper>
        </ToggleWrapper>

        {chainId !== 42 && (
          <ToggleWrapper>
            <CheckBoxWrapper>
              <CheckBox
                checked={onlyNotSpam}
                id="checkbox-spam"
                type="checkbox"
                onClick={() => {
                  setOnlyNotSpam(!onlyNotSpam);
                }}
              />
              <CheckBoxLabel htmlFor="checkbox-spam" />
            </CheckBoxWrapper>
            <SwapStepText>Hide Spam</SwapStepText>
          </ToggleWrapper>
        )}
      </InputAndSwitchWrapper>
      {!allTokens.length ? (
        <SwapNoTokens>You don't have tokens of this type</SwapNoTokens>
      ) : (
        <InfiniteScroll
          dataLength={filteredTokens.length}
          hasMore={!isEnding}
          loader={!isEnding && <h1>Loading...</h1>}
          next={next}
          style={{ overflow: 'visible' }}
        >
          <SwapItemsWrapper>
            <NothingsFound>Nothing's found</NothingsFound>
            {filteredTokens
              .filter((token) => {
                if (onlyVerified) {
                  return token.isWhitelisted;
                }
                if (onlyNotSpam) {
                  return !token.isSpam;
                }
                return token;
              })
              .filter((token) => {
                if (searchTerm === '') return token;
                if (token.category === 'Tokens') {
                  if (!token.title) {
                    return token;
                  }
                  if (
                    token.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    token.title.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return token;
                  }
                } else {
                  return token;
                }

                return null;
              })
              .map((token: TokenType) => {
                switch (token.category) {
                  case 'NFT':
                    return (
                      <Fragment key={token.uniqueKey}>
                        {token.tokenStandard === 'ERC-721' ? (
                          <ReactCursorPosition>
                            <ERC721Item
                              key={token.uniqueKey}
                              id={token.id!}
                              image={token.image}
                              imageFallback={token.imageFallback}
                              title={token.title}
                              tokenName={token.tokenName}
                              balance={token.balance!}
                              isSelected={token.isSelected!}
                              amount={token.amount!}
                              address={token.address}
                              description={token.description}
                              isWhitelisted={token.isWhitelisted}
                              step={2}
                            />
                          </ReactCursorPosition>
                        ) : (
                          <ReactCursorPosition>
                            <ERC1155Item
                              key={token.uniqueKey}
                              image={token.image}
                              imageFallback={token.imageFallback}
                              title={token.title}
                              tokenName={token.tokenName}
                              isSelected={token.isSelected!}
                              balance={token.balance!}
                              id={token.id!}
                              amount={token.amount!}
                              address={token.address}
                              description={token.description}
                              isWhitelisted={token.isWhitelisted}
                              step={2}
                            />
                          </ReactCursorPosition>
                        )}
                      </Fragment>
                    );
                  case 'Tokens':
                    return (
                      <Fragment key={token.uniqueKey}>
                        {token.tokenStandard === 'ERC-20' ? (
                          <ReactCursorPosition>
                            <ERC20Item
                              key={token.uniqueKey}
                              image={token.image}
                              title={token.title}
                              balance={token.balance!}
                              id={token.id!}
                              symbol={token.symbol}
                              decimals={token.decimals}
                              isSelected={token.isSelected!}
                              amount={token.amount!}
                              address={token.address}
                              isWhitelisted={token.isWhitelisted}
                              step={2}
                            />
                          </ReactCursorPosition>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    );
                  default:
                    return null;
                }
              })}
          </SwapItemsWrapper>
        </InfiniteScroll>
      )}
    </SwapContent>
  );
};

export default SwapStepTwo;
