import { useEffect, useState } from 'react';
import axios from 'axios';
import { TradeElement, ImageWrap } from './Accordion.styles';

type TradeElementType = {
  id: string;
  metaURI: string;
  address: string;
};

function TradeElementComp({ id, metaURI, address }: TradeElementType) {
  const [image, setImage] = useState('');
  const [name, setName] = useState('');

  const fetchMetaURIFallback = async () => {
    axios
      .get(
        `https://api.covalenthq.com/v1/${process.env.REACT_APP_NETWORK_CHAINID}/tokens/${address}/nft_metadata/${id}/?quote-currency=USD&format=JSON&key=ckey_${process.env.REACT_APP_COVALENT}`,
      )
      .then((res: any) => {
        setName(res.data.data.items[0].contract_name ?? "");
        setImage(res.data.data.items[0].nft_data[0].external_data.image);
      });
  };

  const fetchMetaURI = async (url: string) => {
    fetch(url)
      .then((response) => response.json())
      .then((data: any) => {
        if (data.image.includes('https')) {
          setImage(data.image);
          setName(data.name ?? "");
        } else {
          const image = data.image.replace('ipfs://', '');
          setImage(`https://ipfs.infura.io/ipfs/${image}`);
          setName(data.name ?? "");
        }
      })
      .catch((error) => fetchMetaURIFallback());
  };

  useEffect(() => {
    let tokenMetaURI = '';
    let url = '';

    if (metaURI.includes('ipfs://')) {
      tokenMetaURI = metaURI.replace('ipfs://', '');
      url = `https://ipfs.infura.io/ipfs/${tokenMetaURI}`;
    } else if (metaURI.includes('https')) {
      url = metaURI;
    }

    fetchMetaURI(url);
  }, []);

  return (
    <TradeElement key={id}>
      <ImageWrap>
        <img src={image} alt="nft-trade" />
      </ImageWrap>
      <span>{name.slice(0, 7).concat('...')}</span>
    </TradeElement>
  );
}

export default TradeElementComp;
