import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

export const useGetAccount = () => {
  const { account } = useWeb3React();

  const [isGettingAccount, setIsGettingAccount] = useState(true);

  useEffect(() => {
    if (!account) {
      return;
    }

    setIsGettingAccount(false);
  }, [account]);

  return { account, isGettingAccount };
};
