import styled from 'styled-components';

export const SwapInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const SwapText = styled.span`
  font-size: 0.875rem;
  color: #74899a;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const SwapInput = styled.input`
  width: 100%;
  border: none;
  height: 60px;
  border-radius: 8px;
  outline: none;
  padding: 0 16px;
  font-size: 1rem;
  color: #183b56;

  &::placeholder {
    opacity: 0.2;
  }

  margin-bottom: 24px;

  &.red {
    border: 1px solid #ff3162;
  }
`;

export const SwapButton = styled.button`
  width: 132px;
  height: 40px;
  padding: 8px 16px;
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.8)' : '#fff')};
  border: none;
  font-size: 1rem;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  background: ${(props) => (props.disabled ? '#F3F5F7' : 'linear-gradient(180deg, #566ffe 0%, #18a0fb 100%)')};
  border-radius: 40px;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const SwapNoTokens = styled.span`
  color: #ff3162;
  font-size: 0.75rem;
  position: relative;
  top: -8px;
  margin-top: 8px;
`;

export const SwapCheckingText = styled.span`
  margin-bottom: 8px;
  font-size: 0.75rem;
  display: flex;
  align-items: flex-end;
  gap: 12px;

  .dot-flashing {
    position: relative;
    width: 2px;
    height: 2px;
    border-radius: 5px;
    background-color: #183b56;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    color: #183b56;
    margin-bottom: 4px;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    color: #183b56;
  }

  .dot-flashing::before {
    left: -5px;
    width: 2px;
    height: 2px;
    border-radius: 5px;
    background-color: #183b56;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
    color: #183b56;
  }

  .dot-flashing::after {
    left: 5px;
    width: 2px;
    height: 2px;
    border-radius: 5px;
    background-color: #183b56;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
    color: #183b56;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #183b56;
      opacity: 0.4;
    }
    50%,
    100% {
      background-color: #183b56;
    }
  }
`;
