import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }

  body {
    background: #ECF1F9;

    &.no-scroll {
      overflow: hidden;
    }
  }
`;

export const Container = styled.div`
  max-width: 82.5rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  height: 100%;

  &.margin-bottom {
    margin-bottom: 4rem;
  }
`;

export default GlobalStyle;
