import styled from 'styled-components';

type ModalProps = {
  disabled: boolean;
};

type ButtonProps = {
  disabled: boolean;
};

type RowAmountProps = {
  blue?: boolean;
};

type RowProps = {
  marginTop?: boolean;
  marginBottom?: boolean;
};

export const CancelTradeContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
`;

export const CancelTradeWrapper = styled.div<ModalProps>`
  width: 500px;

  background: #ffffff;
  border: 1px solid #e3e7eb;
  box-sizing: border-box;
  border-radius: 16px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) =>
    props.disabled
      ? `
          pointer-events: none;
          `
      : ''}
`;

export const CancelTradeImg = styled.img`
  margin: 0 auto;
  min-height: 195px;
`;

export const CancelTitle = styled.h3`
  color: #183b56;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #f4f5f9;
  border-radius: 16px;
  border: 1px solid #74899a;
  width: 100%;
`;

export const InfoRow = styled.div<RowProps>`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.marginTop
      ? `
          margin-top: 12px;
          `
      : ''}

  ${(props) =>
    props.marginBottom
      ? `
          margin-bottom: 12px;
          `
      : ''}
`;

export const RowText = styled.span`
  color: #74899a;
  font-size: 1rem;
  font-weight: 400;
`;

export const RowAmount = styled.span<RowAmountProps>`
  font-size: 1.25rem;

  ${(props) =>
    props.blue
      ? `
          background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          `
      : 'color: #183b56;'}
`;

export const InfoIcon = styled.img`
  margin-left: 4px;
  cursor: pointer;
`;

export const ModalContent = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 26px;
  transition: opacity 0.2s ease-in-out;

  ${(props) =>
    props.disabled
      ? `
          opacity: 0.3;
          pointer-events: none;
          `
      : ''}
`;

export const ModalCloseIcon = styled.img`
  position: absolute;
  top: -6px;
  right: -6px;
  width: 24px;
  cursor: pointer;
`;

export const FontWeight = styled.span`
  font-weight: 600;
`;

export const CancelButton = styled.button<ButtonProps>`
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  border-radius: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  ${(props) =>
    props.disabled
      ? `
          opacity: 0.7;
          pointer-events: none;
          `
      : ''}
`;

export const CancelLink = styled.span`
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 35%;
  left: 35%;
`;

export const DottedLine = styled.hr`
  border: none;
  border-top: 4px dotted #e3e7eb;
  color: #fff;
  background-color: #f4f5f9;
  height: 4px;
  width: 100%;
  margin: 10px 0;
`;
