import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import {
  WalletWindowWrapper,
  WalletWindowCircle,
  WalletWindowImage,
  WalletWindowContent,
  WalletWindowTitle,
  WalletWindowText,
} from './WalletWindow.styles';

type WalletWindowProps = {
  icon: string;
  background: string;
  name: string;
  text: string;
  connectWallet: () => void;
};

function WalletWindow({ icon, background, name, text, connectWallet }: WalletWindowProps) {
  const web3reactContext = useWeb3React();
  const navigate = useNavigate();

  const [isConnecting, setIsConnecting] = useState(false);

  const goToSwapPage = useCallback(() => navigate('/swap', { replace: true }), [navigate]);

  useEffect(() => {
    if (web3reactContext.account) {
      setIsConnecting(false);
      goToSwapPage();
    }
  }, [web3reactContext.account]);

  return isConnecting ? (
    <WalletWindowWrapper disabled>
      <WalletWindowCircle color={background}>
        <WalletWindowImage src={icon} />
      </WalletWindowCircle>
      <WalletWindowContent>
        <WalletWindowTitle>{name}</WalletWindowTitle>
        <WalletWindowText>{text}</WalletWindowText>
      </WalletWindowContent>
    </WalletWindowWrapper>
  ) : (
    <WalletWindowWrapper
      onClick={() => {
        connectWallet();
        setIsConnecting(true);
      }}
    >
      <WalletWindowCircle color={background}>
        <WalletWindowImage src={icon} />
      </WalletWindowCircle>
      <WalletWindowContent>
        <WalletWindowTitle>{name}</WalletWindowTitle>
        <WalletWindowText>{text}</WalletWindowText>
      </WalletWindowContent>
    </WalletWindowWrapper>
  );
}

export default WalletWindow;
