import { BigNumber } from 'ethers';

import { JsonRpcSigner } from '@ethersproject/providers';
import { IERC20__factory } from '../types/factories/IERC20__factory';
import { IERC721__factory } from '../types';
import { IERC1155__factory } from '../types/factories/IERC1155__factory';

export const checkErc20 = async (account: string, address: string, amount: BigNumber, signer: JsonRpcSigner) => {
  const ERC20Contract = IERC20__factory.connect(address, signer);
  const tokensOnAccount = await ERC20Contract.balanceOf(account);

  if (tokensOnAccount.gte(amount)) {
    return true;
  }
  return false;
};

export const checkErc721 = async (
  account: string,
  address: string,
  amount: string,
  tokenId: BigNumber,
  signer: JsonRpcSigner,
) => {
  const ERC721Contract = IERC721__factory.connect(address, signer);
  const tokensOnAccount = await ERC721Contract.balanceOf(account);
  const tokenOwner = await ERC721Contract.ownerOf(tokenId);

  if (tokensOnAccount.toString() >= amount && tokenOwner.toLowerCase() === account) {
    return true;
  }
  return false;
};

export const checkErc1155 = async (
  account: string,
  address: string,
  amount: BigNumber,
  id: BigNumber,
  signer: JsonRpcSigner,
) => {
  const ERC1155Contract = IERC1155__factory.connect(address, signer);
  const tokensOnAccount = await ERC1155Contract.balanceOf(account, id);
  if (tokensOnAccount.gte(amount)) {
    return true;
  }
  return false;
};
