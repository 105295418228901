import styled from 'styled-components';
import { useState, useEffect, Fragment } from 'react';

// @ts-ignore
import ReactCursorPosition from 'react-cursor-position';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import verifyCheck from '../../icons/verifiy-check.svg';
import infoIcon from '../../icons/info-icon.svg';
import RefreshIcon from '../../icons/refresh-icon.svg';

import { ERC721Item, ERC1155Item, ERC20Item, Loading } from '../../components/index';

import { TokenType } from '../../types/tokensType';

import { Container } from '../../globalStyles';

import {
  SwapContent,
  SwapButtonsWrapper,
  SwapItemsWrapper,
  SwapNoTokens,
  SwapStepText,
  SearchWrapper,
  SearchImg,
  SwapInput,
  NothingsFound,
  InputAndSwitchWrapper,
  CheckBoxWrapper,
  CheckBoxLabel,
  CheckBox,
  VerifyIcon,
  InfoIconWrapper,
  InfoIcon,
  ToggleWrapper,
} from '../Swap/components/SwapStepOne/SwapStepOne.styles';

import { TokenButton } from '../Swap/components';

import SearchIcon from '../../icons/search-icon.svg';
import { ViewTokenButtonType } from './types';
import { useGetFilteredErc20FromAddress } from '../../hooks/useFilteredErc20FromAddress';
import { useNft } from '../../hooks/useNft';
import { useWeb3React } from '@web3-react/core';
import { findAddress } from '../../funcs/findAddress';

export const SwapLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 10%;
`;

export const RefreshButton = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 5px;
`;

type ButtonsType = {
  name: string;
  isSelected: boolean;
};

const allCategories = ['NFT', 'Tokens'];

const filteredButtons = allCategories.reduce(
  (accumulator: Array<ButtonsType>, category: string) =>
    (accumulator = [{ name: category, isSelected: false }, ...accumulator]),
  [],
);

function ViewTokens() {
  const { id } = useParams();
  const { erc20Filtered, isLoadingTokens, isRefreshTokens, setIsRefreshTokens, setAddressTokens } =
    useGetFilteredErc20FromAddress();
  const {
    collection,
    dispatchAction,
    isNftLoading,
    isNftEnd,
    next: nextNft,
    refresh: refreshNft,
    updateAddress: nftUpdateAddress,
  } = useNft(id ?? '');
  const { connector, chainId } = useWeb3React();

  const [searchTerm, setSearchTerm] = useState('');
  const [activeButton, setActiveButton] = useState<ViewTokenButtonType>('NFT');
  const [onlyVerified, setOnlyVerified] = useState(false);
  const [onlyNotSpam, setOnlyNotSpam] = useState(true);
  const [buttons, setButtons] = useState(filteredButtons);
  const [tokens, setTokens] = useState<any>([]);
  const [filter, setFilter] = useState<string>('NFT');
  const [debouncedTerm, setDebouncedTerm] = useState<string | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedTerm === null) {
        return;
      }

      setSearchTerm(debouncedTerm);
      if (!debouncedTerm) {
        dispatchAction({ action: 'Plain' });
        return;
      }

      dispatchAction({
        action: 'Search',
        options: {
          query: debouncedTerm,
          filter: 'name',
        },
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  const updateTokensForPage = (pageActiveButton: ViewTokenButtonType) => {
    const activeListLength = pageActiveButton === 'NFT' ? collection?.length : erc20Filtered?.length;
    if (tokens.length === activeListLength) {
      return;
    }
    switch (pageActiveButton) {
      case 'NFT':
        setTokens(collection);
        break;
      default:
        setTokens(erc20Filtered);
        break;
    }
  };

  const next = () => {
    if (activeButton === 'Tokens') return;
    nextNft();
  };

  const switchData = (button: ViewTokenButtonType) => {
    updateTokensForPage(button);
  };

  const setActiveButtonFunc = (buttons: Array<ButtonsType>, selectedButton: ViewTokenButtonType) => {
    buttons.map((curButton) => {
      if (curButton.name !== selectedButton) {
        return (curButton.isSelected = false);
      }

      return (curButton.isSelected = true);
    });

    setActiveButton(selectedButton);
    setButtons(buttons);
  };

  const filterItems = (button: string) => {
    setFilter(button);
    if (button === 'NFT') {
      if (!collection) return;

      setActiveButtonFunc(filteredButtons, button);
      setTokens(collection);
    } else if (button === 'Tokens') {
      if (!erc20Filtered) return;

      setActiveButtonFunc(filteredButtons, button);
      setTokens(erc20Filtered);
    }
  };

  const refresh = () => {
    if (filter === 'NFT') {
      refreshNft();
    } else if (filter === 'Tokens') {
      setIsRefreshTokens(!isRefreshTokens);
    }
  };

  useEffect(() => {
    if (!isNftLoading || !isLoadingTokens) {
      filterItems(filter);
    }
    // eslint-disable-next-line
  }, [collection, erc20Filtered]);

  useEffect(() => {
    if (!connector) return;

    findAddress(connector, id!).then((address) => {
      nftUpdateAddress(address);
      setAddressTokens(address);
    });
  }, [connector, nftUpdateAddress, setAddressTokens]);

  return (
    <Container>
      <SwapContent>
        {isNftLoading || isLoadingTokens ? (
          <SwapLoading>
            <Loading isLoading />
          </SwapLoading>
        ) : (
          <>
            <SwapButtonsWrapper>
              {buttons
                .slice(0)
                .reverse()
                .map((button: any) => {
                  return (
                    <TokenButton
                      key={button.name}
                      token={button.name}
                      isSelected={button.isSelected}
                      filter={filterItems}
                      clearSearch={setDebouncedTerm}
                    />
                  );
                })}{' '}
              <RefreshButton src={RefreshIcon} onClick={() => refresh()} alt="refresh button" />
            </SwapButtonsWrapper>
            <InputAndSwitchWrapper>
              <SearchWrapper>
                <SearchImg src={SearchIcon} />
                <SwapInput
                  type="text"
                  placeholder="Filter assets"
                  value={debouncedTerm ?? ''}
                  onChange={(event: any) => {
                    setDebouncedTerm(event.target.value);
                  }}
                />
              </SearchWrapper>

              <ToggleWrapper>
                <CheckBoxWrapper>
                  <CheckBox
                    checked={onlyVerified}
                    id="checkbox-verified"
                    type="checkbox"
                    onClick={() => setOnlyVerified(!onlyVerified)}
                  />
                  <CheckBoxLabel htmlFor="checkbox-verified" />
                </CheckBoxWrapper>
                <VerifyIcon src={verifyCheck} />
                <SwapStepText>Verified Only</SwapStepText>
                <InfoIconWrapper>
                  <InfoIcon src={infoIcon} />
                  <div className="helpModal">
                    <span>When active, only verified (tradeable) assets are visible</span>
                  </div>
                </InfoIconWrapper>
              </ToggleWrapper>

              {chainId !== 42 && (
                <ToggleWrapper>
                  <CheckBoxWrapper>
                    <CheckBox
                      checked={onlyNotSpam}
                      id="checkbox-spam"
                      type="checkbox"
                      onClick={() => {
                        setOnlyNotSpam(!onlyNotSpam);
                      }}
                    />
                    <CheckBoxLabel htmlFor="checkbox-spam" />
                  </CheckBoxWrapper>
                  <SwapStepText>Hide Spam</SwapStepText>
                </ToggleWrapper>
              )}
            </InputAndSwitchWrapper>
            {!tokens.length ? (
              <SwapNoTokens>You don't have tokens of this type</SwapNoTokens>
            ) : (
              <InfiniteScroll
                dataLength={tokens.length}
                hasMore={!isNftEnd}
                loader={!isNftEnd && filter !== 'Tokens' && <h1 style={{ marginTop: '12px' }}>Loading...</h1>}
                next={next}
                style={{ overflow: 'visible' }}
              >
                <SwapItemsWrapper>
                  <NothingsFound>Nothing's found</NothingsFound>
                  {tokens
                    .filter((token: TokenType) => {
                      if (onlyVerified) {
                        return token.isWhitelisted;
                      }
                      if (onlyNotSpam) {
                        return !token.isSpam;
                      }
                      return token;
                    })
                    .filter((token: TokenType) => {
                      if (searchTerm === '') return token;
                      if (token.category === 'Tokens') {
                        if (!token.title) {
                          return token;
                        }
                        if (
                          token.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          token.title.toLowerCase().includes(searchTerm.toLowerCase())
                        ) {
                          return token;
                        }
                      } else {
                        return token;
                      }

                      return null;
                    })
                    .map((token: TokenType) => {
                      switch (token.category) {
                        case 'NFT':
                          return (
                            <Fragment key={token.uniqueKey}>
                              {token.tokenStandard === 'ERC-721' ? (
                                <ReactCursorPosition>
                                  <ERC721Item
                                    key={token.uniqueKey}
                                    id={token.id!}
                                    image={token.image}
                                    imageFallback={token.imageFallback}
                                    title={token.title}
                                    tokenName={token.tokenName}
                                    balance={token.balance!}
                                    isSelected={token.isSelected!}
                                    amount={token.amount!}
                                    address={token.address}
                                    description={token.description}
                                    noSelect
                                    isWhitelisted={token.isWhitelisted}
                                    step={2}
                                  />
                                </ReactCursorPosition>
                              ) : (
                                <ReactCursorPosition>
                                  <ERC1155Item
                                    key={token.uniqueKey}
                                    image={token.image}
                                    imageFallback={token.imageFallback}
                                    title={token.title}
                                    tokenName={token.tokenName}
                                    isSelected={token.isSelected!}
                                    balance={token.balance!}
                                    id={token.id!}
                                    amount={token.amount!}
                                    address={token.address}
                                    description={token.description}
                                    noSelect
                                    isWhitelisted={token.isWhitelisted}
                                    step={2}
                                  />
                                </ReactCursorPosition>
                              )}
                            </Fragment>
                          );
                        case 'Tokens':
                          return (
                            <Fragment key={token.uniqueKey}>
                              {token.tokenStandard === 'ERC-20' ? (
                                <ReactCursorPosition>
                                  <ERC20Item
                                    key={token.uniqueKey}
                                    image={token.image}
                                    title={token.title}
                                    balance={token.balance!}
                                    id={token.id!}
                                    symbol={token.symbol}
                                    decimals={token.decimals}
                                    isSelected={token.isSelected!}
                                    amount={token.amount!}
                                    address={token.address}
                                    noSelect
                                    isWhitelisted={token.isWhitelisted}
                                    step={2}
                                  />
                                </ReactCursorPosition>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          );
                        default:
                          return null;
                      }
                    })}
                </SwapItemsWrapper>
              </InfiniteScroll>
            )}
          </>
        )}
      </SwapContent>
    </Container>
  );
}

export default ViewTokens;
