import { useState } from 'react';
import {
  NFTInfoModalWrapper,
  NFTInfoModalTitle,
  NFTInfoModalDescription,
  IconsWrapper,
  IconImage,
  DottedLine,
  NFTDetailsContainer,
  NFTDetailsRow,
  NFTDetailsTitle,
  NFTDetailsText,
  VerifyIcon,
} from './NFTInfoModal.styles';

import openSea from '../../icons/opensea-icon.svg';
import raribleIcon from '../../icons/rarible-icon.svg';
import looksrareIcon from '../../icons/looksrare-icon.png';
import gemIcon from '../../icons/gem-icon.png';
import verifyCheck from '../../icons/verifiy-check.svg';

type NFTInfoModalProps = {
  isShown: boolean;
  position?: string;
  title: string;
  description: string;
  address: string;
  tokenId: string;
  tokenStandard: string;
  isWhitelisted: boolean | undefined;
};

function NFTInfoModal({
  isShown,
  position,
  title,
  description,
  address,
  tokenId,
  tokenStandard,
  isWhitelisted,
}: NFTInfoModalProps) {
  const [isCopied, setIsCopied] = useState('Copy');

  const openTokenEtherscan = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/address/${address}`, '_blank');
  };

  const openTokenOpenSea = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://opensea.io/assets/${address}/${tokenId}`, '_blank');
  };

  const openTokenRarible = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://rarible.com/token/${address}:${tokenId}`, '_blank');
  };

  const openLooksrare = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://looksrare.org/collections/${address}/${tokenId}`, '_blank');
  };

  const openGems = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://www.gem.xyz/asset/${address}/${tokenId}`, '_blank');
  };

  const copyAddress = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(tokenId);
    setIsCopied('Copied');
    setTimeout(() => {
      setIsCopied('Copy');
    }, 1400);
  };

  return (
    <>
      {isShown ? (
        <NFTInfoModalWrapper position={position || ''} onClick={(e) => e.stopPropagation()}>
          <NFTInfoModalTitle>
            {title} {isWhitelisted ? <VerifyIcon src={verifyCheck} /> : <></>}
          </NFTInfoModalTitle>
          <NFTInfoModalDescription>
            {description
              ? description.length > 100
                ? description.slice(0, 100).concat('...')
                : description
              : 'No description'}
          </NFTInfoModalDescription>
          <IconsWrapper>
            <IconImage src={openSea} onClick={openTokenOpenSea} />
            <IconImage src={raribleIcon} onClick={openTokenRarible} />
            <IconImage src={looksrareIcon} onClick={openLooksrare} />
            <IconImage src={gemIcon} onClick={openGems} />
          </IconsWrapper>
          <DottedLine />
          <NFTDetailsContainer>
            <NFTDetailsRow>
              <NFTDetailsTitle>Contract</NFTDetailsTitle>
              <NFTDetailsText isBlue onClick={openTokenEtherscan}>
                {`${address.slice(0, 6)}...${address.slice(address.length - 4, address.length)}`}
              </NFTDetailsText>
            </NFTDetailsRow>
            <NFTDetailsRow>
              <NFTDetailsTitle>Token ID</NFTDetailsTitle>
              <NFTDetailsText onClick={copyAddress}>
                <div className="copyWindow">
                  <span>{isCopied}</span>
                </div>
                {tokenId.length > 40
                  ? `${tokenId.slice(0, 6)}...${tokenId.slice(tokenId.length - 4, tokenId.length)}`
                  : tokenId}
              </NFTDetailsText>
            </NFTDetailsRow>
            <NFTDetailsRow>
              <NFTDetailsTitle>Token Standard</NFTDetailsTitle>
              <NFTDetailsText>{tokenStandard}</NFTDetailsText>
            </NFTDetailsRow>
            <NFTDetailsRow>
              <NFTDetailsTitle>Blockchain</NFTDetailsTitle>
              <NFTDetailsText>{process.env.REACT_APP_BLOCKCHAIN_NAME}</NFTDetailsText>
            </NFTDetailsRow>
          </NFTDetailsContainer>
        </NFTInfoModalWrapper>
      ) : (
        <></>
      )}
    </>
  );
}

export default NFTInfoModal;
