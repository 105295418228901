import { useEffect } from 'react';

import {
  MobileWarningContainer,
  MobileWarningWrapper,
  MobileWarningText,
  ButtonsWrapper,
  Button,
} from './MobileWarning.styles';

function MobileWarning({ closeWarning }: { closeWarning: Function }) {
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => document.body.classList.remove('no-scroll');
  }, []);

  return (
    <MobileWarningContainer>
      <MobileWarningWrapper>
        <MobileWarningText>
          The application is designed for desktop usage. A mobile view is coming soon. You can proceed if you wish.
        </MobileWarningText>
        <ButtonsWrapper>
          <Button white={false} onClick={() => closeWarning(false)}>
            Proceed
          </Button>
        </ButtonsWrapper>
      </MobileWarningWrapper>
    </MobileWarningContainer>
  );
}

export default MobileWarning;
