import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { JsonRpcSigner } from '@ethersproject/providers';

import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { tokens } from '../../helper/connector';

import {
  TransactionModalContainer,
  TransactionModalWrapper,
  ProgressLineContainer,
  ProgressLine,
  ProgressLineBlue,
  ProgressBarWrapper,
  ProgressBarText,
  ProgressLineIcons,
  ProgressIcon,
  ProgressIconChecked,
  Checkbox,
  ModalCloseIcon,
  ModalContent,
  ModalRow,
  ModalTokenRow,
  InfoIcon,
  ModalTextGray,
  ModalText,
  ModalTextBold,
  ModalWrapper,
  ModalTokensWrapper,
  ModalPrice,
  FontWeight,
  TransactionTitle,
  ModalButton,
} from './TransactionModal.styles';

import closeIcon from '../../icons/close-icon.svg';
import infoIcon from '../../icons/info-icon.svg';
import { selectTokensUserOne, selectTokensUserTwo } from '../../app/store';
import { VaultSwap, VaultSwap__factory } from '../../typechain';
import { formateDataToSwap } from '../../utils/formateDataToSwap';
import { TransactionErrorModal, TransactionDoneModal } from '../index';

import CheckedIcon from '../../icons/check-icon.svg';

import { approveERC20, approveERC721, approveERC1155 } from '../../utils/approveTokens';
import { checkErc1155, checkErc20, checkErc721 } from '../../utils/checkTokensOnAccount';

function TransactionModal({
  setShowTransaction,
  address,
  gasPrice,
  fee,
  approveTrade,
  addressOne,
  addressTwo,
  swapId,
  userTwoAssets,
}: {
  setShowTransaction: Function;
  address: string;
  gasPrice: string;
  fee: string;
  approveTrade?: boolean;
  addressOne?: string;
  addressTwo?: string;
  swapId?: string;
  userTwoAssets?: any;
}) {
  const { connector } = useWeb3React();
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const [isChecked, setIsChecked] = useState(false);
  const [isSecondModal, setIsSecondModal] = useState(false);
  const [isFinalizingTrade, setIsFinalizingTrade] = useState('false');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [transactionHash, setTransactionHash] = useState('');
  const [createdSwapId, setCreatedSwapId] = useState('');
  const [showCongrats, setShowCongrats] = useState(false);

  const swapTokensFirstUser = useSelector(selectTokensUserOne);
  const swapTokensSecondUser = useSelector(selectTokensUserTwo);
  const [approvedState, setApprovedState] = useState(
    swapTokensFirstUser.map((token) => ({
      name: token.title,
      type: token.tokenStandard,
      isApproved: 'false',
    })),
  );
  const [counterPartApprovedState, setCounterPartApprovedState] = useState(approveTrade ? userTwoAssets : []);

  const counterPartAddress = sessionStorage.getItem('secondAddress');

  const merkleTree = new MerkleTree(
    tokens.map((address) => address),
    keccak256,
    { sortPairs: true, hashLeaves: true },
  );

  const getSigner = async () => {
    if (!connector) return;

    const provider = new ethers.providers.Web3Provider(await connector.getProvider());
    const signer = provider.getSigner(address);

    setSigner(signer);
  };

  const handleError = (message: string) => {
    setIsChecked(false);
    setIsSecondModal(false);
    setIsError(true);
    setErrorMessage(message);
    console.error(message);
    setApprovedState(
      swapTokensFirstUser.map((token) => ({
        name: token.title,
        type: token.tokenStandard,
        isApproved: 'false',
      })),
    );

    if (approveTrade) {
      setCounterPartApprovedState(
        userTwoAssets.map((token: any) => ({
          name: token.name,
          type: `ERC-${token.type.substring(3)}`,
          isApproved: 'false',
        })),
      );
    }

    setIsFinalizingTrade('false');
    setShowCongrats(false);
  };

  const batchSwapContract = VaultSwap__factory.connect(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, signer!);

  const userOneETH = (user: number) => {
    if (user === 1) {
      const eth = swapTokensFirstUser.find((token) => {
        if (token.title === 'Ether') {
          return ethers.utils.parseUnits(token.amount!, 18);
        }

        return 0;
      });

      if (!eth) return 0;

      return ethers.utils.parseUnits(eth.amount!, 18);
    }
    const eth = swapTokensSecondUser.find((token) => {
      if (token.title === 'Ether') {
        return ethers.utils.parseUnits(token.amount!, 18);
      }

      return 0;
    });

    if (!eth) return 0;

    return ethers.utils.parseUnits(eth.amount!, 18);
  };

  const swap = async () => {
    if (!connector || !signer) return;

    setIsSecondModal(true);
    const SIGNER_ADDRESS = await signer.getAddress();

    const swapIntnet = {
      id: 0,
      addressOne: SIGNER_ADDRESS,
      valueOne: userOneETH(1), // must
      addressTwo: counterPartAddress, // must
      valueTwo: userOneETH(2), //  must
      swapStart: 0,
      swapEnd: 0,
      swapFee: 0,
      status: 0,
    } as VaultSwap.SwapIntentStruct;

    const tokensSwap1 = await formateDataToSwap(
      swapTokensFirstUser,
      SIGNER_ADDRESS,
      approvedState,
      setApprovedState,
      handleError,
      signer,
      true,
    );

    const tokensSwap2 = await formateDataToSwap(
      swapTokensSecondUser,
      SIGNER_ADDRESS,
      approvedState,
      setApprovedState,
      handleError,
      signer,
    );

    const merkleUserOne = swapTokensFirstUser
      .slice(0, swapTokensFirstUser.length)
      .map((token) => merkleTree.getHexProof(keccak256(token.address)));

    const merkleUserTwo = swapTokensSecondUser
      .slice(0, swapTokensSecondUser.length)
      .map((token) => merkleTree.getHexProof(keccak256(token.address)));

    const batchSwapContract = VaultSwap__factory.connect(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, signer);

    const ethValue = userOneETH(1);
    const overrides = { value: ethValue };

    try {
      setIsFinalizingTrade('pending');

      const tx = await batchSwapContract.createSwapIntent(
        swapIntnet,
        tokensSwap1,
        tokensSwap2,
        merkleUserOne,
        merkleUserTwo,
        overrides,
      );

      await tx.wait();
    } catch (error: any) {
      handleError(error.message ? error.message : error);
      return;
    }

    try {
      setIsFinalizingTrade('pending');

      await batchSwapContract.getSwapListByAddress(SIGNER_ADDRESS).then((swapList) => {
        const lastSwap = swapList.slice(-1);
        setCreatedSwapId(lastSwap[0].id.toString());
        setShowCongrats(true);
      });
    } catch (error: any) {
      handleError(error.message ? error.message : error);
    }
  };

  const approveTradeFun = async () => {
    if (!connector || !signer) return;

    setIsSecondModal(true);
    if (!swapId || !addressOne || !addressTwo) return;

    const tx_nftsTwo = await batchSwapContract.getSwapStructs(swapId, false);
    const tx2 = await batchSwapContract.getSwapIntentByAddress(addressOne, swapId);
    const tx_nftsOne = await batchSwapContract.getSwapStructs(swapId, true);

    const { valueTwo } = tx2;

    for (let i = 0; i < tx_nftsTwo.length; i++) {
      switch (tx_nftsTwo[i].typeStd) {
        case 0:
          const isApprovedERC20 = await approveERC20(
            tx_nftsTwo[i].dapp,
            tx_nftsTwo[i].blc[0],
            addressTwo,
            counterPartApprovedState[i],
            setCounterPartApprovedState,
            handleError,
            signer,
          );

          if (isApprovedERC20 === false) {
            return;
          }

          if (addressTwo && tx_nftsTwo[i].dapp && tx_nftsTwo[i].blc[0]) {
            const isEnoughERC20 = await checkErc20(addressTwo, tx_nftsTwo[i].dapp, tx_nftsTwo[i].blc[0], signer);

            if (!isEnoughERC20) {
              handleError('The asset you are trying to trade is no longer in your wallet');
              return;
            }
          } else {
            handleError('The asset you are trying to trade is no longer in your wallet');
            return;
          }
          break;
        case 1:
          const isApprovedERC721 = await approveERC721(
            tx_nftsTwo[i].dapp,
            addressTwo,
            counterPartApprovedState[i],
            setCounterPartApprovedState,
            handleError,
            signer,
          );

          if (isApprovedERC721 === false) {
            return;
          }

          if (addressTwo && tx_nftsTwo[i].dapp) {
            const isEnoughERC721 = await checkErc721(
              addressTwo,
              tx_nftsTwo[i].dapp,
              '1',
              tx_nftsTwo[i].tokenId[0],
              signer,
            );
            if (!isEnoughERC721) {
              handleError('The asset you are trying to trade is no longer in your wallet');
              return;
            }
          } else {
            handleError('The asset you are trying to trade is no longer in your wallet');
            return;
          }
          break;
        case 2:
          const isApprovedERC1155 = await approveERC1155(
            tx_nftsTwo[i].dapp,
            addressTwo,
            counterPartApprovedState[i],
            setCounterPartApprovedState,
            handleError,
            signer,
          );

          if (isApprovedERC1155 === false) {
            return;
          }

          if (addressTwo && tx_nftsTwo[i].dapp && tx_nftsTwo[i].blc[0] && tx_nftsTwo[i].tokenId[0]) {
            const isEnoughERC1155 = await checkErc1155(
              addressTwo,
              tx_nftsTwo[i].dapp,
              tx_nftsTwo[i].blc[0],
              tx_nftsTwo[i].tokenId[0],
              signer,
            );

            if (!isEnoughERC1155) {
              handleError('The asset you are trying to trade is no longer in your wallet');
              return;
            }
          } else {
            handleError('The asset you are trying to trade is no longer in your wallet');
            return;
          }
          break;
      }
    }

    const overrides = { value: valueTwo };

    const merkleUserTwo = tx_nftsTwo
      .slice(0, tx_nftsTwo.length)
      .map((token) => merkleTree.getHexProof(keccak256(token.dapp)));

    const merkleUserOne = tx_nftsOne
      .slice(0, tx_nftsOne.length)
      .map((token) => merkleTree.getHexProof(keccak256(token.dapp)));

    try {
      setIsFinalizingTrade('pending');

      const tx3 = await batchSwapContract.closeSwapIntent(addressOne, swapId, merkleUserOne, merkleUserTwo, overrides);

      await tx3.wait().then((receipt) => {
        setTransactionHash(receipt.transactionHash);
        setShowCongrats(true);
      });
    } catch (error: any) {
      handleError(error.message ? error.message : error);
    }
  };

  useEffect(() => {
    if (!connector) return;

    getSigner();
  }, [connector]);

  return showCongrats ? (
    <TransactionDoneModal
      setShowCongrats={setShowCongrats}
      approveTrade={approveTrade}
      swapId={createdSwapId}
      transactionHash={transactionHash}
    />
  ) : isError ? (
    <TransactionErrorModal errorMessage={errorMessage} tryAgain={setIsError} closeModal={setShowTransaction} />
  ) : (
    <>
      {isSecondModal ? (
        <TransactionModalContainer>
          <TransactionModalWrapper>
            <ModalContent>
              <ProgressLineContainer>
                <ProgressLine />
                <ProgressLineBlue width="50%" />
                <ProgressLineIcons>
                  <ProgressBarWrapper>
                    <ProgressIconChecked src={CheckedIcon} />
                    <ProgressBarText>Confirm</ProgressBarText>
                  </ProgressBarWrapper>
                  <ProgressBarWrapper>
                    <ProgressIcon isLoading />
                    <div className="dot-flashing" />
                    <ProgressBarText>Approve</ProgressBarText>
                  </ProgressBarWrapper>
                  <ProgressBarWrapper>
                    <ProgressIcon />
                    <ProgressBarText>Complete</ProgressBarText>
                  </ProgressBarWrapper>
                </ProgressLineIcons>
              </ProgressLineContainer>
              <TransactionTitle>Approve</TransactionTitle>
              <ModalTokensWrapper>
                {!approveTrade
                  ? approvedState.map((token) => {
                      if (token.name === 'Ether') {
                        return <></>;
                      }
                      return (
                        <ModalTokenRow>
                          {token.isApproved === 'true' ? (
                            <ProgressIconChecked src={CheckedIcon} />
                          ) : token.isApproved === 'pending' ? (
                            <ProgressBarWrapper>
                              <ProgressIcon isLoading />
                              <div className="dot-flashing" />
                            </ProgressBarWrapper>
                          ) : token.isApproved === 'false' ? (
                            <ProgressBarWrapper>
                              <ProgressIcon />
                            </ProgressBarWrapper>
                          ) : (
                            <></>
                          )}
                          <ModalTextBold>
                            {token.name.length > 18 ? token.name.slice(0, 18).concat('...') : token.name} -{' '}
                            {token.type === 'ERC-721' && 'ERC-1155' ? 'Set Approval For All' : 'Approve'}{' '}
                            <InfoIcon src={infoIcon} />
                          </ModalTextBold>
                        </ModalTokenRow>
                      );
                    })
                  : counterPartApprovedState.map((token: any) => {
                      if (token.name === 'Ether') {
                        return <></>;
                      }
                      return (
                        <ModalTokenRow>
                          {token.isApproved === 'true' ? (
                            <ProgressIconChecked src={CheckedIcon} />
                          ) : token.isApproved === 'pending' ? (
                            <ProgressBarWrapper>
                              <ProgressIcon isLoading />
                              <div className="dot-flashing" />
                            </ProgressBarWrapper>
                          ) : token.isApproved === 'false' ? (
                            <ProgressBarWrapper>
                              <ProgressIcon />
                            </ProgressBarWrapper>
                          ) : (
                            <></>
                          )}
                          <ModalTextBold>
                            {token.name.length > 18 ? token.name.slice(0, 18).concat('...') : token.name} -{' '}
                            {token.type === 'ERC-721' && 'ERC-1155' ? 'Set Approval For All' : 'Approve'}{' '}
                            <InfoIcon src={infoIcon} />
                          </ModalTextBold>
                        </ModalTokenRow>
                      );
                    })}
                <ModalTokenRow>
                  {isFinalizingTrade === 'true' ? (
                    <ProgressIconChecked src={CheckedIcon} />
                  ) : isFinalizingTrade === 'pending' ? (
                    <ProgressBarWrapper>
                      <ProgressIcon isLoading />
                      <div className="dot-flashing" />
                    </ProgressBarWrapper>
                  ) : (
                    <ProgressBarWrapper>
                      <ProgressIcon />
                    </ProgressBarWrapper>
                  )}
                  <ModalTextBold>
                    VaultSwap - Finalize Trade
                    <InfoIcon src={infoIcon} />
                  </ModalTextBold>
                </ModalTokenRow>
              </ModalTokensWrapper>
            </ModalContent>
          </TransactionModalWrapper>
        </TransactionModalContainer>
      ) : (
        <TransactionModalContainer>
          <TransactionModalWrapper>
            <ModalCloseIcon
              src={closeIcon}
              onClick={() => {
                setShowTransaction(false);
                setShowCongrats(false);
                document.body.classList.remove('no-scroll');
              }}
            />
            <ModalContent>
              <ProgressLineContainer>
                <ProgressLine />
                <ProgressLineIcons>
                  <ProgressBarWrapper>
                    <ProgressIcon isLoading />
                    <div className="dot-flashing" />
                    <ProgressBarText>Confirm</ProgressBarText>
                  </ProgressBarWrapper>
                  <ProgressBarWrapper>
                    <ProgressIcon />
                    <ProgressBarText>Approve</ProgressBarText>
                  </ProgressBarWrapper>
                  <ProgressBarWrapper>
                    <ProgressIcon />
                    <ProgressBarText>Complete</ProgressBarText>
                  </ProgressBarWrapper>
                </ProgressLineIcons>
              </ProgressLineContainer>
              <TransactionTitle>Confirm</TransactionTitle>
              <ModalRow>
                <ModalTextGray>
                  VaultSwap's fee <InfoIcon src={infoIcon} />
                </ModalTextGray>
                <ModalPrice>
                  {fee} <FontWeight>ETH</FontWeight>
                </ModalPrice>
              </ModalRow>
              <ModalWrapper>
                <Checkbox type="checkbox" onClick={() => setIsChecked(!isChecked)} />
                <ModalText>
                  I understand that many NFTs and Crypto assets look alike and have done my research to be certain that
                  I know what I am trading. Further, I have read the Terms of Service and Privacy Policy.
                </ModalText>
              </ModalWrapper>
              <ModalButton disabled={!isChecked} onClick={approveTrade ? approveTradeFun : swap}>
                {approveTrade ? 'Accept Swap' : 'Initiate Swap'}
              </ModalButton>
            </ModalContent>
          </TransactionModalWrapper>
        </TransactionModalContainer>
      )}
    </>
  );
}

export default TransactionModal;
