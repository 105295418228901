import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import connectWallet from '../../utils/connectWallet';

import { Container } from '../../globalStyles';

import {
  ConnectWalletWrapper,
  ConnectWalletContent,
  ConnectWalletTitle,
  ConnectWalletText,
  ConnectWalletWindows,
} from './ConnectWallet.styles';

import WalletList from '../../utils/walletList';

import WalletWindow from './components/WalletWindow/WalletWindow';

function ConnectWallet() {
  const web3reactContext = useWeb3React();

  useEffect(() => {
    if (!web3reactContext.account) {
      return console.debug('loading...');
    }
    sessionStorage.setItem('account', web3reactContext.account);
  }, [web3reactContext]);

  return (
    <ConnectWalletWrapper>
      <Container>
        <ConnectWalletContent>
          <ConnectWalletTitle>Connect your wallet</ConnectWalletTitle>
          <ConnectWalletText>Connect with an available wallet provider</ConnectWalletText>
        </ConnectWalletContent>
        <ConnectWalletWindows>
          {WalletList.map((wallet) => (
            <WalletWindow
              key={wallet.id}
              icon={wallet.image}
              background={wallet.background}
              name={wallet.name}
              text={wallet.text}
              connectWallet={() => {
                connectWallet(web3reactContext, wallet.name);
              }}
            />
          ))}
        </ConnectWalletWindows>
      </Container>
    </ConnectWalletWrapper>
  );
}

export default ConnectWallet;
