import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import ReactCursorPosition from 'react-cursor-position';
import { useGetGasPrice } from '../../../../hooks/useGetGasPrice';
import { useGetFee } from '../../../../hooks/useGetFee';

// @ts-ignore
import verifyCheck from '../../../../icons/verifiy-check.svg';

import { selectTokensUserOne, selectTokensUserTwo } from '../../../../app/store';

import { Container } from '../../../../globalStyles';

import ToggleSteps from '../ToggleSteps/ToggleSteps';
import { TransactionModal, ERC721ItemPreview, ERC1155ItemPreview } from '../../../../components';

import { SwapContent, SwapStepText, SwapSelectText } from '../SwapStepOne/SwapStepOne.styles';

import {
  SwapBlockWrapper,
  SwapAddress,
  SwapSubtitle,
  EditButton,
  SwapTokensWrapper,
  TokenWrapper,
  TokenImageCurrency,
  TokenContent,
  TokenTitle,
  TokenAmountCurrency,
  TokenCurrencyWrapper,
  VerifyIcon,
} from './SwapStepThree.styles';

function SwapStepThree() {
  const [showTransaction, setShowTransaction] = useState(false);
  const { gasPrice, isGettingGasPrice } = useGetGasPrice();
  const { fee, isGettingFee } = useGetFee();
  const { account } = useWeb3React();
  const counterpartyAccount = sessionStorage.getItem('secondAddress');

  const swapTokensFirstUser = useSelector(selectTokensUserOne);
  const swapTokensSecondUser = useSelector(selectTokensUserTwo);

  const navigate = useNavigate();

  const openTransaction = (e: React.SyntheticEvent, hash: string) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/address/${hash}`, '_blank');
  };

  useEffect(() => {
    if (!swapTokensFirstUser.length || !swapTokensSecondUser.length) {
      navigate('/swap');
    }
    // eslint-disable-next-line
  }, [swapTokensFirstUser, swapTokensSecondUser]);

  useEffect(() => {
    if (!isGettingFee) return;
  }, [isGettingFee]);

  useEffect(() => {
    if (!isGettingGasPrice) return;
  }, [isGettingGasPrice]);

  useEffect(() => () => {}, []);

  return (
    <>
      {showTransaction && account ? (
        <TransactionModal setShowTransaction={setShowTransaction} address={account} gasPrice={gasPrice} fee={fee} />
      ) : (
        <></>
      )}
      <Container>
        <SwapContent>
          <SwapStepText>Step 3 of 3</SwapStepText>
          <SwapSelectText>Confirm Your Trade Selections</SwapSelectText>
          <SwapBlockWrapper>
            <SwapSubtitle>
              You selected{' '}
              <SwapAddress
                onClick={(e) => {
                  account && openTransaction(e, account);
                }}
              >
                {account && account.slice(0, 12).concat('...') + account.slice(30, 42).concat()}
              </SwapAddress>
            </SwapSubtitle>
            <EditButton onClick={() => navigate('/swap/user-one/edit-assets')}>Edit Assets</EditButton>
          </SwapBlockWrapper>
          <SwapTokensWrapper>
            {swapTokensFirstUser.map((token) => {
              switch (token.tokenStandard) {
                case 'ERC-721':
                  return (
                    <ReactCursorPosition>
                      <ERC721ItemPreview
                        key={token.address}
                        address={token.address}
                        image={token.image}
                        title={token.title}
                        tokenName={token.tokenName!}
                        description={token.description!}
                        id={token.id}
                      />
                    </ReactCursorPosition>
                  );

                case 'ERC-1155':
                  return (
                    <ReactCursorPosition>
                      <ERC1155ItemPreview
                        key={token.address}
                        address={token.address}
                        image={token.image}
                        title={token.title}
                        amount={token.amount!}
                        tokenName={token.tokenName!}
                        description={token.description!}
                        id={token.id}
                      />
                    </ReactCursorPosition>
                  );

                case 'ERC-20':
                  return (
                    <TokenWrapper key={token.address}>
                      <TokenImageCurrency src={token.image} />
                      <TokenContent>
                        <TokenCurrencyWrapper>
                          <TokenTitle>
                            {token.title} <VerifyIcon src={verifyCheck} />
                          </TokenTitle>
                          <TokenAmountCurrency>
                            {token.amount} {token.symbol}
                          </TokenAmountCurrency>
                        </TokenCurrencyWrapper>
                      </TokenContent>
                    </TokenWrapper>
                  );

                default:
                  return null;
              }
            })}
          </SwapTokensWrapper>
          <SwapBlockWrapper className="second">
            <SwapSubtitle>
              You want{' '}
              <SwapAddress
                onClick={(e) => {
                  counterpartyAccount && openTransaction(e, counterpartyAccount);
                }}
              >
                {counterpartyAccount &&
                  counterpartyAccount.slice(0, 12).concat('...') + counterpartyAccount.slice(30, 42).concat()}
              </SwapAddress>
            </SwapSubtitle>
            <EditButton onClick={() => navigate('/swap/user-two/edit-assets')}>Edit Assets</EditButton>
          </SwapBlockWrapper>
          <SwapTokensWrapper>
            {swapTokensSecondUser.map((token) => {
              switch (token.tokenStandard) {
                case 'ERC-721':
                  return (
                    <ReactCursorPosition>
                      <ERC721ItemPreview
                        key={token.address}
                        address={token.address}
                        image={token.image}
                        title={token.title}
                        tokenName={token.tokenName!}
                        description={token.description!}
                        id={token.id}
                      />
                    </ReactCursorPosition>
                  );

                case 'ERC-1155':
                  return (
                    <ReactCursorPosition>
                      <ERC1155ItemPreview
                        key={token.address}
                        address={token.address}
                        image={token.image}
                        title={token.title}
                        amount={token.amount!}
                        tokenName={token.tokenName!}
                        description={token.description!}
                        id={token.id}
                      />
                    </ReactCursorPosition>
                  );

                case 'ERC-20':
                  return (
                    <TokenWrapper key={token.address}>
                      <TokenImageCurrency src={token.image} />
                      <TokenContent>
                        <TokenCurrencyWrapper>
                          <TokenTitle>
                            {token.title} <VerifyIcon src={verifyCheck} />
                          </TokenTitle>
                          <TokenAmountCurrency>
                            {token.amount} {token.symbol}
                          </TokenAmountCurrency>
                        </TokenCurrencyWrapper>
                      </TokenContent>
                    </TokenWrapper>
                  );

                default:
                  return null;
              }
            })}
          </SwapTokensWrapper>
        </SwapContent>
      </Container>
      <ToggleSteps
        buttonName="Approve Trade"
        backLink={`/swap/step-two/tokens/${counterpartyAccount}`}
        nextStepLink="/swap/step-three"
        thirdStep
        setShowTransaction={setShowTransaction}
      />
    </>
  );
}

export default SwapStepThree;
