import gql from 'graphql-tag';

export const GET_MY_TRADES = gql`
  query GetMyTrades($first: Int!, $address: Bytes!) {
    outcoming: swaps(first: $first, where: { addressOne: $address }, orderBy: id, orderDirection: desc) {
      id
      createAction
      closeAction
      cancelAction
      addressOne
      addressTwo
      valueOne
      valueTwo
      status
      swapStart
      swapEnd
      nftsOne {
        id
        assetType
        dapp {
          id
          name
          symbol
          assetType
          decimals
        }
        tokenId
        balance
        metaURI
      }
      nftsTwo {
        id
        assetType
        dapp {
          id
          name
          symbol
          assetType
          decimals
        }
        tokenId
        balance
        metaURI
      }
    }

    incoming: swaps(first: $first, where: { addressTwo: $address }, orderBy: id, orderDirection: desc) {
      id
      createAction
      closeAction
      cancelAction
      addressOne
      addressTwo
      valueOne
      valueTwo
      status
      swapStart
      swapEnd
      nftsOne {
        id
        assetType
        dapp {
          id
          name
          symbol
          assetType
          decimals
        }
        tokenId
        balance
        metaURI
      }
      nftsTwo {
        id
        assetType
        dapp {
          id
          name
          symbol
          assetType
          decimals
        }
        tokenId
        balance
        metaURI
      }
    }
  }
`;

export const GET_TOKENS_FROM_ID = gql`
  query GetTokensFromId($id: ID!) {
    swaps(where: { id: $id }) {
      id
      createAction
      closeAction
      cancelAction
      addressOne
      addressTwo
      valueOne
      valueTwo
      status
      swapEnd
      nftsOne {
        id
        assetType
        dapp {
          id
          name
          symbol
          assetType
          decimals
        }
        tokenId
        balance
        metaURI
      }
      nftsTwo {
        id
        assetType
        dapp {
          id
          name
          symbol
          assetType
          decimals
        }
        tokenId
        balance
        metaURI
      }
    }
  }
`;
