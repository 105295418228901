import styled from 'styled-components';
import { TokenStyles } from '../Swap/components/TokenButton/TokenButton';

export const PrimaryHeading = styled.h1<{ mt?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #183b56;
  margin-top: ${(props) => props.mt || ''};

  img {
    margin-left: 4px;
    max-width: 16px;
    cursor: pointer;
  }
`;

export const SwapLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 10%;
`;

export const TabStyles = styled(TokenStyles)``;

export const MyTradesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 312px;
  height: 40px;
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 8px;
`;

export const SearchImg = styled.img`
  margin-right: 12px;
`;

export const MyTradesInput = styled.input`
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
`;

export const NothingsFound = styled.span`
  position: absolute;
  font-size: 1.2rem;
  z-index: -1;
  margin-top: 0.5rem;
`;
