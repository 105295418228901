import { useMoralisWeb3Api } from 'react-moralis';

export const useErc20FetchFromAddress = () => {
  const Web3Api = useMoralisWeb3Api();
  const chainName: any = `${process.env.REACT_APP_NETWORK_NAME}`;

  const fetchTokens = async (address: string) => {
    if (!address) return;
    const options = {
      chain: chainName,
      address,
    };

    const tokens = await Web3Api.account.getTokenBalances(options);
    const balance = await Web3Api.account.getNativeBalance(options);

    return {
      tokens,
      balance,
    };
  };

  return {
    fetchTokens,
  };
};
