import styled from 'styled-components';

import { Link } from 'react-router-dom';
import CancelDoneModalLogo from './assets/transaction-done-modal-logo.svg';
import CancelDoneModalExitLogo from './assets/exit-logo.svg';

type ButtonProps = {
  disabled?: boolean;
};

export const CancelDoneModalContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
`;

export const CancelDoneModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #e3e7eb;
  max-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;

export const CancelDoneModalExitButton = styled.button`
  outline: none;
  background: none;
  border: none;
  position: absolute;
  left: 95.5%;
  right: 0%;
  top: -0.5%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const CancelDoneModalExit = styled.img.attrs({
  src: `${CancelDoneModalExitLogo}`,
})`
  width: 25px;
  height: 25px;
  background-color: white;
  cursor: hover;
  border-radius: 50%;
`;

export const CancelDoneModalLogoImg = styled.img.attrs({
  src: `${CancelDoneModalLogo}`,
})`
  margin-top: 25px;
  width: 200px;
  height: 195px;
`;

export const CancelDoneModalTitle = styled.div`
  margin-top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;

  color: #183b56;
`;

export const CancelDoneModalSubTitle = styled.div`
  margin-top: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  text-align: center;

  color: #74899a;
`;
export const CancelDoneModalStepsNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;

  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const CancelDoneModalListStepsWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CancelDoneModalHashText = styled.span`
  font-family: 'Montserrat';
  text-align: center;
  font-style: normal;
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 159%;
  color: #000;
`;

export const CancelDoneModalButton = styled.button<ButtonProps>`
  width: 380px;
  height: 40px;
  margin-top: 24px;
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Montserrat';
  font-style: normal;

  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  border-radius: 40px;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  ${(props) =>
    props.disabled
      ? `
          opacity: 0.7;
          pointer-events: none;
          `
      : ''}
`;

export const CancelDoneModalLink = styled.span`
  margin-top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  text-transform: capitalize;

  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const HashAddress = styled.span`
  margin-top: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  text-transform: capitalize;

  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;
