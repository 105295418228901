import styled from 'styled-components';

export const WalletWindowWrapper = styled.div<{
  disabled?: boolean;
}>`
  ${(props) => (props.disabled ? 'pointer-events:none' : '')};
  ${(props) => (props.disabled ? 'opacity: 0.8' : '')};
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e3e7eb;
  border-radius: 16px;
  width: 312px;
  height: 244px;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`;

export const WalletWindowCircle = styled.div<{
  color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-top: 40px;
`;

export const WalletWindowImage = styled.img`
  width: 50%;
`;

export const WalletWindowContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 50%;
`;

export const WalletWindowTitle = styled.h4`
  margin: 16px 0 8px;
  color: #183b56;
`;

export const WalletWindowText = styled.p`
  text-align: center;
  color: #74899a;
`;
