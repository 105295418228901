import styled from 'styled-components';

interface ERC20ItemProps {
  disabled?: boolean;
}

export const ERC20ItemWrapper = styled.div<ERC20ItemProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  transition: box-shadow 0.2s ease-in-out;
  height: 407px;

  ${(props) =>
    props.disabled
      ? `cursor: auto;
          border: 4px solid #18A0FB;
          `
      : `cursor: pointer;
      
          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }`}
`;

export const ERC20Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f5f7;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin: 24px auto 0;
`;

export const ERC20Image = styled.img`
  width: 50%;
  border-radius: 16px;
`;

export const ERC20Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const ERC20Title = styled.h5`
  color: #183b56;
  font-weight: 600;
  margin: 8px 0 4px;
  font-size: 1rem;
  font-size: 1rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: #18a0fb;
    /* background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
`;

export const ERC20Balance = styled.span`
  color: #74899a;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
`;

export const ERC20Input = styled.input`
  width: 100%;
  min-width: 270px;
  height: 60px;
  text-align: center;
  border: none;
  background: #f3f5f7;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: #183b56;
    opacity: 0.2;
  }

  &:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  &.red {
    border: 1px solid #ff3162;
  }
`;

export const ERC20Button = styled.button<ERC20ItemProps>`
  background: ${(props) => (props.disabled ? '#F3F5F7' : 'linear-gradient(180deg, #566ffe 0%, #18a0fb 100%)')};
  border-radius: 40px;
  max-width: 119px;
  max-height: 40px;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.8)' : '#fff')};
  transition: all 0.2s ease-in-out;
  margin-top: 16px;

  &:hover {
    opacity: 0.9;
  }
`;

export const ERC20Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotEnoughBalance = styled.span`
  color: #ff3162;
  font-size: 0.75rem;
  position: relative;
  top: -8px;
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;
