/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  CustomInterface,
  CustomInterfaceInterface,
} from "../CustomInterface";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "dapp",
        type: "address",
      },
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "bridgeSafeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class CustomInterface__factory {
  static readonly abi = _abi;
  static createInterface(): CustomInterfaceInterface {
    return new utils.Interface(_abi) as CustomInterfaceInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): CustomInterface {
    return new Contract(address, _abi, signerOrProvider) as CustomInterface;
  }
}
