// @ts-ignore
import converter from 'hex2dec';
import { useEffect, useState } from 'react';

import myTradesService from '../services/myTradesService';
import { GetTokensFromId } from '../services/myTradesService/__generated__/GetTokensFromId';

export const useGetTokensFromId = (id: string) => {
  const [tokens, setTokens] = useState<GetTokensFromId>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchTokens = async () => {
    if (!id) return;

    const hexId = converter.decToHex(id);

    const tokens = await myTradesService.getTokensFromId(hexId).catch((err) => {
      console.error('Error ', err);
    });

    if (!tokens) return;

    setTokens(tokens);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);

    fetchTokens();
    // eslint-disable-next-line
  }, [id]);

  return { data: tokens, isLoading };
};
