import styled from 'styled-components';

type TokenButtonProps = {
  token: string;
  isSelected: boolean;
  filter: Function;
  clearSearch: Function;
};

export const TokenStyles = styled.button`
  background: #ffffff;
  border-radius: 40px;
  padding: 5px 19px;
  height: 40px;
  color: #74899a;
  font-size: 1.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 16px;

  &.selected {
    background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
    color: #fff;
  }
`;

function TokenButton({ token, isSelected, filter, clearSearch }: TokenButtonProps) {
  return (
    <TokenStyles
      className={isSelected ? `${token} selected` : ''}
      onClick={() => {
        filter(token);
        clearSearch('');
      }}
    >
      {token}
    </TokenStyles>
  );
}

export default TokenButton;
