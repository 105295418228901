import styled from 'styled-components';

export const SwapContent = styled.div`
  margin-top: 68px;
  margin-bottom: 124px;
`;

export const SwapStepText = styled.span`
  color: #183b56;
`;

export const SwapSelectText = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
  color: #183b56;
  margin-bottom: 16px;
`;

export const SwapButtonsWrapper = styled.div`
  display: -webkit-box;
  margin-top: 38px;
  margin-bottom: 28px;
  overflow: auto;
  width: 100%;
  white-space: nowrap;
`;

export const SwapItemsWrapper = styled.div`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
`;

export const SwapNoTokens = styled.div`
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 280px;
  height: 40px;
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 8px;
  margin: 0px;
  margin-right: 50px;
`;

export const SearchImg = styled.img`
  margin-right: 12px;
`;

export const SwapInput = styled.input`
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
`;

export const NothingsFound = styled.span`
  position: absolute;
  font-size: 1.2rem;
  z-index: -1;
  margin-top: 0.5rem;
`;

export const InputAndSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-right: 12px;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
  margin: 0 6px 0 0;
`;

export const InfoIcon = styled.img`
  position: absolute;
  cursor: pointer;
  bottom: 0;

  &:hover + .helpModal {
    visibility: visible;
    opacity: 1;
  }
`;

export const InfoIconWrapper = styled.div`
  position: relative;
  bottom: 48px;
  left: 5px;
  max-width: 50px;

  .helpModal {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: relative;
    background: #000000;
    color: #fff;
    height: 105px;
    width: 130px;
    left: -57px;
    bottom: 28px;
    padding-left: 8px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
  }

  .helpModal::after {
    content: '';
    position: relative;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -56px;
    left: -58px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
