import styled from 'styled-components';

interface NativeTokenProps {
  disabled?: boolean;
}

export const NativeTokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300%;
`;

export const NativeTokenText = styled.span`
  font-size: 0.875rem;
  color: #74899a;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
`;

export const NativeTokenInput = styled.input<NativeTokenProps>`
  width: 100%;
  border: none;
  height: 60px;
  border-radius: 8px;
  outline: none;
  padding: 0 16px;
  font-size: 1rem;
  color: #183b56;

  &::placeholder {
    opacity: 0.2;
  }

  margin-bottom: 24px;

  &.red {
    border: 1px solid #ff3162;
  }

  ${(props) =>
    props.disabled
      ? `cursor: auto;
          pointer-events: none;
          background: #FFFFFF;
          opacity: 0.8;
          `
      : ''}
`;

export const NativeTokenButton = styled.button<NativeTokenProps>`
  width: ${(props) => (props.disabled ? '134px' : '132px')};
  height: 40px;
  padding: 8px 16px;
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.8)' : '#fff')};
  border: ${(props) => (props.disabled ? '2px solid #18A0FB' : 'none')};
  font-size: 1rem;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  background: ${(props) => (props.disabled ? '#F3F5F7' : 'linear-gradient(180deg, #566ffe 0%, #18a0fb 100%)')};
  border-radius: 40px;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const NotEnoughBalance = styled.span`
  color: #ff3162;
  font-size: 0.75rem;
  position: relative;
  top: -8px;
`;
