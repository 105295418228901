import { useEffect, useState, useRef } from 'react';

import {
  ERC1155Wrapper,
  ERC1155Image,
  ERC1155ContentTop,
  ERC1155Content,
  ERC1155Title,
  ERC1155Amount,
  ERC1155Numbering,
  VerifyIcon,
} from './ERC1155ItemPreview.styles';

import { NFTInfoModal } from '../index';

import verifyCheck from '../../icons/verifiy-check.svg';

import { calculateModalPosition } from '../../utils/calculateModalPosition';

type ERC1155Props = {
  address: string;
  image: string;
  title: string;
  amount: string;
  tokenName: string;
  description: string;
  isActive?: boolean;
  id: string;
};

function ERC1155ItemPreview({ address, image, title, amount, tokenName, description, isActive, id }: ERC1155Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [isShownModal, setIsShownModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalPosition, setModalPosition] = useState('');
  const [isNoImage, setIsNoImage] = useState(false);
  const [isTitleHovered, setIsTitleHovered] = useState(false);

  const getModalWidth = () => {
    if (!ref.current) return;

    const newWidth = ref.current.clientWidth;
    setModalWidth(newWidth);
  };

  useEffect(() => {
    if (isActive && isTitleHovered) {
      const timing = setTimeout(() => {
        setIsShownModal(true);
      }, 400);
      return () => clearTimeout(timing);
    }
    setIsShownModal(false);
    setIsTitleHovered(false);
    setModalPosition('');
  }, [isActive, isTitleHovered]);

  useEffect(() => {
    if (image.includes('no-image')) {
      setIsNoImage(true);
    }

    getModalWidth();
    window.addEventListener('resize', getModalWidth);
  }, []);

  return (
    <ERC1155Wrapper key={address} ref={ref}>
      <NFTInfoModal
        isShown={isShownModal}
        position={modalPosition}
        title={title}
        description={description || ''}
        address={address}
        tokenId={id}
        tokenStandard="ERC-1155"
        isWhitelisted
      />
      <ERC1155Image className={isNoImage ? 'no-image' : ''} src={image} />
      <ERC1155Content>
        <ERC1155ContentTop>
          <ERC1155Title
            onMouseOver={(e) => {
              setTimeout(() => {
                const modalPosition = calculateModalPosition(e, modalWidth);
                setModalPosition(modalPosition);
                setIsTitleHovered(true);
              }, 100);
            }}
          >
            {title ? (title.length > 16 ? title?.slice(0, 16).concat('...') : title) : ''}{' '}
            <VerifyIcon src={verifyCheck} alt="" />
          </ERC1155Title>
          <ERC1155Amount>X{amount}</ERC1155Amount>
        </ERC1155ContentTop>
        <ERC1155Numbering
          onMouseOver={(e) => {
            setTimeout(() => {
              const modalPosition = calculateModalPosition(e, modalWidth);
              setModalPosition(modalPosition);
              setIsTitleHovered(true);
            }, 100);
          }}
        >
          {tokenName ? (tokenName.length > 16 ? tokenName?.slice(0, 16).concat('...') : tokenName) : ''}
        </ERC1155Numbering>
      </ERC1155Content>
    </ERC1155Wrapper>
  );
}

export default ERC1155ItemPreview;
