import isIPFS from 'is-ipfs';
import axios from 'axios';
import { FetchNftData, FetchNftSearchData, MoralisSearchData, TokenType } from './types';
import { v4 as uuidv4 } from 'uuid';
import { CHAIN_NAME } from './constants';

export const timeout = (ms: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const getTokenData = async (address: string, tokenId: string) => {
  const metadata = axios
    .get(
      `https://api.covalenthq.com/v1/${process.env.REACT_APP_NETWORK_CHAINID}/tokens/${address}/nft_metadata/${tokenId}/?quote-currency=USD&format=JSON&key=ckey_${process.env.REACT_APP_COVALENT}`,
    )
    .then((res: any) => {
      if (res.data.data.items[0].nft_data === null) {
        return {
          description: '',
          image: '',
          name: '',
        };
      }

      return {
        description: res.data.data.items[0].nft_data[0].external_data.description,
        image: res.data.data.items[0].nft_data[0].external_data.image,
        name: res.data.data.items[0].nft_data[0].external_data.name,
      };
    })
    .catch((e) => {
      console.error(e);
      return {
        description: '',
        image: '',
        name: '',
      };
    });
  return metadata;
};

export const getMoralisChainNameFromEnv = () => {
  let chainId = process.env.REACT_APP_NETWORK_CHAINID;

  return chainId === '1' ? 'eth' : chainId === '42' ? 'kovan' : new Error('unsupported chainId for moralis');
};

export const mapMoralisSearchNft = (searchResutls: MoralisSearchData[]): TokenType[] => {
  return searchResutls.map<TokenType>((searchData) => {
    const metadata = JSON.parse(searchData.metadata!);
    const image = metadata?.image ? metadata?.image.replace('ipfs://', 'https://ipfs.io/ipfs/') : '';

    const collectionName = metadata?.name ?? '';
    const tokenName = `collectionName #${searchData.token_id}`;

    return {
      id: searchData.token_id,
      image: image,
      address: searchData.token_address,
      title: collectionName,
      tokenName: tokenName,
      isSelected: false,
      balance: '',
      tokenStandard: searchData.contract_type === 'ERC721' ? 'ERC-721' : 'ERC-1155',
      description: metadata?.description,
      category: 'NFT',
      uniqueKey: uuidv4(),
    };
  });
};

export const mapMoralisPlainNft = (results: any): TokenType[] => {
  return results.map((item: any) => {
    const metadata = JSON.parse(item.metadata!);
    const image = metadata?.image
      ? metadata.image
      : metadata?.image_url
      ? metadata.image_url
      : metadata?.animation_url
      ? metadata.animation_url
      : metadata?.image_data
      ? metadata.image_data
      : metadata?.svg_image_data
      ? metadata.svg_image_data
      : undefined;

    const modifiedImage = image ? transformIPFS(image) : '';
    const CNDImage = getCDNPreviewUrl(modifiedImage ? modifiedImage : '', item.last_metadata_sync);

    let collectionName;
    let tokenName;
    if (item.name) {
      collectionName = item.name;

      if (metadata?.name) tokenName = metadata.name;
      else tokenName = `${item.name} #${item.token_id}`;
    } else {
      collectionName = metadata?.name ?? '';
      tokenName = metadata?.name ?? '';
    }

    return {
      id: item.token_id,
      image: CNDImage,
      address: item.token_address,
      title: collectionName,
      tokenName: tokenName,
      balance: item.amount!,
      isSelected: false,
      tokenStandard: item.contract_type === 'ERC721' ? 'ERC-721' : 'ERC-1155',
      description: metadata?.description,
      category: 'NFT',
      uniqueKey: uuidv4(),
    };
  });
};

export const filterWhiteList = (tokens: TokenType[], whitelistedAddresses: string[]) => {
  tokens!.sort((a: any, b: any) => {
    if (a.title !== 'Ether' && b.title !== 'Ether' && a.title && b.title) {
      return a.title.localeCompare(b.title);
    }
    return null;
  });

  const whitelistedTokens = tokens!.map((token) => {
    return whitelistedAddresses.map((address) => address.toLowerCase()).includes(token.address.toLowerCase())
      ? {
          ...token,
          isWhitelisted: true,
        }
      : {
          ...token,
          isWhitelisted: false,
        };
  });

  return whitelistedTokens;
};

export const searchNft = async (
  fetchData: FetchNftSearchData,
): Promise<{ tokens: TokenType[]; cursor: string | null } | null> => {
  if (!fetchData.address) return null;

  const provider = fetchData.apiProvider;

  const options = {
    chain: CHAIN_NAME,
    address: fetchData.address,
    filter: fetchData.searchQuery.filter,
    q: fetchData.searchQuery.query,
    cursor: fetchData.cursor ? fetchData.cursor : null,
  };

  const nftResult = await provider.token.searchNFTs(options);

  if (!nftResult.result) return null;

  const tokens = mapMoralisSearchNft(nftResult.result);

  return { tokens: tokens, cursor: (nftResult as any).cursor as string };
};

export const fetchNft = async (
  fetchData: FetchNftData,
): Promise<{ tokens: TokenType[]; cursor: string | null } | null> => {
  if (!fetchData.address) return null;

  const provider = fetchData.apiProvider;

  const options = {
    chain: CHAIN_NAME,
    address: fetchData.address,
    cursor: fetchData.cursor ? fetchData.cursor : null,
  };

  let initialNFTs = await provider.account.getNFTs(options);
  if (!initialNFTs.result) {
    return null;
  }

  const result = mapMoralisPlainNft(initialNFTs.result);

  return { tokens: result, cursor: (initialNFTs as any).cursor as string };
};

export const filterSearch = (tokens: TokenType[], searchTerm: string): TokenType[] | [] => {
  return tokens.filter((token) => {
    if (!token.title && token.tokenName) {
      if (
        token.tokenName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.tokenName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    } else if (!token.tokenName && token.title) {
      if (
        token.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    } else if (token.title && token.tokenName) {
      if (
        token.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return true;
      }

      if (
        token.tokenName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.tokenName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  });
};

export const filterDuplicates = (tokens: TokenType[], oldTokens: TokenType[]): TokenType[] | [] => {
  return tokens.filter((dataToken: any) => {
    return oldTokens.filter((token: any) => token.tokenName === dataToken.tokenName).length === 0;
  });
};

export const getSpamTokens = async () => {
  const AlchemyApiKey = `${process.env.REACT_APP_ALCHEMY_API_KEY}`;
  const baseURL = `https://eth-mainnet.alchemyapi.io/nft/v2/${AlchemyApiKey}/getSpamContracts/`;

  const spamTokens = await axios
    .get(`${baseURL}`)
    .then((response) => response.data)
    .catch((error) => console.error(error));

  return spamTokens;
};

export const filterSpam = (tokensArr: TokenType[], spamTokensArr: string[]) => {
  const filteredData = tokensArr.map((token) => {
    if (spamTokensArr.includes(token.address)) {
      return { ...token, isSpam: true };
    } else {
      return token;
    }
  });

  return filteredData;
};

export const getCDNPreviewUrl = (url: string, last_metadata_sync: string | undefined): string => {
  const prefix = 'https://ik.imagekit.io/vaultswap/tr:w-320/';
  let suffix = 'vaultswap_ts=' + 0;
  let urlObj;
  const isValidUrl = checkIsValidUrl(url);

  if (isValidUrl && url) {
    urlObj = new URL(url);
  }

  if (urlObj && urlObj.pathname.endsWith('.mp4')) {
    return `${prefix}${url.replace(/\+$/, '')}/ik-thumbnail.jpg`;
  }
  if (last_metadata_sync) {
    suffix = 'vaultswap_ts=' + last_metadata_sync;
  }
  if (!url || !url.startsWith('http')) {
    return url;
  }
  if (!hasSearchParams(url)) {
    return `${prefix}${url.replace(/\+$/, '')}?${suffix}`;
  } else {
    return `${prefix}${url}&${suffix}`;
  }
};

export const checkIsValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

const hasSearchParams = (url: string): boolean => {
  const urlObj = new URL(url);
  if (urlObj.search) {
    return true;
  } else {
    return false;
  }
};

export const transformIPFS = (original: string) => {
  let result: string = original;
  let IPFSPath: string | undefined;
  if (isIPFS.urlOrPath(original)) {
    const url = new URL(original);
    const prefix = `${url.protocol}//${url.host}/ipfs/`;
    IPFSPath = original.replace(prefix, '');
    if (!isIPFS.cid(IPFSPath) && !isIPFS.cidPath(IPFSPath)) {
      throw `Expected to be parsed as CID or CID Path ${IPFSPath}`;
    }
    return;
  }

  const stripped = original.replace('ipfs://', '');
  if (isIPFS.cidPath(stripped) || isIPFS.cid(stripped)) {
    IPFSPath = stripped;
  }
  const extraStripped = original.replace('ipfs://ipfs/', '');
  if (isIPFS.cidPath(extraStripped) || isIPFS.cid(extraStripped)) {
    IPFSPath = extraStripped;
  }
  if (!IPFSPath && original.includes('ipfs')) {
  }
  if (IPFSPath) {
    const transformed = `${process.env.REACT_APP_IPFS_GW}` + IPFSPath;
    return transformed;
  } else {
    return result;
  }
};
