import styled from 'styled-components';

type ModalProps = {
  disabled?: boolean;
  isLoading?: boolean;
  width?: string;
};

type ButtonProps = {
  disabled: boolean;
};

export const TransactionModalContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
`;

export const TransactionModalWrapper = styled.div<ModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;
  border: 1px solid #e3e7eb;
  width: 500px;
  min-height: 390px;
  border-radius: 16px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  ${(props) =>
    props.disabled
      ? `
          pointer-events: none;
          `
      : ''}
`;

export const ProgressLineContainer = styled.div`
  height: 4px;
  margin: 6px 0px;
  position: relative;
  width: 100%;
`;

export const ProgressLine = styled.div`
  height: 4px;
  position: absolute;
  background: #c4c4c4;
  width: 100%;
`;

export const ProgressLineBlue = styled.div<ModalProps>`
  height: 4px;
  position: absolute;
  background: #219afc;

  ${(props) =>
    props.width
      ? `
          width: ${props.width}
          `
      : ''}
`;

export const ProgressLineIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -6px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:nth-child(3) {
    span {
      left: -24px;
    }
  }

  .dot-flashing {
    position: relative;
    top: -8px;
    left: 6px;
    width: 2px;
    height: 2px;
    border-radius: 5px;
    background-color: #21b1fe;
    color: #21b1fe;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -4px;
    width: 2px;
    height: 2px;
    border-radius: 5px;
    background-color: #21b1fe;
    color: #21b1fe;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 4px;
    width: 2px;
    height: 2px;
    border-radius: 5px;
    background-color: #21b1fe;
    color: #21b1fe;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #566ffe;
    }
    50%,
    100% {
      background-color: #21b1fe;
    }
  }
`;

export const Checkbox = styled.input`
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 2px solid #219afc;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  padding-right: 6px;
  outline: none;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    margin-left: 6px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #219afc;
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export const ProgressBarText = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  color: #183b56;
  position: absolute;
  top: 16px;
  left: -17px;
`;

export const ProgressIcon = styled.span<ModalProps>`
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;

  ${(props) =>
    props.isLoading
      ? `
          border: 1.5px solid #219AFC;
          background-color: #E3E7EB;
          `
      : ''}
`;

export const ProgressIconChecked = styled.img`
  height: 15px;
  width: 15px;
`;

export const ModalCloseIcon = styled.img`
  position: absolute;
  top: -6px;
  right: -6px;
  width: 24px;
  cursor: pointer;
`;

export const ModalContent = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 26px 30px;

  ${(props) =>
    props.disabled
      ? `
          opacity: 0.3;
          pointer-events: none;
          `
      : ''}
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ModalTokenRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const TransactionTitle = styled.h2`
  color: #183b56;
  font-size: 1.5rem;
  align-self: flex-start;
  margin: 20px auto 0;
`;

export const ModalWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  gap: 5px;
  width: 100%;
`;

export const ModalTokensWrapper = styled(ModalWrapper)`
  flex-direction: column;
  gap: 24px;
`;

export const ModalTextGray = styled.span`
  color: #74899a;
  font-size: 1rem;
  font-weight: 400;
`;

export const ModalText = styled.span`
  color: #000000;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ModalTextBold = styled(ModalText)`
  font-size: 1rem;
  font-weight: 600;
`;

export const InfoIcon = styled.img`
  margin-left: 4px;
  cursor: pointer;
`;

export const ModalAddress = styled.span`
  color: #183b56;
  font-weight: 500;
`;

export const ModalPrice = styled.span`
  color: #183b56;
  font-size: 1.25rem;
`;

export const FontWeight = styled.span`
  font-weight: 600;
`;

export const DottedLine = styled.hr`
  border: none;
  border-top: 4px dotted #f3f5f7;
  color: #fff;
  background-color: #fff;
  height: 4px;
  width: 100%;
  margin: 10px 0;
`;

export const ModalPriceBlue = styled(ModalPrice)`
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ModalButton = styled.button<ButtonProps>`
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  border-radius: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  ${(props) =>
    props.disabled
      ? `
          opacity: 0.6;
          pointer-events: none;
          `
      : ''}
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 35%;
  z-index: 99;
`;
