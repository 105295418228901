import styled from 'styled-components';

type NFTProps = {
  position?: string;
  isBlue?: boolean;
};

export const NFTInfoModalWrapper = styled.div<NFTProps>`
  position: absolute;
  cursor: auto;
  bottom: 113px;
  left: ${(props) => (props.position === 'right' ? '75%;' : '-75%;')};
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 320px;
  height: 310px;
  background: #f3f5f7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  z-index: 2;
`;

export const NFTInfoModalTitle = styled.h5`
  color: #183b56;
  font-size: 1rem;
  font-weight: 600;
`;

export const NFTInfoModalDescription = styled.p`
  display: block;
  color: #74899a;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 16px 0;
  height: 20%;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0px 16px;
  gap: 1.5rem;
`;

export const IconImage = styled.img`
  max-width: 28px;
  cursor: pointer;
`;

export const DottedLine = styled.hr`
  border: 1px dashed #74899a;
`;

export const NFTDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
`;

export const NFTDetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NFTDetailsTitle = styled.span`
  font-size: 0.875rem;
  color: #74899a;
  font-weight: 400;
`;

export const NFTDetailsText = styled.span<NFTProps>`
  color: #183b56;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;

  .copyWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -35px;
    right: 0;
    background: #000000;
    color: #fff;
    height: 24px;
    width: 70px;
    font-size: 14px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
  }

  .copyWindow::after {
    content: '';
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -20%;
    right: 10%;
    z-index: 99;
  }

  &:hover {
    .copyWindow {
      visibility: visible;
      opacity: 1;
      z-index: 99;
    }
  }

  ${(props) =>
    props.isBlue
      ? `background: linear-gradient(180deg, #566FFE 0%, #18A0FB 100%);background: linear-gradient(180deg, #566FFE 0%, #18A0FB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;`
      : ''};
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;
