import styled from 'styled-components';

import copyIcon from '../../../icons/copy-icon.svg';

interface IWrapper {
  disp?: string;
  alignItems?: string;
  justifyContent?: string;
  justifySelf?: string;
  flexDirection?: string;
  flexWrap?: string;
  gap?: string;
  marg?: string;
  mw?: string;
  bg?: string;
  w?: string;
  curs?: string;
}

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: ${(props) => props.w || ''};
  display: ${(props) => props.disp || 'inline-block'};
  margin: ${(props) => props.marg};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  justify-self: ${(props) => props.justifySelf};
  flex-direction: ${(props) => props.flexDirection || ''};
  margin: ${(props) => props.marg};
  max-width: ${(props) => props.mw};
  gap: ${(props) => props.gap};
  flex-wrap: ${(props) => props.flexWrap || ''};
  background-color: ${(props) => props.bg || ''};
  cursor: ${(props) => props.curs || ''};
`;

export const AccordionWrap = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e3e7eb;
  border-radius: 16px;
  max-height: 64px;
  transition: all ease-in-out 0.15s;
  margin-bottom: 8px;
  overflow: hidden;

  &.active {
    max-height: 246px;
  }
`;

export const AccordionPreview = styled.ul`
  display: flex;
  //justify-content: space-between;
  padding: 10px 24px;
  height: 64px;
  list-style: none;
  background: #ffffff;

  li:nth-child(1) {
    margin-left: 0;
  }

  li {
    margin-left: 8%;
  }

  button {
    margin-left: 8%;
  }
`;

export const ListName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #74899a;
`;

export const AccordionElement = styled.li<{
  alignItems?: string;
  w?: string;
  minW?: string;
}>`
  display: flex;
  align-items: ${(props) => props.alignItems || ''};
  justify-content: space-between;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #183b56;
  width: ${(props) => props.w || ''};
  min-width: ${(props) => props.minW || ''};
`;

export const UserIcoWrap = styled.div`
  width: 24px;
  height: 24px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

export const LinkText = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  border-bottom: 1px solid #18a0fb;
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  text-fill-color: transparent;
`;

export const Arrow = styled.span`
  margin-left: auto;
  justify-self: end;
  margin-top: 12px;
  cursor: pointer;
  border-bottom: 2px solid #183b56;
  border-right: 2px solid #183b56;
  border-radius: 1px;
  transform: rotate(-45deg);
  width: 14px;
  height: 14px;
  transition: all ease-in-out 0.15s;

  &.active {
    transform: rotate(45deg);
  }
`;

export const AssetsWrap = styled.div`
  display: flex;
  height: 100%;
  border-top: 1px solid #ecf1f9;
  margin: 0 24px;
  cursor: auto;
`;

export const AssetTitle = styled.span`
  margin: 4px 0px 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #74899a;
`;

export const Assets = styled.div<{ br?: string; padd?: string }>`
  padding: ${(props) => props.padd || '10px'};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #183b56;
  flex: 1 1 auto;
  border-right: ${(props) => props.br || ''};
  min-width: 50%;
`;

export const Trade = styled.ul`
  list-style: none;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 200px;
`;

export const TradeElement = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const ImageWrap = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PrimaryBtn = styled.button`
  cursor: pointer;
  background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
  border-radius: 40px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  width: 140px;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const AccordionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .copyWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -30px;
    right: -36px;
    background: #000000;
    color: #fff;
    height: 24px;
    width: 70px;
    font-size: 14px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
  }

  .copyWindow::after {
    content: '';
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -20%;
    z-index: 99;
  }

  &:hover {
    .copyWindow {
      visibility: visible;
      opacity: 1;
      z-index: 99;
    }
  }
`;

export const AccordionCopyButton = styled.img.attrs({
  src: `${copyIcon}`,
})`
  width: 16px;
  height: 18px;
  cursor: pointer;
  position: relative;
  left: 8px;
`;

export const TransactionHashWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TopSpace = styled.div`
  margin-top: 25px;
`;
