import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  TransactionDoneModalButton,
  TransactionDoneModalContainer,
  TransactionDoneModalExit,
  TransactionDoneModalExitButton,
  TransactionDoneModalLink,
  TransactionDoneModalListStepsWrapper,
  TransactionDoneModalLogoImg,
  TransactionDoneModalStepsNumber,
  TransactionDoneModalStepsText,
  TransactionDoneModalStepsTitle,
  TransactionDoneModalStepsWrapper,
  TransactionDoneModalHashText,
  TransactionDoneModalSubTitle,
  TransactionDoneModalTextWrapper,
  TransactionDoneModalTitle,
  TransactionDoneModalWrapper,
  HashAddress,
} from './TransactionDoneModal.styles';

import {
  ProgressLineContainer,
  ProgressLine,
  ProgressLineBlue,
  ProgressBarWrapper,
  ProgressBarText,
  ProgressLineIcons,
  ProgressIconChecked,
} from '../TransactionModal/TransactionModal.styles';

import CheckedIcon from '../../icons/check-icon.svg';

import { reset } from '../../features/swapSlice';

function TransactionDoneModal({
  setShowCongrats,
  approveTrade,
  swapId,
  transactionHash,
}: {
  setShowCongrats: Function;
  approveTrade?: boolean;
  swapId?: string;
  transactionHash?: string;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [copyText] = useState(
    `1. Visit https://${process.env.REACT_APP_NETLIFY_URL}/my-trades\n2. Connect wallet\n3. Review, then Approve/Reject the trade`,
  );
  const [showCopiedModal, setShowCopiedModal] = useState('');

  const openTransaction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/tx/${transactionHash}`, '_blank');
  };

  useEffect(
    () => () => {
      document.body.classList.remove('no-scroll');
    },
    [],
  );

  return (
    <TransactionDoneModalContainer>
      <TransactionDoneModalWrapper>
        <TransactionDoneModalExitButton>
          <TransactionDoneModalExit
            onClick={() => {
              setShowCongrats(false);
              document.body.classList.remove('no-scroll');
              if (approveTrade) {
                navigate('/my-trades');
              } else {
                dispatch(reset());
                navigate('/swap');
              }
            }}
          />
        </TransactionDoneModalExitButton>

        <ProgressLineContainer>
          <ProgressLine />
          <ProgressLineBlue width="100%" />
          <ProgressLineIcons>
            <ProgressBarWrapper>
              <ProgressIconChecked src={CheckedIcon} />
              <ProgressBarText>Confirm</ProgressBarText>
            </ProgressBarWrapper>
            <ProgressBarWrapper>
              <ProgressIconChecked src={CheckedIcon} />
              <ProgressBarText>Approve</ProgressBarText>
            </ProgressBarWrapper>
            <ProgressBarWrapper>
              <ProgressIconChecked src={CheckedIcon} />
              <ProgressBarText>Complete</ProgressBarText>
            </ProgressBarWrapper>
          </ProgressLineIcons>
        </ProgressLineContainer>

        <TransactionDoneModalLogoImg />
        {approveTrade ? (
          <>
            <TransactionDoneModalTitle>Trade successful</TransactionDoneModalTitle>
            <TransactionDoneModalSubTitle>
              Congratulation, your trade between you and the counterparty has been succeeded!
            </TransactionDoneModalSubTitle>
            <TransactionDoneModalHashText>
              Transaction hash:{' '}
              <HashAddress onClick={openTransaction}>
                {`${transactionHash!.substring(0, 18)}...${transactionHash!.substring(24, 42)}`}
              </HashAddress>
            </TransactionDoneModalHashText>
            <TransactionDoneModalButton disabled>Share to</TransactionDoneModalButton>
          </>
        ) : (
          <>
            <TransactionDoneModalTitle>Congratulations! Trade Initiated</TransactionDoneModalTitle>
            <TransactionDoneModalSubTitle>Your VaultSwap trade ID is: {swapId}</TransactionDoneModalSubTitle>
            <TransactionDoneModalTextWrapper>
              <TransactionDoneModalStepsTitle>
                Tell your counterparty to complete the transaction in 3 simple steps:
              </TransactionDoneModalStepsTitle>
              <TransactionDoneModalListStepsWrapper>
                <TransactionDoneModalStepsWrapper>
                  <TransactionDoneModalStepsNumber>1</TransactionDoneModalStepsNumber>
                  <TransactionDoneModalStepsText>
                    Visit {`${window.location.href.replace('swap/step-three', '')}my-trades`}
                  </TransactionDoneModalStepsText>
                </TransactionDoneModalStepsWrapper>
                <TransactionDoneModalStepsWrapper>
                  <TransactionDoneModalStepsNumber>2</TransactionDoneModalStepsNumber>
                  <TransactionDoneModalStepsText>Connect wallet</TransactionDoneModalStepsText>
                </TransactionDoneModalStepsWrapper>
                <TransactionDoneModalStepsWrapper>
                  <TransactionDoneModalStepsNumber>3</TransactionDoneModalStepsNumber>
                  <TransactionDoneModalStepsText>Review, then Approve/Reject the trade</TransactionDoneModalStepsText>
                </TransactionDoneModalStepsWrapper>
              </TransactionDoneModalListStepsWrapper>
              <TransactionDoneModalButton
                onClick={() => {
                  navigator.clipboard.writeText(copyText);
                  setShowCopiedModal('visible');
                  setTimeout(() => {
                    setShowCopiedModal('');
                  }, 1400);
                }}
              >
                <div className={`copyWindow ${showCopiedModal}`}>
                  <span>Copied</span>
                </div>
                Copy text
              </TransactionDoneModalButton>
            </TransactionDoneModalTextWrapper>
          </>
        )}
        <TransactionDoneModalLink
          onClick={() => {
            document.body.classList.remove('no-scroll');
            navigate('/my-trades', { replace: true });
            window.location.reload();
          }}
        >
          Go To My Trades
        </TransactionDoneModalLink>
      </TransactionDoneModalWrapper>
    </TransactionDoneModalContainer>
  );
}
export default TransactionDoneModal;
