import { useEffect, useState } from 'react';
import { TradeElement, ImageWrap } from './Accordion.styles';

function TradeElementFallback({ imagePromise, name }: { imagePromise: Promise<any>; name: string }) {
  const [image, setImage] = useState();

  const waitPromise = async () => {
    const response = await imagePromise;
    setImage(response);
  };

  useEffect(() => {
    waitPromise();
  }, []);

  return (
    <TradeElement>
      <ImageWrap>
        <img src={image} alt="nft-trade" />
      </ImageWrap>
      <span>{name.slice(0, 7).concat('...')}</span>
    </TradeElement>
  );
}

export default TradeElementFallback;
