import { useEffect, useState, useRef } from 'react';

import axios from 'axios';

import {
  TokenWrapper,
  TokenImage,
  TokenContent,
  TokenUpperContent,
  TokenTitle,
  TokenNumbering,
  LoadingContainer,
  VerifyIcon,
} from '../../ReviewTrade.styles';

import { NFTInfoModal } from '../../../../components/index';
import Loading from '../../../../components/Loading/Loading';
import verifyCheck from '../../../../icons/verifiy-check.svg';
import noImage from '../../../../images/no-image.svg';

import { calculateModalPosition } from '../../../../utils/calculateModalPosition';

type ERC721TokenType = {
  id: string;
  tokenId: any[];
  name: string;
  address: string;
  isWhitelisted: boolean;
  isActive?: boolean;
};

function ERC721Token({ id, tokenId, name, address, isActive, isWhitelisted }: ERC721TokenType) {
  const ref = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [isShownModal, setIsShownModal] = useState(false);
  const [tokenName, setTokenName] = useState('');
  const [modalWidth, setModalWidth] = useState(0);
  const [modalPosition, setModalPosition] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isTitleHovered, setIsTitleHovered] = useState(false);

  const getTokenData = async () => {
    axios
      .get(
        `https://api.covalenthq.com/v1/${process.env.REACT_APP_NETWORK_CHAINID}/tokens/${address}/nft_metadata/${tokenId}/?quote-currency=USD&format=JSON&key=ckey_${process.env.REACT_APP_COVALENT}`,
      )
      .then((res: any) => {
        setImage(res.data.data.items[0].nft_data[0].external_data.image);
        setTokenName(res.data.data.items[0].nft_data[0].external_data.name);
        setDescription(res.data.data.items[0].nft_data[0].external_data.description);
      });
  };

  useEffect(() => {
    getTokenData();
  }, []);

  const getModalWidth = () => {
    if (!ref.current) return;

    const newWidth = ref.current.clientWidth;
    setModalWidth(newWidth);
  };

  useEffect(() => {
    if (isActive && isTitleHovered) {
      const timing = setTimeout(() => {
        setIsShownModal(true);
      }, 400);
      return () => clearTimeout(timing);
    }
    setIsShownModal(false);
    setIsTitleHovered(false);
    setModalPosition('');
  }, [isActive, isTitleHovered]);

  useEffect(() => {
    const timing = setTimeout(() => {
      setIsLoading(false);
    }, 1400);

    getModalWidth();
    window.addEventListener('resize', getModalWidth);

    return () => clearTimeout(timing);
  }, []);

  return (
    <TokenWrapper key={id} ref={ref}>
      <NFTInfoModal
        isShown={isShownModal}
        position={modalPosition}
        title={name}
        description={description || ''}
        address={address}
        tokenId={tokenId[0]}
        tokenStandard="ERC-721"
        isWhitelisted={isWhitelisted}
      />
      {isLoading ? (
        <LoadingContainer>
          <Loading isLoading />
        </LoadingContainer>
      ) : (
        <TokenImage className={image === '' ? 'no-image' : ''} src={image || noImage} />
      )}
      <TokenContent>
        <TokenUpperContent>
          <TokenTitle
            onMouseOver={(e) => {
              setTimeout(() => {
                const modalPosition = calculateModalPosition(e, modalWidth);
                setModalPosition(modalPosition);
                setIsTitleHovered(true);
              }, 100);
            }}
          >
            {name ? (name.length > 28 ? name?.slice(0, 28).concat('...') : name) : ''}{' '}
            {isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
          </TokenTitle>
        </TokenUpperContent>
        <TokenNumbering
          onMouseOver={(e) => {
            setTimeout(() => {
              const modalPosition = calculateModalPosition(e, modalWidth);
              setModalPosition(modalPosition);
              setIsTitleHovered(true);
            }, 100);
          }}
        >
          {tokenName ? (tokenName.length > 28 ? tokenName?.slice(0, 28).concat('...') : tokenName) : ''}
        </TokenNumbering>
      </TokenContent>
    </TokenWrapper>
  );
}

export default ERC721Token;
