import styled from 'styled-components';

import copyIcon from '../../icons/copy-icon.svg';
import ethereumIcon from '../../icons/ethereum-icon.svg';
import disconnectIcon from '../../icons/disconnect-icon.svg';

export const AccountDropdownWrapper = styled.div`
  position: absolute;
  z-index: 99;
  right: 0;
  top: 68px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 240px;
  height: 211px;

  background: #ffffff;
  border: 1px solid #e3e7eb;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const AccountDropdownAddressBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 80%;
`;

export const AccountDropdownAddress = styled.span`
  color: #183b56;
`;

export const AccountDropdownCopyButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .copyWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -14px;
    right: -8px;
    background: #000000;
    color: #fff;
    height: 30px;
    width: 80px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
  }

  .copyWindow::after {
    content: '';
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -20%;
  }

  &:hover {
    .copyWindow {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const AccountDropdownCopyButton = styled.img.attrs({
  src: `${copyIcon}`,
})`
  width: 16px;
  height: 18px;
  cursor: pointer;
  position: relative;
`;

export const AccountDropdownBalanceBlock = styled.div`
  display: flex;
  gap: 8px;
  width: 80%;
  margin-top: 16px;

  div.block {
    span.text {
      color: #74899a;
      font-size: 0.75rem;
    }

    div {
      span.ETH {
        font-weight: 700;
        color: #183b56;
      }
    }
  }
`;

export const AccountDropdownBalanceImg = styled.img.attrs({
  src: `${ethereumIcon}`,
})`
  width: 40px;
  height: 40px;
`;

export const AccountDropdownBalanceETH = styled.span`
  font-weight: 500;
  color: #183b56;
`;

export const AccountDropdownChain = styled.span`
  margin-top: 10px;
  width: 80%;
  display: block;
  color: #74899a;
  font-size: 0.75rem;
`;

export const AccountDropdownDisconnectBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-top: 16px;
  width: 80%;
  cursor: pointer;

  span {
    color: #183b56;
    font-weight: 600;
  }
`;

export const AccountDropdownDisconnectIcon = styled.img.attrs({
  src: `${disconnectIcon}`,
})`
  width: 24px;
  height: 24px;
`;
