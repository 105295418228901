import { useState, useEffect } from 'react';

import styled from 'styled-components';

import Bell from '../../icons/bell.svg';
import Close from '../../icons/close.svg';

import { Container } from '../../../../globalStyles';

import { Chains } from '../../../../helper/connector';

export const NotificationBKGD = styled.div`
  height: 40px;
  background: #74899a;
`;

export const NotificationWrapper = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 100%;
`;

export const NotificationIcon = styled.img`
  margin-right: 8px;
`;

export const CloseIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 0;
  padding-right: 1.5rem;
`;

export const NotificationLink = styled.a`
  font-size: 0.875rem;
  cursor: pointer;
  color: #ffffff;
  margin-left: 8px;
`;

function Notification() {
  const [showNotification, setShowNotification] = useState(true);
  const [notificationString, setNotificationString] = useState('');
  const networkName = Chains[+`${process.env.REACT_APP_NETWORK_CHAINID}`];

  useEffect(() => {
    const showNotification = sessionStorage.getItem('notification');

    setNotificationString(`${process.env.REACT_APP_NOTIFICATION_TEXT}`);

    if (showNotification === 'false') {
      setShowNotification(false);
    }
  }, []);

  return (
    <>
      {showNotification ? (
        <NotificationBKGD>
          <NotificationWrapper>
            <NotificationIcon src={Bell} />
            {notificationString}
            <NotificationLink href={process.env.REACT_APP_NOTIFICATION_LINK}>Click here for details!</NotificationLink>
            <CloseIcon
              src={Close}
              onClick={() => {
                setShowNotification(false);
                sessionStorage.setItem('notification', 'false');
              }}
            />
          </NotificationWrapper>
        </NotificationBKGD>
      ) : (
        <></>
      )}
    </>
  );
}

export default Notification;
