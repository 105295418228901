import axios from 'axios';

export const getNFTMetadata = async (address: string, tokenId: string) => {
  const imageUrl = await axios
    .get(
      `https://api.covalenthq.com/v1/${process.env.REACT_APP_NETWORK_CHAINID}/tokens/${address}/nft_metadata/${tokenId}/?quote-currency=USD&format=JSON&key=ckey_${process.env.REACT_APP_COVALENT}`,
    )
    .then((res: any) => res.data.data.items[0].nft_data[0].external_data.image);

  return imageUrl;
};
