import { useEffect, useState, useRef } from 'react';
import { TokenType } from './types';
import { useMoralisWeb3Api } from 'react-moralis';
import { fetchNft, filterWhiteList, filterDuplicates, getSpamTokens, timeout, filterSpam } from './funcs';
import { tokens as whitelistedAddresses } from '../helper/connector';

export const usePlainNft = (addressArg: string) => {
  const [nfts, setNfts] = useState<TokenType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [isError, setIsError] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [cursor, setCursor] = useState<string | null>(null);
  const [address, setAddress] = useState(addressArg);
  const Web3Api = useMoralisWeb3Api();

  const loadSpanTokens = async () => {
    const spamTokens = await getSpamTokens()
      .then((data) => {
        if (data) {
          return data;
        }
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        isLoadingRef.current = false;
      });

    return spamTokens;
  };

  const loadAll = async (address: string): Promise<TokenType[]> => {
    let cursor: string | null = null;
    let tokens: TokenType[] = [];

    do {
      try {
        const result: { tokens: TokenType[]; cursor: string | null } | null = await fetchNft({
          apiProvider: Web3Api,
          address: address,
          cursor: cursor,
        });

        tokens = tokens.concat(result?.tokens ?? []);
        cursor = result?.cursor ?? null;
      } catch (e) {
        await timeout(1000);
      }
    } while (cursor !== null && cursor !== undefined && cursor !== '');

    const spamNfts = await loadSpanTokens();
    if (!spamNfts) {
      return tokens;
    }
    const allTokens = filterSpam(tokens, spamNfts);
    return allTokens;
  };

  const loadPlainNft = () => {
    if (isLoading || isLoadingRef.current || !address) {
      return;
    }

    isLoadingRef.current = true;
    setIsLoading(true);
    setIsError(false);

    loadAll(address)
      .then((data) => {
        if (data) {
          const receivedTokens = filterWhiteList(data, whitelistedAddresses);
          setNfts(receivedTokens);
          setIsEnd(true);
        }
        setIsLoading(false);
        isLoadingRef.current = false;
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        isLoadingRef.current = false;
      });
  };

  const loadNextPlainNft = () => {
    if (isNext || isEnd || !address) {
      return;
    }

    setIsNext(true);
    setIsError(false);

    fetchNft({
      apiProvider: Web3Api,
      address: address,
      cursor: cursor,
    })
      .then((data) => {
        if (data) {
          const receivedTokens = filterWhiteList(data.tokens, whitelistedAddresses);
          const filteredTokens = filterDuplicates(receivedTokens, nfts);
          setNfts([...nfts, ...filteredTokens]);
          setIsEnd(data.cursor === null);
          setCursor(data.cursor);
        }
      })
      .catch((err) => {
        setIsError(true);
      });
    setIsNext(false);
  };

  useEffect(() => {
    loadPlainNft();
  }, [address, isRefresh]);

  return {
    plainIsEnd: isEnd,
    nfts,
    plainIsNext: isNext,
    setPlainIsNext: setIsNext,
    plainIsRefresh: isRefresh,
    setPlainIsrefresh: setIsRefresh,
    plainIsLoading: isLoading,
    setPlainIsLoading: setIsLoading,
    plainIsError: isError,
    plainSetAddress: setAddress,
    plainAddress: address,
    loadPlainNft,
    loadNextPlainNft,
  };
};
