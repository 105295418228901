import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CancelDoneModalContainer,
  CancelDoneModalExit,
  CancelDoneModalExitButton,
  CancelDoneModalLink,
  CancelDoneModalSubTitle,
  CancelDoneModalLogoImg,
  CancelDoneModalButton,
  CancelDoneModalTitle,
  CancelDoneModalWrapper,
} from './CancelDoneModal.styles';

function CancelDoneModal({ closeModal, isRejectTrade }: { closeModal: Function; isRejectTrade?: boolean }) {
  useEffect(
    () => () => {
      document.body.classList.remove('no-scroll');
    },
    [],
  );

  const navigate = useNavigate();

  return (
    <CancelDoneModalContainer>
      <CancelDoneModalWrapper>
        <CancelDoneModalExitButton>
          <CancelDoneModalExit
            onClick={() => {
              closeModal(false);
              window.location.reload();
              document.body.classList.remove('no-scroll');
              navigate('/my-trades', { replace: true });
            }}
          />
        </CancelDoneModalExitButton>
        <CancelDoneModalLogoImg />
        {isRejectTrade ? (
          <>
            <CancelDoneModalTitle>Trade Rejected Successful</CancelDoneModalTitle>
            <CancelDoneModalSubTitle>
              Congratulation, your trade between you and the counterparty has been rejected successfully!
            </CancelDoneModalSubTitle>
            <CancelDoneModalButton disabled>Share to</CancelDoneModalButton>
          </>
        ) : (
          <>
            <CancelDoneModalTitle>Trade Canceled Successful</CancelDoneModalTitle>
            <CancelDoneModalSubTitle>
              Congratulation, your trade between you and the counterparty has been canceled successfully!
            </CancelDoneModalSubTitle>
            <CancelDoneModalButton disabled>Share to</CancelDoneModalButton>
          </>
        )}
        <CancelDoneModalLink
          onClick={() => {
            closeModal(false);
            window.location.reload();
            document.body.classList.remove('no-scroll');
            navigate('/my-trades', { replace: true });
          }}
        >
          Go To My Trades
        </CancelDoneModalLink>
      </CancelDoneModalWrapper>
    </CancelDoneModalContainer>
  );
}
export default CancelDoneModal;
