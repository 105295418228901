import styled from 'styled-components';

interface ToggleStepsProps {
  white?: boolean;
  isOneNum?: boolean;
  isBig?: boolean;
}

export const ToggleStepsBackground = styled.div`
  position: fixed;
  bottom: 0;
  height: 116px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(24, 59, 86, 0.25);
  border-radius: 16px 16px 0px 0px;
  z-index: 2;
`;

export const ToggleStepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const TokensWrapper = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: overlay;
  width: 100%;
  height: 100%;
  padding-bottom: 8px;
  margin-right: 8px;

  &::-webkit-scrollbar {
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-color: #18a0fb;
  }
`;

export const TokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TokenImage = styled.img`
  height: 64px;
  border: none;
  border-radius: 4px;
  max-height: 100%;
`;

export const TokenName = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
  text-align: center;
`;

export const TokenAmount = styled.span<ToggleStepsProps>`
  position: absolute;
  font-size: 0.75rem;
  background: linear-gradient(180deg, #566ffe 0%, #3b84fd 52.64%, #18a0fb 100%);
  background-size: cover;
  padding: 2px 7px;
  min-width: 10px;
  color: #ffffff;
  right: 0px;
  bottom: 26px;
  ${(props) =>
    props.isOneNum
      ? `
          border-radius: 50%;
          `
      : 'border-radius: 30% / 45%;'}
`;

export const TokenClose = styled.img`
  position: absolute;
  cursor: pointer;
  right: -4px;
  top: 8px;
`;

export const ToggleStepsButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ToggleStepsButton = styled.button<ToggleStepsProps>`
  background: ${(props) => (props.white ? '#fff' : 'linear-gradient(180deg, #566ffe 0%, #18a0fb 100%)')};
  color: ${(props) => (props.white ? '#183B56' : '#fff')};
  border: ${(props) => (props.white ? ' 1px solid #E3E7EB' : 'none')};
  border-radius: 40px;
  padding: ${(props) => (props.white ? ' ' : '5px 19px')};
  width: ${(props) => (props.white ? '78px' : props.isBig ? '230px' : '158px')};
  height: 40px;
  font-size: 1rem;
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    opacity: 0.4;
    cursor: auto;
  }
`;

export const ToggleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #183b56;
  font-size: 0.875rem;
  font-weight: 600;
  gap: 1rem;
`;
