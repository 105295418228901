import { useEffect, useState, useRef } from 'react';

import {
  ERC721Wrapper,
  ERC721Image,
  ERC721ContentTop,
  ERC721Content,
  ERC721Title,
  ERC721Numbering,
  VerifyIcon,
} from './ERC721ItemPreview.styles';

import { NFTInfoModal } from '../index';

import verifyCheck from '../../icons/verifiy-check.svg';

import { calculateModalPosition } from '../../utils/calculateModalPosition';

type ERC721Props = {
  address: string;
  image: string;
  title: string;
  tokenName: string;
  description: string;
  isActive?: boolean;
  id: string;
};

function ERC721ItemPreview({ address, image, title, tokenName, description, isActive, id }: ERC721Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [isShownModal, setIsShownModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalPosition, setModalPosition] = useState('');
  const [isNoImage, setIsNoImage] = useState(false);
  const [isTitleHovered, setIsTitleHovered] = useState(false);

  const getModalWidth = () => {
    if (!ref.current) return;

    const newWidth = ref.current.clientWidth;
    setModalWidth(newWidth);
  };

  useEffect(() => {
    if (isActive && isTitleHovered) {
      const timing = setTimeout(() => {
        setIsShownModal(true);
      }, 400);
      return () => clearTimeout(timing);
    }
    setIsShownModal(false);
    setIsTitleHovered(false);
    setModalPosition('');
  }, [isActive, isTitleHovered]);

  useEffect(() => {
    if (image.includes('no-image')) {
      setIsNoImage(true);
    }

    getModalWidth();
    window.addEventListener('resize', getModalWidth);
  }, []);

  return (
    <ERC721Wrapper key={address} ref={ref}>
      <NFTInfoModal
        isShown={isShownModal}
        position={modalPosition}
        title={title}
        description={description || ''}
        address={address}
        tokenId={id}
        tokenStandard="ERC-721"
        isWhitelisted
      />
      <ERC721Image className={isNoImage ? 'no-image' : ''} src={image} />
      <ERC721Content>
        <ERC721ContentTop>
          <ERC721Title
            onMouseOver={(e) => {
              setTimeout(() => {
                const modalPosition = calculateModalPosition(e, modalWidth);
                setModalPosition(modalPosition);
                setIsTitleHovered(true);
              }, 100);
            }}
          >
            {title ? (title.length > 28 ? title?.slice(0, 28).concat('...') : title) : ''}{' '}
            <VerifyIcon src={verifyCheck} alt="" />
          </ERC721Title>
        </ERC721ContentTop>
        <ERC721Numbering
          onMouseOver={(e) => {
            setTimeout(() => {
              const modalPosition = calculateModalPosition(e, modalWidth);
              setModalPosition(modalPosition);
              setIsTitleHovered(true);
            }, 100);
          }}
        >
          {tokenName ? (tokenName.length > 28 ? tokenName?.slice(0, 28).concat('...') : tokenName) : ''}
        </ERC721Numbering>
      </ERC721Content>
    </ERC721Wrapper>
  );
}

export default ERC721ItemPreview;
