import { useState, useEffect } from 'react';

import axios from 'axios';
import { ethers } from 'ethers';

type ResponseType = {
  data: {
    result: string;
  };
};

export const useGetETHBalance = () => {
  const [balance, setBalance] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState<string | null>();

  useEffect(() => {
    if (!address) {
      setIsLoading(false);
      return;
    }

    axios
      .get(
        `https://${process.env.REACT_APP_ETHERSCAN_API_URL}/api?module=account&action=balance&address=${address}&tag=latest&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`,
      )
      .then((res: ResponseType) => {
        const balanceETH = ethers.utils.formatEther(res.data.result).replace(/\.(\d{1,2}).*$/, '.$1');
        setBalance(balanceETH);
        setIsLoading(false);
      });
  }, [address, balance, isLoading]);

  return { setAddress, balanceETH: balance, isLoading };
};
