import { injected, walletconnect, resetWalletConnector } from '../helper/connector';

const connectWallet = (web3reactContext: any, wallet: string) => {
  const connectMetamask = async () => {
    try {
      await web3reactContext.activate(injected());
      localStorage.setItem('disconnect', 'false');
    } catch (ex) {
      console.error(ex);
    }
  };

  const connectWalletConnect = async () => {
    try {
      resetWalletConnector(walletconnect);
      await web3reactContext.activate(walletconnect);
      localStorage.setItem('disconnect', 'false');
    } catch (ex) {
      console.error(ex);
    }
  };

  switch (wallet) {
    case 'Metamask':
      connectMetamask();
      break;
    case 'WalletConnect':
      connectWalletConnect();
      break;
    default:
      break;
  }
};

export default connectWallet;
