import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectTokensUserOne, selectTokensUserTwo } from '../../../../app/store';
import { removeFromSwapUserOne, removeFromSwapUserTwo, resetSwapUserTwo } from '../../../../features/swapSlice';

import closeIcon from '../../../../icons/close-icon.svg';
import alertIcon from '../../../../icons/alert-icon.svg';

import { Container } from '../../../../globalStyles';

import {
  ToggleStepsBackground,
  ToggleStepsButtonsWrapper,
  ToggleStepsButton,
  ToggleStepsWrapper,
  TokensWrapper,
  TokenWrapper,
  TokenAmount,
  TokenImage,
  TokenName,
  TokenClose,
  ToggleTextWrapper,
} from './ToggleSteps.styles';

type ToggleStepsProps = {
  nextStepLink?: string;
  backLink?: string;
  backLinkName?: string;
  buttonName?: string;
  secondStep?: boolean;
  thirdStep?: boolean;
  setShowTransaction?: Function;
  setIsRejectTrade?: Function;
};

function ToggleSteps({
  nextStepLink,
  backLink,
  backLinkName,
  secondStep,
  buttonName,
  thirdStep,
  setShowTransaction,
  setIsRejectTrade,
}: ToggleStepsProps) {
  const swapTokens = useSelector(selectTokensUserOne);
  const swapTokensSecondUser = useSelector(selectTokensUserTwo);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ToggleStepsBackground>
      <Container>
        <ToggleStepsWrapper>
          <TokensWrapper>
            {thirdStep ? (
              <ToggleTextWrapper>
                <img src={alertIcon} alt="" />
                Double Check The Selected Assets - Many Crypto Assets Look Alike
              </ToggleTextWrapper>
            ) : (
              <>
                {secondStep ? (
                  <>
                    {swapTokensSecondUser.map((token: any, key: number) => (
                      <TokenWrapper key={key}>
                        <TokenClose
                          onClick={() => {
                            const { image, title, amount, id, tokenStandard, address } = token;
                            dispatch(
                              removeFromSwapUserTwo({
                                image,
                                title,
                                amount,
                                id,
                                tokenStandard,
                                address,
                              }),
                            );
                          }}
                          src={closeIcon}
                        />
                        <TokenImage src={token.image} />
                        <TokenName>{token.title}</TokenName>
                        {token.tokenStandard === 'ERC-1155' ? (
                          <>
                            {token.amount.length > 1 ? (
                              <TokenAmount isOneNum={false}>{token.amount}</TokenAmount>
                            ) : (
                              <TokenAmount isOneNum>{token.amount}</TokenAmount>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TokenWrapper>
                    ))}
                  </>
                ) : (
                  <>
                    {swapTokens.map((token: any, key: number) => (
                      <TokenWrapper key={key}>
                        <TokenClose
                          onClick={() => {
                            const { image, title, amount, id, tokenStandard, address } = token;
                            dispatch(
                              removeFromSwapUserOne({
                                image,
                                title,
                                amount,
                                id,
                                tokenStandard,
                                address,
                              }),
                            );
                          }}
                          src={closeIcon}
                        />
                        <TokenImage src={token.image} />
                        <TokenName>{token.title}</TokenName>
                        {token.tokenStandard === 'ERC-1155' ? (
                          <>
                            {token.amount.length > 1 ? (
                              <TokenAmount isOneNum={false}>{token.amount}</TokenAmount>
                            ) : (
                              <TokenAmount isOneNum>{token.amount}</TokenAmount>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TokenWrapper>
                    ))}
                  </>
                )}
              </>
            )}
          </TokensWrapper>
          <ToggleStepsButtonsWrapper>
            {backLink ? (
              <ToggleStepsButton
                white
                onClick={() => {
                  if (backLink === '/step-two/address') dispatch(resetSwapUserTwo());
                  navigate(`${backLink}`);
                }}
              >
                Back
              </ToggleStepsButton>
            ) : (
              <></>
            )}
            {backLinkName ? (
              <ToggleStepsButton
                white
                onClick={() => {
                  if (backLinkName === 'Reject' && setIsRejectTrade) {
                    document.body.classList.add('no-scroll');
                    setIsRejectTrade(true);
                  }
                }}
              >
                {backLinkName}
              </ToggleStepsButton>
            ) : (
              <></>
            )}
            {nextStepLink ? (
              <ToggleStepsButton
                isBig={buttonName === 'Approve Trade Request'}
                onClick={() => {
                  navigate(`${nextStepLink}`);
                  if (
                    (buttonName === 'Approve Trade Request' && setShowTransaction) ||
                    (buttonName === 'Approve Trade' && setShowTransaction)
                  ) {
                    document.body.classList.add('no-scroll');
                    setShowTransaction(true);
                  }
                }}
              >
                {buttonName || 'Next Step'}
              </ToggleStepsButton>
            ) : (
              <ToggleStepsButton className="disabled">{buttonName || 'Next Step'}</ToggleStepsButton>
            )}
          </ToggleStepsButtonsWrapper>
        </ToggleStepsWrapper>
      </Container>
    </ToggleStepsBackground>
  );
}

export default ToggleSteps;
