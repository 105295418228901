import { AllTrades } from '../hooks/useGetUserTrades';

export const getUserTradesCount = (trades: AllTrades) => {
  let all = 0;
  let completed = 0;
  let pending = 0;
  let canceled = 0;

  for (let i = 0; i < trades.length; i++) {
    all++;
    switch (trades[i].status) {
      case 'Canceled':
        canceled++;
        break;
      case 'Completed':
        completed++;
        break;
      case 'Pending':
        pending++;
        break;
      default:
        break;
    }
  }

  return [
    {
      name: 'All',
      count: all,
      isSelected: true,
    },
    {
      name: 'Completed',
      count: completed,
      isSelected: false,
    },
    {
      name: 'Pending',
      count: pending,
      isSelected: false,
    },
    {
      name: 'Canceled',
      count: canceled,
      isSelected: false,
    },
  ];
};
